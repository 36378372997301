import { navigate } from './nav';
// import { VOUCHER_TYPE_PURCHASE } from '../constants';

export const appInit = () => (dispatch, getState) => {
  const { user, router, currentCompany } = getState();
  // use regex to get companyId from pathname
  //console.log('test iCompanyId', extractICompanyIdFromPath(router.location.pathname));

  var reg = new RegExp(
    /(view\/shared-voucher)|(view\/shared-conversation)|(view\/ishop)|(view\/download)/g,
    'i'
  );
  const isPublicUrl = reg.test(router.location.pathname);
  // if accesstoken is absent and url is also not follow public url
  // format then redirect to login
  if (!user.accessToken && !isPublicUrl) {
    navigate(dispatch, `/login`);
    return false;
  }
  if (router.location.pathname === '/' && currentCompany.id) {
    // TODO: We should direct it to centralized router nav which should fire this
    const page = 'vouchers';
    // const type = VOUCHER_TYPE_PURCHASE;
    navigate(dispatch, `/${currentCompany.id}/home/${page}`);
    return;
  }
  var regCompanyLoginPathMatch = new RegExp(/\/companies\/company\/?([a-f\d]{24})\/?$/g);
  if (regCompanyLoginPathMatch.test(router.location.pathname) && !currentCompany.id) {
    // TODO: We should direct it to centralized router nav which should fire this
    navigate(dispatch, `/companies/list`);
    return;
  }

  if (router.location.pathname === '/' && user.accessToken && user.id) {
    //console.log('router.location.pathname', router.location.pathname);
    navigate(dispatch, `/companies/list`);
    return;
  }
};

// function extractICompanyIdFromPath(url) {
//   let regex = new RegExp(/\/?([a-f\d]{24})\/home.*/g);
//   let matchArr = regex.exec(url);
//   let iCompanyId = matchArr && matchArr.length > 1 ? matchArr[1] : null;
//   return iCompanyId;
// }

export * from './companies';
export * from './reports';
export * from './vouchers';
export * from './contacts';
export * from './items';
export * from './user';
export * from './purchaseVouchersOld';
export * from './snackbar';
export * from './table';
export * from './guides';
export * from './pdfPrint';
export * from './parties';
export * from './settings';
