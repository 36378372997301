import moment from 'moment';
import {
  FETCH_PROGRESS_TRACKER_LIST,
  FETCH_PROGRESS_TRACKER_LIST_SUCCESS,
  FETCH_PROGRESS_TRACKER_LIST_FAILED,
  SET_PROGRESS_STATUS_BY_ID
} from '../actions/types';

const initialState = {
  progressTrackerList: [],
  listLoading: false,
  progressStatus: {}
};

export const progressTracker = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRESS_TRACKER_LIST:
      return {
        ...state,
        listLoading: true
      };

    case FETCH_PROGRESS_TRACKER_LIST_SUCCESS:
      const inProgressTracker =
        action.payload.length > 0
          ? action.payload.filter(p => moment(p.createdAt).isAfter(moment().startOf('day')))
          : [];
      return {
        ...state,
        listLoading: false,
        progressTrackerList: inProgressTracker
      };

    case FETCH_PROGRESS_TRACKER_LIST_FAILED:
      return {
        ...state,
        listLoading: false
      };
    case SET_PROGRESS_STATUS_BY_ID:
      return {
        ...state,
        progressStatus: {
          ...state.progressStatus,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export const getItemGroupTrackerById = (id, progressTrackerList) => {
  let tracker = null;

  if (progressTrackerList.length > 0) {
    progressTrackerList.forEach(element => {
      const name = element.name.split('-');
      if (name && name.length && name[1] === id) {
        tracker = element;
      }
    });
  }

  return tracker;
};
