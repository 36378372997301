import { serverError, PdfBaseUrl } from '../api/server';
import * as api from '../api/pdfPrint.js';
import { CURRENT_COMPANY_PDF_TOKEN } from '../constants';
import { PDF_TEMPLATES_REQUESTED, PDF_TEMPLATES_SUCCESS } from './types';

export const fetchUserTemplates = (companyId, voucherType, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  if (voucherType) {
    query += `?voucherType=${voucherType}`;
  }
  dispatch({
    type: PDF_TEMPLATES_REQUESTED
  });
  return api
    .getUserTemplates(id || companyId, query)
    .then(response => {
      dispatch({
        type: 'FETCH_NEW_TEMPLATE_LIST',
        payload: response.data,
        companyId: id
      });
      dispatch({
        type: PDF_TEMPLATES_SUCCESS
      });
      callback && callback(response);
    })
    .catch(error => {
      console.log(error);
      serverError(error);
    });
};

export const getDefaultTemplateCode = (companyId, voucherType) => (dispatch, getState) => {
  const {
    currentCompany: { id }
  } = getState();
  let query = '';
  if (voucherType) {
    query += `?voucherType=${voucherType}`;
  }
  return api
    .generateDefaultTemplateCode(id || companyId, query)
    .then(response => {
      const pdfToken = localStorage.getItem(`${CURRENT_COMPANY_PDF_TOKEN}_${companyId || id}`);
      window
        .open(
          `${PdfBaseUrl}/editor/${parseInt(response.data.template)}?token=${pdfToken}`,
          '_blank'
        )
        .focus();
    })
    .catch(error => {
      console.log(error);
      serverError(error);
    });
};

export const onNewWayPrinting = voucherId => (dispatch, getState) => {
  const {
    currentCompany: { id, newTemplates }
  } = getState();
  let query = '';
  let code = '';
  let defaultTemplate = false;
  if (newTemplates.userTemplates.length > 0) {
    code = newTemplates.userTemplates[0].template;
  } else if (newTemplates.defaultTemplates.length > 0) {
    code = newTemplates.defaultTemplates[0].template;
    defaultTemplate = true;
  }

  query += `?isDefaultTemplateCode=${defaultTemplate}`;

  return api
    .printPdf(id, voucherId, code, query)
    .then(response => {
      console.log(response, '????');
      // const pdfToken = localStorage.getItem(`${CURRENT_COMPANY_PDF_TOKEN}_${companyId || id}`);
      window.open(response.data.url, '_blank').focus();
    })
    .catch(error => {
      console.log(error);
      serverError(error);
    });
};
