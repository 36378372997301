import {
  OTP_REQUESTED,
  OTP_SUCCESS,
  OTP_VERIFICATION_SUCCESS,
  UPDATE_USER_NAME_SUCCESS,
  PASSWORD_RESET_OTP_REQUESTED,
  PASSWORD_RESET_OTP_SUCCESS,
  PASSWORD_RESET_OTP_FAILED,
  PASSWORD_RESET_VERIFICATION_SUCCESS,
  PASSWORD_RESET_VERIFICATION_FAILED,
  PASSWORD_RESET_SUCCESS,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_COMPANY_SETTING,
  DELETE_USER_ACCESS_TOKEN_REQUESTED,
  DELETE_USER_ACCESS_TOKEN_SUCCESS,
  DELETE_USER_ACCESS_TOKEN_FAILED,
  GET_USER_REFERRALS_REQUESTED,
  GET_USER_REFERRALS_SUCCESS,
  GET_USER_REFERRALS_FAILED,
  USER_TAB_LOGOUT,
  REMOVE_BUSINESS_ADMIN_OTP_REQUESTED,
  REMOVE_BUSINESS_ADMIN_OTP_SUCCESS,
  REMOVE_BUSINESS_ADMIN_OTP_FAILED,
  OTP_SIGN_FAILED,
  OTP_SIGN_SUCCESS,
  OTP_SIGN_REQUESTED,
  SET_USER_NAME_REQUESTED,
  SET_USER_NAME_SUCCESS,
  SET_USER_NAME_FAILED,
  UPDATE_USER_PROFILE_PICTURE_REQUESTED,
  UPDATE_USER_PROFILE_PICTURE_FAILED,
  UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS,
  FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_FAILED,
  FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED,
  FETCH_USER_PROFILE_SUCCESS
} from '../actions/types';

const initialState = {
  loadingGenerateOtp: false,
  signIn: false,
  resetOtpVerified: false,
  passwordResetInitialize: false,
  otpId: '',
  userCompanySettings: {
    financialYear: {
      startDate: '',
      endDate: ''
    },
    id: '',
    iCompanyId: '',
    iUserId: ''
  },
  userPublicBusinessCardShareStatus: {
    loading: false,
    contacts: [],
    shareContacts: []
  },
  deleteUserTokenRequested: false,
  userReferralsRequested: false,
  userReferrals: { referredUsers: [] },
  removeBusinessAdminOtpRequested: false,
  loading: false,
  iUser: {}
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case OTP_REQUESTED:
      return {
        ...state,
        ...action.payload,
        otpId: '',
        loadingGenerateOtp: true
      };

    case OTP_SUCCESS:
      return {
        ...state,
        ...action.payload.iUser,
        signIn: true,
        otpId: action.payload.otpId,
        loadingGenerateOtp: initialState.loadingGenerateOtp
      };
    case PASSWORD_RESET_OTP_REQUESTED:
      return {
        ...state,
        passwordResetInitialize: true,
        resetOtpVerified: false
      };
    case PASSWORD_RESET_OTP_SUCCESS:
      return {
        ...state,
        resetOtpVerified: false,
        otpId: action.payload.otpId
      };
    case PASSWORD_RESET_OTP_FAILED:
      return {
        ...state,
        resetOtpVerified: false
      };
    case PASSWORD_RESET_VERIFICATION_SUCCESS:
      return {
        ...state,
        resetOtpVerified: true
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordResetInitialize: false,
        resetOtpVerified: false
      };
    case PASSWORD_RESET_VERIFICATION_FAILED:
      return {
        ...state,
        resetOtpVerified: false
      };
    case OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        ...action.payload.iUser,
        accessToken: action.payload.accessToken.id
      };
    case UPDATE_USER_NAME_SUCCESS:
      return {
        ...state,
        name: action.payload,
        iUser: {
          ...state.iUser,
          name: action.payload.name
        }
      };
    case UPDATE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        iUser: {
          ...state.iUser,
          email: action.payload.email
        }
      };
    case UPDATE_USER_COMPANY_SETTING:
      return {
        ...state,
        userCompanySettings: {
          ...state.userCompanySettings,
          ...action.payload
        }
      };
    case DELETE_USER_ACCESS_TOKEN_REQUESTED:
      return {
        ...state,
        deleteUserTokenRequested: true
      };
    case DELETE_USER_ACCESS_TOKEN_SUCCESS:
    case DELETE_USER_ACCESS_TOKEN_FAILED:
    case USER_TAB_LOGOUT:
      return {
        ...initialState
      };
    case GET_USER_REFERRALS_REQUESTED:
      return {
        ...state,
        userReferralsRequested: true
      };
    case GET_USER_REFERRALS_SUCCESS:
      return {
        ...state,
        userReferralsRequested: false,
        userReferrals: action.payload
      };
    case GET_USER_REFERRALS_FAILED:
      return {
        ...state,
        userReferralsRequested: false
      };
    case REMOVE_BUSINESS_ADMIN_OTP_REQUESTED:
      return {
        ...state,
        removeBusinessAdminOtpRequested: true
      };
    case REMOVE_BUSINESS_ADMIN_OTP_SUCCESS:
      return {
        ...state,
        otpId: action.payload.otpId,
        removeBusinessAdminOtpRequested: false
      };
    case REMOVE_BUSINESS_ADMIN_OTP_FAILED:
      return {
        ...state,
        removeBusinessAdminOtpRequested: false
      };
    case OTP_SIGN_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case OTP_SIGN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case OTP_SIGN_FAILED:
      return {
        ...state,
        loading: false
      };
    case SET_USER_NAME_REQUESTED:
    case UPDATE_USER_PROFILE_PICTURE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case SET_USER_NAME_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SET_USER_NAME_FAILED:
    case UPDATE_USER_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        loading: false
      };
    case UPDATE_USER_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        iUser: {
          ...state.iUser,
          profileImage: [action.payload]
        }
      };
    case FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED:
      return {
        ...state,
        [action.key]: {
          ...initialState[action.key],
          loading: true
        }
      };

    case FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          ...action.payload
        }
      };
    case FETCH_USER_BUSINESS_CARD_PUBLIC_SHARE_FAILED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false
        }
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        iUser: action.payload
      };
    default:
      return state;
  }
};
