import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from './types';

export const openSnackbar = (message, type) => {
  return {
    type: SNACKBAR_OPEN,
    payload: { message, type }
  };
};

export const closeSnackbar = () => {
  return {
    type: SNACKBAR_CLOSE
  };
};
