// import map from 'lodash/map';
import {
  getBranchState,
  objectToArray,
  getPartyGstBusinessTypeRef,
  salesTypeFlag
} from '../utils/helperFunctions';
import { getDecimalPoints } from '../reducers';
import VoucherCalModule from 'voucher-calculation';
import {
  GET_CALCULATED_ITEMS,
  GET_AUTO_POPULATE_ROUNDOFF_DATA,
  GET_LINE_AMOUNT_SUM,
  GET_BILL_TAX_AMOUNT,
  GET_BILL_ITEMS_PRICE,
  GET_BILL_DISCOUNT_AMOUNT,
  GET_BILL_AMT_AND_DUE_AMT,
  GET_OTHER_CHARGES_TOTAL,
  GET_OTHER_CHARGES_AFTER_TAX_TOTAL,
  GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT,
  GET_TAX,
  GET_EXCLUSIVE_DISCOUNT_LIST_AND_TOTAL,
  SET_CALCULATION_STATUS,
  GET_CALCULATED_ITEM
} from '../actions/types';
import { getLineItemSchema } from '../reducers/vouchers';
import {
  EDIT_LINE,
  NEW_LINE,
  DELETE_LINE,
  SET_COUPON_DISCOUNT,
  SET_ADDITIONAL_DISCOUNT,
  PARTY_SELECT,
  RECALCULATE,
  EDIT_OTHER_CHARGES_AFTER_TAX,
  SET_ROUNDOFF,
  MERGE_ESTIMATION,
  SET_EXCLUSIVE_DISCOUNT,
  EDIT_OTHER_CHARGES,
  SET_BRANCH,
  NEW,
  CURRENCY_DECIMAL_ROUND,
  QTY_DECIMAL_ROUND,
  VOUCHER_TYPE_CREDIT_NOTE,
  ADJUSTMENT_ON_ITEM_PRICE,
  VOUCHER_TYPE_DEBIT_NOTE
} from '../constants';
import { reCalculateOtherChargeAfterTaxAmount, reCalculateOtherChargeAmount } from '../actions';

let VoucherOptimizePureCal = VoucherCalModule.optimizePureCal;
export const initVoucherCalc = props => {
  const {
    voucherId = NEW,
    itemList = [],
    additionalDiscount = 0,
    hasExclusiveDiscount = false,
    salesDiscount = [],
    couponDiscount = 0,
    otherCharges = [],
    partyId = '',
    partyGstBusinessTypeRef = '',
    otherChargesAfterTax = [],
    adjustments = 0,
    partyState = '',
    branchState = '',
    currencyDecimals = CURRENCY_DECIMAL_ROUND,
    itemQtyDecimals = QTY_DECIMAL_ROUND
  } = props;
  console.log('hshs');
  VoucherOptimizePureCal.initVoucherCalculation(
    voucherId,
    itemList,
    additionalDiscount,
    hasExclusiveDiscount,
    salesDiscount,
    couponDiscount,
    otherCharges,
    partyId,
    partyGstBusinessTypeRef,
    otherChargesAfterTax,
    adjustments,
    partyState,
    branchState,
    currencyDecimals,
    itemQtyDecimals
  );
};

export const resetVoucherCalc = (voucherId = NEW) => {
  console.log('reset voucher calc', voucherId);
  VoucherOptimizePureCal.resetVoucherCalculation(voucherId);
};

export const getOtherChargesAfterTax = (vocuherId = NEW, otherChargesAfterTaxArray, dispatch) => {
  const otherChargesAfterTaxTotal = getOtherChargesAfterTaxTotal(
    vocuherId,
    otherChargesAfterTaxArray
  );
  console.log(otherChargesAfterTaxArray, otherChargesAfterTaxTotal);
  dispatch({ type: GET_OTHER_CHARGES_AFTER_TAX_TOTAL, payload: otherChargesAfterTaxTotal });
};

/**
 *  this  functions handles add voucher calculations,
 *  lineItems array has   to  be  set as  ref in  setRefItemList()  function  provided  by  voucher-calculation,
 *  otherCharges array has   to  be  set as  ref in  setRefOtherChargesList()  function  provided  by  voucher-calculation,
 *  adjustments array has   to  be  set as  ref in  setAdjustments()  function  provided  by  voucher-calculation,
 *  otherChargesAfterTax array has   to  be  set as  ref in  setRefOtherChargesAfterTaxList()  function  provided  by  voucher-calculation,
 */
export const handleItemCalculations = (dispatch, getState, actionType, offSetValue, lineIndex) => {
  console.log('handle start', actionType);
  const {
    vouchers,
    vouchers: {
      type: voucherType,
      _lineItems,
      _otherCharges,
      _branches,
      _selectedBranch,
      _otherChargesAfterTax,
      selectedPartyAccount,
      selectedPartyAccount: { salesDiscounts = [], purchaseDiscounts = [] },
      couponDiscount,
      _roundOffOffset,
      _selectedVoucherId,
      oldLineItem: oldLine,
      app: {
        exclusiveDiscountFlag: hasExclusiveDiscount,
        // calcEnabled = true,
        lineItem: { currentItemIndex }
      },
      subType
    },
    contacts: {
      voucherViewMode: { duplicateVoucherType }
    }
  } = getState();

  let voucherId = _selectedVoucherId;
  if (!voucherId) {
    voucherId = NEW;
  }
  const type = duplicateVoucherType ? duplicateVoucherType : voucherType;
  const decimalplaces = getDecimalPoints(getState());
  dispatch({ type: SET_CALCULATION_STATUS, key: GET_CALCULATED_ITEMS, status: true });

  const selectedBranchState = getBranchState(_branches, _selectedBranch);
  const arrOtherCharges = objectToArray(_otherCharges);
  const arrOtherChargesAfterTax = objectToArray(_otherChargesAfterTax);
  let adjustments = _roundOffOffset;

  if (!adjustments) {
    adjustments = offSetValue || 0;
  }

  const isVoucherTypeSales = salesTypeFlag(voucherType);
  const getUpdatedExclusiveDiscount = () => {
    let {
      summary: exclusiveDiscountList,
      totalDiscountAmount: exclusiveDiscountTotal
    } = getExclusiveDiscountListAndTotal(
      voucherId,
      isVoucherTypeSales,
      salesDiscounts,
      purchaseDiscounts,
      hasExclusiveDiscount
    );

    exclusiveDiscountList = exclusiveDiscountList?.filter(
      item => item?.actualAmount !== 0 && item?.discountAmount !== 0
    );
    dispatch({
      type: GET_EXCLUSIVE_DISCOUNT_LIST_AND_TOTAL,
      payload: { exclusiveDiscountList, exclusiveDiscountTotal }
    });
  };
  //set reference for voucher-calculation module

  let index =
    actionType === NEW_LINE
      ? lineIndex
      : actionType === DELETE_LINE ||
        actionType === EDIT_OTHER_CHARGES_AFTER_TAX ||
        actionType === EDIT_OTHER_CHARGES ||
        actionType === SET_COUPON_DISCOUNT ||
        actionType === SET_BRANCH ||
        actionType === SET_ADDITIONAL_DISCOUNT ||
        actionType === SET_EXCLUSIVE_DISCOUNT ||
        actionType === PARTY_SELECT
      ? -1
      : currentItemIndex;
  let currentLine = _lineItems[index];

  let lineItem = {
    ...getLineItemSchema(currentLine, vouchers), //initialize with default values
    ...currentLine
  };
  let oldLineItem = {
    ...getLineItemSchema({}, vouchers),
    ...(actionType === DELETE_LINE || index !== -1 ? oldLine : {})
  };
  let otherChargesArrayRef = arrOtherCharges;
  let otherChargesAfterTaxArrayRef = arrOtherChargesAfterTax;
  let additionalDiscountObjRef = vouchers.purchase.additionalDiscount;
  let branStateRef = selectedBranchState;
  let partyStateRef = selectedPartyAccount.state;
  console.log(type, selectedPartyAccount, _branches, _selectedBranch);
  let partyGstBusinessTypeRef =
    getPartyGstBusinessTypeRef(type, selectedPartyAccount, _branches, _selectedBranch) || '';

  let adjustQtyFlag = false;

  if (
    (type === VOUCHER_TYPE_CREDIT_NOTE || type === VOUCHER_TYPE_DEBIT_NOTE) &&
    subType === ADJUSTMENT_ON_ITEM_PRICE
  ) {
    adjustQtyFlag = true;
  }

  const discountList = salesTypeFlag(type) ? salesDiscounts : purchaseDiscounts;

  switch (actionType) {
    case SET_COUPON_DISCOUNT:
      getLineAmountSum(
        //added temporarily, remove after npm is updated to split calc
        voucherId,
        lineItem,
        oldLineItem,
        additionalDiscountObjRef,
        hasExclusiveDiscount,
        discountList,
        couponDiscount,
        otherChargesArrayRef,
        selectedPartyAccount.id,
        partyGstBusinessTypeRef,
        partyStateRef,
        branStateRef
      );
      const couponDiscountSum = getCouponDiscountSum(voucherId);
      console.log(couponDiscountSum);
      break;

    case SET_BRANCH:
      getLineAmountSum(
        //added temporarily, remove after npm is updated to split calc
        voucherId,
        lineItem,
        oldLineItem,
        additionalDiscountObjRef,
        hasExclusiveDiscount,
        discountList,
        couponDiscount,
        otherChargesArrayRef,
        selectedPartyAccount.id,
        partyGstBusinessTypeRef,
        partyStateRef,
        branStateRef
      );
      break;

    case SET_ADDITIONAL_DISCOUNT:
      getLineAmountSum(
        //added temporarily, remove after npm is updated to split calc
        voucherId,
        lineItem,
        oldLineItem,
        additionalDiscountObjRef,
        hasExclusiveDiscount,
        discountList,
        couponDiscount,
        otherChargesArrayRef,
        selectedPartyAccount.id,
        partyGstBusinessTypeRef,
        partyStateRef,
        branStateRef
      );
      const additionalDiscountCurrencyAmount = getAdditionalDiscountCurrencyAmount(voucherId);

      dispatch({
        type: GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT,
        payload: additionalDiscountCurrencyAmount
      });
      getUpdatedExclusiveDiscount();
      break;

    case RECALCULATE:
    case MERGE_ESTIMATION:
    case PARTY_SELECT:
      console.log(partyGstBusinessTypeRef);
      const initalLineItem = {
        ...getLineItemSchema({}, vouchers)
      };
      recalculateAllLineItems(
        voucherId,
        _lineItems,
        partyGstBusinessTypeRef,
        dispatch,
        index,
        initalLineItem,
        additionalDiscountObjRef,
        hasExclusiveDiscount,
        discountList,
        couponDiscount,
        otherChargesArrayRef,
        selectedPartyAccount,
        partyStateRef,
        branStateRef,
        adjustQtyFlag
      );

      dispatch({
        type: GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT,
        payload: getAdditionalDiscountCurrencyAmount(voucherId)
      });
      break;

    case EDIT_OTHER_CHARGES_AFTER_TAX:
      const otherChargesAfterTaxTotal = getOtherChargesAfterTaxTotal(
        voucherId,
        otherChargesAfterTaxArrayRef
      );
      dispatch({ type: GET_OTHER_CHARGES_AFTER_TAX_TOTAL, payload: otherChargesAfterTaxTotal });
      break;

    case SET_ROUNDOFF:
      VoucherOptimizePureCal.updateAdjustments(voucherId, adjustments || 0);
      break;

    case EDIT_LINE:
    case NEW_LINE:
    case DELETE_LINE:
    case SET_EXCLUSIVE_DISCOUNT:
      calculateSingleLineItem(
        voucherId,
        //added temporarily, remove after npm is updated to split calc
        lineItem,
        partyGstBusinessTypeRef,
        dispatch,
        index,
        oldLineItem,
        additionalDiscountObjRef,
        hasExclusiveDiscount,
        discountList,
        couponDiscount,
        otherChargesArrayRef,
        selectedPartyAccount,
        partyStateRef,
        branStateRef,
        adjustQtyFlag
      );

      dispatch({
        type: GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT,
        payload: getAdditionalDiscountCurrencyAmount(voucherId)
      });
      getUpdatedExclusiveDiscount();

      dispatch(reCalculateOtherChargeAmount());
      dispatch(reCalculateOtherChargeAfterTaxAmount());
      break;

    case EDIT_OTHER_CHARGES:
      console.log(voucherId, lineItem, oldLineItem);
      const otherChargesTotal = getOtherChargesTotal(voucherId, otherChargesArrayRef);
      dispatch({ type: GET_OTHER_CHARGES_TOTAL, payload: otherChargesTotal });
      getLineAmountSum(
        //added temporarily, remove after npm is updated to split calc
        voucherId,
        lineItem,
        oldLineItem,
        additionalDiscountObjRef,
        hasExclusiveDiscount,
        discountList,
        couponDiscount,
        otherChargesArrayRef,
        selectedPartyAccount.id,
        partyGstBusinessTypeRef,
        partyStateRef,
        branStateRef
      );
      break;

    default:
      break;
  }

  const billItemsPrice = getBillItemsPrice(voucherId);
  dispatch({ type: GET_BILL_ITEMS_PRICE, payload: billItemsPrice });

  const billDiscountAmount = getBillDiscountAmount(voucherId);
  dispatch({ type: GET_BILL_DISCOUNT_AMOUNT, payload: billDiscountAmount });

  const { dueAmount, billFinalAmount } = getBillAmtAndDueAmt(voucherId, vouchers, decimalplaces);
  dispatch({ type: GET_BILL_AMT_AND_DUE_AMT, payload: { dueAmount, billFinalAmount } });

  const autoPopulateRoundoffData = getAutoPopulateRoundoffData(voucherId);
  dispatch({ type: GET_AUTO_POPULATE_ROUNDOFF_DATA, payload: autoPopulateRoundoffData });

  const billTaxAmount = getBillTaxAmount(voucherId);
  dispatch({ type: GET_BILL_TAX_AMOUNT, payload: billTaxAmount });

  const taxBreakup = getTaxBreakUpByHsn(voucherId, partyStateRef, branStateRef);
  dispatch({ type: GET_TAX, payload: taxBreakup });

  dispatch({ type: SET_CALCULATION_STATUS, key: GET_CALCULATED_ITEMS, status: false });
  console.log('handle end', billFinalAmount);
  dispatch(reCalculateOtherChargeAfterTaxAmount());
  // }
};

function calculateSingleLineItem(
  voucherId,
  lineItem,
  partyGstBusinessTypeRef,
  dispatch,
  index,
  oldLineItem,
  additionalDiscountObjRef,
  hasExclusiveDiscount,
  salesDiscounts,
  couponDiscount,
  otherChargesArrayRef,
  selectedPartyAccount,
  partyStateRef,
  branStateRef,
  adjustQtyFlag
) {
  // console.log(lineItem, partyGstBusinessTypeRef, oldLineItem.unit, lineItem.unit);
  let calculatedItem = getCalculatedItems(
    lineItem,
    partyGstBusinessTypeRef,
    oldLineItem.unit,
    lineItem.unit,
    partyStateRef,
    branStateRef,
    adjustQtyFlag
  );
  // console.log(calculatedItem);
  dispatch({ type: GET_CALCULATED_ITEM, payload: calculatedItem, index: index });
  const lineAmountSum = getLineAmountSum(
    voucherId,
    calculatedItem,
    oldLineItem,
    additionalDiscountObjRef,
    hasExclusiveDiscount,
    salesDiscounts,
    couponDiscount,
    otherChargesArrayRef,
    selectedPartyAccount.id,
    partyGstBusinessTypeRef,
    partyStateRef,
    branStateRef
  );
  dispatch({ type: GET_LINE_AMOUNT_SUM, payload: lineAmountSum });
}

function recalculateAllLineItems(
  voucherId,
  lineItems,
  partyGstBusinessTypeRef,
  dispatch,
  index,
  oldLineItem,
  additionalDiscountObjRef,
  hasExclusiveDiscount,
  salesDiscounts,
  couponDiscount,
  otherChargesArrayRef,
  selectedPartyAccount,
  partyStateRef,
  branStateRef,
  adjustQtyFlag
) {
  let lineAmountSum = 0;
  let calculatedItems = lineItems.map(item => {
    let tempItem = getCalculatedItems(
      item,
      partyGstBusinessTypeRef,
      item.unit,
      item.unit,
      partyStateRef,
      branStateRef,
      adjustQtyFlag
    );
    lineAmountSum = getLineAmountSum(
      voucherId,
      tempItem,
      oldLineItem,
      additionalDiscountObjRef,
      hasExclusiveDiscount,
      salesDiscounts,
      couponDiscount,
      otherChargesArrayRef,
      selectedPartyAccount.id,
      partyGstBusinessTypeRef,
      partyStateRef,
      branStateRef
    );
    return tempItem;
  });

  dispatch({ type: GET_CALCULATED_ITEMS, payload: calculatedItems });
  dispatch({ type: GET_LINE_AMOUNT_SUM, payload: lineAmountSum });
}

function getCouponDiscountSum(voucherId) {
  return VoucherOptimizePureCal.getCouponDiscount(voucherId);
}

function getExclusiveDiscountListAndTotal(
  voucherId,
  isVoucherTypeSales,
  salesDiscounts,
  purchaseDiscounts,
  hasExclusiveDiscount
) {
  const { summary, totalDiscountAmount } = VoucherOptimizePureCal.getExclusiveDiscountSummary(
    voucherId,
    isVoucherTypeSales,
    salesDiscounts,
    purchaseDiscounts,
    hasExclusiveDiscount
  );
  return { summary, totalDiscountAmount };
}

function getTaxBreakUpByHsn(voucherId, partyStateRef, branStateRef) {
  return VoucherOptimizePureCal.getTaxBreakUpByHsn(voucherId, partyStateRef, branStateRef);
}

function getAdditionalDiscountCurrencyAmount(voucherId) {
  return VoucherOptimizePureCal.getAdditionalDiscount(voucherId);
}

function getOtherChargesAfterTaxTotal(voucherId, otherChargesAfterTaxArrayRef) {
  return VoucherOptimizePureCal.calculateOtherChargesAfterTaxSum(
    voucherId,
    otherChargesAfterTaxArrayRef
  );
}

function getOtherChargesTotal(voucherId, otherChargesArrayRef) {
  return VoucherOptimizePureCal.calculateOtherChargesSum(voucherId, otherChargesArrayRef);
}

function getBillAmtAndDueAmt(voucherId, vouchers, decimalplaces) {
  const billFinalAmount = VoucherOptimizePureCal.getBillFinalAmount(voucherId);
  // This is calculated from backend api and is no longer needed in payload
  const dueAmount = billFinalAmount;
  return { dueAmount, billFinalAmount };
}

function getBillDiscountAmount(voucherId) {
  return VoucherOptimizePureCal.getLineDiscountSum(voucherId);
}

function getBillItemsPrice(voucherId) {
  return VoucherOptimizePureCal.getQtySellPriceSum(voucherId);
}

function getBillTaxAmount(voucherId) {
  return VoucherOptimizePureCal.getTaxSum(voucherId);
}

function getLineAmountSum(
  voucherId,
  lineItem,
  oldLineItem,
  additionalDiscount,
  hasExclusiveDiscount,
  salesDiscount,
  couponDiscount,
  otherCharges,
  partyId,
  partyGstBusinessTypeRef,
  partyStateRef,
  branStateRef
) {
  return VoucherOptimizePureCal.updateLineAmountSum(
    voucherId,
    lineItem,
    oldLineItem,
    additionalDiscount,
    hasExclusiveDiscount,
    salesDiscount,
    couponDiscount,
    otherCharges,
    partyId,
    partyGstBusinessTypeRef,
    partyStateRef,
    branStateRef
  );
}

function getAutoPopulateRoundoffData(voucherId) {
  return VoucherOptimizePureCal.getRoundOffAddjustmentValues(voucherId);
}

function getCalculatedItems(
  lineItem,
  partyGstBusinessTypeRef,
  oldUnit,
  newUnit,
  partyStateRef,
  branStateRef,
  adjustQtyFlag = false
) {
  const {
    qtySellPrice,
    discountAmount,
    lineAmount,
    lineTax,
    unitPrice,
    unit
  } = VoucherOptimizePureCal.calculateLineItem(
    lineItem,
    partyGstBusinessTypeRef,
    oldUnit,
    newUnit,
    partyStateRef,
    branStateRef,
    adjustQtyFlag
  );

  const calculatedLineItem = {
    ...lineItem,
    qtySellPrice: qtySellPrice,
    lineAmount: lineAmount,
    discountAmount: discountAmount,
    taxAmount: lineTax,
    unitPrice,
    unit
  };

  return calculatedLineItem;
}
