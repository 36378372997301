import { navigate } from './nav';
import * as api from '../api/user';

import {
  OTP_REQUESTED,
  OTP_SUCCESS,
  OTP_FAILED,
  OTP_VERIFICATION_REQUESTED,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILED,
  UPDATE_USER_NAME_REQUESTED,
  UPDATE_USER_NAME_SUCCESS,
  UPDATE_USER_NAME_FAILED,
  PASSWORD_RESET_OTP_REQUESTED,
  PASSWORD_RESET_OTP_SUCCESS,
  PASSWORD_RESET_OTP_FAILED,
  PASSWORD_RESET_VERIFICATION_REQUESTED,
  PASSWORD_RESET_VERIFICATION_SUCCESS,
  PASSWORD_RESET_VERIFICATION_FAILED,
  UPDATE_USER_EMAIL_REQUESTED,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_FAILED,
  UPDATE_USER_GUIDE_STATUS_SUCCESS,
  UPDATE_USER_GUIDE_STATUS_FAILED,
  GET_USER_GUIDE_STATUS_SUCCESS,
  GET_USER_GUIDE_STATUS_FAILED,
  DELETE_USER_ACCESS_TOKEN_REQUESTED,
  DELETE_USER_ACCESS_TOKEN_SUCCESS,
  DELETE_USER_ACCESS_TOKEN_FAILED,
  GET_USER_REFERRALS_REQUESTED,
  GET_USER_REFERRALS_SUCCESS,
  GET_USER_REFERRALS_FAILED,
  USER_TAB_LOGOUT,
  USER_LOGOUT,
  REMOVE_BUSINESS_ADMIN_OTP_REQUESTED,
  REMOVE_BUSINESS_ADMIN_OTP_SUCCESS,
  REMOVE_BUSINESS_ADMIN_OTP_FAILED,
  OTP_SIGN_SUCCESS,
  OTP_SIGN_REQUESTED,
  OTP_SIGN_FAILED,
  SET_USER_NAME_REQUESTED,
  SET_USER_NAME_FAILED,
  SET_USER_NAME_SUCCESS,
  FETCH_USER_PROFILE_SUCCESS
} from './types';
import { serverError, setUserAuthToken } from '../api/server';
import { openSnackbar } from './snackbar';
import { ERROR, USER_TOKEN } from '../constants';
import mixpanel from 'mixpanel-browser';
import { socketDisconnect } from '../socket';
import { clearAllStorage } from '../utils';

const generateOtp = (phone, countryCode = 91, testing) => dispatch => {
  dispatch({ type: OTP_REQUESTED, payload: {} });
  testing = testing || (process.env.REACT_APP_NODE_ENV !== 'production' ? true : false);
  let payload = {
    countryCode,
    phone,
    testing
  };
  api
    .generateOtp(payload)
    .then(result => {
      dispatch({ type: OTP_SUCCESS, payload: result });
    })
    .catch(error => {
      dispatch({ type: OTP_FAILED, payload: error });
    });
};

const verifyOtp = (phone, countryCode, otpToken) => dispatch => {
  dispatch({ type: OTP_SIGN_REQUESTED });
  mixpanel.track('sign-in-verify-otp-requested', { phone: phone });
  let data = { phone, countryCode, otpToken };
  api
    .verifyOtp(data)
    .then(result => {
      setUserAuthToken(result.accessToken.id);
      dispatch({ type: OTP_SIGN_SUCCESS, payload: result });
      if (result.phoneVerified && result.iUser.name) {
        dispatch(getUserGuidesStatus());
        mixpanel.track('sign-in-verify-otp-success', { phone: phone });
        navigate(dispatch, `/companies/list`);
      }
    })
    .catch(error => {
      mixpanel.track('sign-in-verify-otp-failed', { phone: phone });
      dispatch(openSnackbar(error.response.data.error.message, ERROR));
      dispatch({ type: OTP_SIGN_FAILED, payload: error });
    });
};

export const registerUser = (name, email, referredByCode, phone, countryCode = 91) => dispatch => {
  dispatch({ type: SET_USER_NAME_REQUESTED });
  let data = { phone: phone, countryCode };
  if (name) {
    data.name = name;
  }
  if (email) {
    data.email = email;
  }
  if (referredByCode) {
    data.referredByCode = referredByCode;
  }
  api
    .registerUser(data)
    .then(result => {
      dispatch(getUserGuidesStatus());
      dispatch({ type: SET_USER_NAME_SUCCESS });
      navigate(dispatch, `/companies/list`);
    })
    .catch(error => {
      dispatch(openSnackbar(error.response.data.error.message, ERROR));
      dispatch({ type: SET_USER_NAME_FAILED, payload: error });
    });
};
export const updateUserName = payload => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_NAME_REQUESTED, payload: {} });
  const {
    user: { id }
  } = getState();
  api
    .updateUserName(id, payload)
    .then(response => {
      dispatch({ type: UPDATE_USER_NAME_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: UPDATE_USER_NAME_FAILED, payload: error }));
};

export const removeUserViaPasscode = passcode => (dispatch, getState) => {
  api
    .removeUserViaPasscode(passcode)
    .then(response => {
      dispatch(openSnackbar('All Users are Removed'));
      navigate(dispatch, '/companies/list');
    })
    .catch(error => {
      serverError(error);
      navigate(dispatch, '/companies/list');
    });
};

export const retrieveUserViaPasscode = passcode => (dispatch, getState) => {
  api
    .retrieveUserViaPasscode(passcode)
    .then(response => {
      dispatch(openSnackbar('All Users are Restored'));
      navigate(dispatch, '/companies/list');
    })
    .catch(error => {
      serverError(error);
      navigate(dispatch, '/companies/list');
    });
};

export const updateUserEmail = payload => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_EMAIL_REQUESTED, payload: {} });
  const {
    user: { id }
  } = getState();
  api
    .updateUserEmail(id, payload)
    .then(response => {
      dispatch({ type: UPDATE_USER_EMAIL_SUCCESS, payload: response });
    })
    .catch(error => dispatch({ type: UPDATE_USER_EMAIL_FAILED, payload: error }));
};

export const fetchUserIfscCodeDetails = (userId, ifscCode) => () => {
  return api.fetchIfscCode(userId, ifscCode);
};

export const passwordResetReq = (phone, testing) => (dispatch, getState) => {
  const {
    user: {
      iUser: { countryCode = 91 }
    }
  } = getState();

  dispatch({ type: PASSWORD_RESET_OTP_REQUESTED, payload: {} });
  testing = testing || (process.env.REACT_APP_TESTING === 'true' ? true : false);
  api
    .passwordResetReq({ phone: phone, testing: testing, countryCode })
    .then(result => {
      if (testing) {
        alert(`test otp is: ${result.smsId.otp}`);
      }
      dispatch({ type: PASSWORD_RESET_OTP_SUCCESS, payload: result });
    })
    .catch(error => {
      dispatch({ type: PASSWORD_RESET_OTP_FAILED, payload: error });
    });
};

export const passwordResetVerification = (phone, otpToken, name) => dispatch => {
  dispatch({ type: PASSWORD_RESET_VERIFICATION_REQUESTED, payload: {} });

  const data = name ? { name, phone, otpToken } : { phone, otpToken };
  api
    .passwordResetVerification(data)
    .then(result => {
      // setUserAuthToken(result.accessToken.id);
      dispatch({ type: PASSWORD_RESET_VERIFICATION_SUCCESS, payload: result });
      // navigate(dispatch, `/companies/reset-password`);
    })
    .catch(error => {
      dispatch(openSnackbar(error.response.data.error.message, ERROR));
      dispatch({ type: PASSWORD_RESET_VERIFICATION_FAILED, payload: error });
    });
};

export const updateUserGuideStatus = payload => (dispatch, getState) => {
  const {
    user: { id }
  } = getState();
  api
    .patchUserGuideStatus(id, payload)
    .then(response => {
      dispatch({ type: UPDATE_USER_GUIDE_STATUS_SUCCESS });
      dispatch(getUserGuidesStatus());
    })
    .catch(error => dispatch({ type: UPDATE_USER_GUIDE_STATUS_FAILED, payload: error }));
};

export const getUserGuidesStatus = () => (dispatch, getState) => {
  const {
    user: { id }
  } = getState();
  api
    .fetchUserGuideStatus(id)
    .then(response => {
      dispatch({ type: GET_USER_GUIDE_STATUS_SUCCESS, payload: response });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: GET_USER_GUIDE_STATUS_FAILED, payload: error });
    });
};

export const deleteUserAccessToken = (token, callback) => (dispatch, getState) => {
  const {
    user: { id }
  } = getState();
  dispatch({ type: DELETE_USER_ACCESS_TOKEN_REQUESTED });
  api
    .deleteUserToken(id, token)
    .then(response => {
      dispatch({ type: DELETE_USER_ACCESS_TOKEN_SUCCESS, payload: response });
      callback && callback();
    })
    .catch(error => {
      console.log(error);
      callback && callback();
      dispatch({ type: DELETE_USER_ACCESS_TOKEN_FAILED, payload: error });
    });
};
export const getUserReferrals = () => (dispatch, getState) => {
  const {
    user: { id }
  } = getState();
  dispatch({ type: GET_USER_REFERRALS_REQUESTED });
  api
    .fetchUserReferrals(id)
    .then(response => {
      dispatch({ type: GET_USER_REFERRALS_SUCCESS, payload: response });
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: GET_USER_REFERRALS_FAILED, payload: error });
    });
};

export const logoutUser = () => (dispatch, getState) => {
  const userToken = localStorage.getItem(USER_TOKEN);
  mixpanel.track('signout-user');
  dispatch({ type: USER_LOGOUT });
  dispatch(
    deleteUserAccessToken(userToken, () => {
      dispatch({ type: USER_TAB_LOGOUT });
      socketDisconnect();
      clearAllStorage();
      navigate(dispatch, '/login');
    })
  );
};

export const generateOtpRequest = (phone, testing) => (dispatch, getState) => {
  const {
    user: { id }
  } = getState();
  dispatch({ type: REMOVE_BUSINESS_ADMIN_OTP_REQUESTED, payload: {} });
  testing = testing || (process.env.REACT_APP_TESTING === 'true' ? true : false);
  api
    .generateUserOtp(id, testing)
    .then(result => {
      if (testing) {
        alert(`test otp is: ${result.smsId.otp}`);
      }
      dispatch({ type: REMOVE_BUSINESS_ADMIN_OTP_SUCCESS, payload: result });
    })
    .catch(error => {
      dispatch({ type: REMOVE_BUSINESS_ADMIN_OTP_FAILED, payload: error });
    });
};

export const getUserProfile = () => (dispatch, getState) => {
  const {
    user: { id }
  } = getState();
  api
    .getUserProfile(id)
    .then(response => {
      dispatch({ type: FETCH_USER_PROFILE_SUCCESS, payload: response });
    })
    .catch(error => {
      serverError(error);
    });
};
export { generateOtp, verifyOtp };
