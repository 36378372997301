import {
  SET_DUPLICATE_ITEM_BEHAVIOUR,
  TOGGLE_AUTO_ADD_QTY,
  TOGGLE_AUTO_REPEAT_LAST_ITEM,
  TOGGLE_ITEM_LAST_SP_UNIT_PRICE,
  TOGGLE_LINE_ITEM_BARCODE_SEARCH,
  UPDATE_USER_COMPANY_VOUCHER_SETTING,
  UPDATE_VOUCHER_LINE_ITEM_TABLE_COLUMN_VISIBILITY
} from './types';
import * as api from '../api/companies';

export const setDuplicateLineItemBehaviour = (key, value) => dispatch => {
  dispatch({ type: SET_DUPLICATE_ITEM_BEHAVIOUR, payload: value, key });
  dispatch(updateUserCompanyVoucherSettings(key));
};

export const toggleLineItemBarcodeSearch = (key, flag) => dispatch => {
  dispatch({ type: TOGGLE_LINE_ITEM_BARCODE_SEARCH, flag, key });
  dispatch(updateUserCompanyVoucherSettings(key));
};

export const toggleItemLastSPUnitPrice = (key, flag) => dispatch => {
  dispatch({ type: TOGGLE_ITEM_LAST_SP_UNIT_PRICE, flag, key });
  dispatch(updateUserCompanyVoucherSettings(key));
};

export const toggleAutoRepeatLastItem = (key, flag) => dispatch => {
  dispatch({ type: TOGGLE_AUTO_REPEAT_LAST_ITEM, flag, key });
  dispatch(updateUserCompanyVoucherSettings(key));
};

export const toggleAutoAddQty = (key, flag) => dispatch => {
  dispatch({ type: TOGGLE_AUTO_ADD_QTY, flag, key });
  dispatch(updateUserCompanyVoucherSettings(key));
};

export const updateVoucherLineItemTableColumnVisibility = (key, hiddenColumns) => dispatch => {
  dispatch({ type: UPDATE_VOUCHER_LINE_ITEM_TABLE_COLUMN_VISIBILITY, hiddenColumns, key });
  dispatch(updateUserCompanyVoucherSettings(key));
};

export const updateUserCompanyVoucherSettings = key => (dispatch, getState) => {
  const {
    currentCompany,
    user: {
      id,
      userCompanySettings: { id: userSettingId }
    },
    settings: {
      voucherSettings: { [key]: currVoucherSettings }
    }
  } = getState();
  const payload = {
    voucherSettings: {
      [key]: currVoucherSettings
    }
  };
  api
    .updateUserCompanyVoucherSettings(currentCompany.id, id, userSettingId, payload)
    .then(response => {
      dispatch({
        type: UPDATE_USER_COMPANY_VOUCHER_SETTING,
        voucherSettings: response.voucherSettings
      });
    });
};

export const updateAllVoucherSettings = voucherSettings => (dispatch, getState) => {
  const {
    currentCompany,
    user: {
      id,
      userCompanySettings: { id: userSettingId }
    }
  } = getState();
  const payload = {
    voucherSettings
  };
  api
    .updateUserCompanyVoucherSettings(currentCompany.id, id, userSettingId, payload)
    .then(response => {
      dispatch({
        type: UPDATE_USER_COMPANY_VOUCHER_SETTING,
        voucherSettings: response.voucherSettings
      });
    });
};
