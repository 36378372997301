import {
  TABLE_SELECT,
  SELECT_RESET,
  SELECT_HIDDEN_COLUMN,
  SELECT_COLUMN_WIDTH,
  RESET_COLUMN_WIDTH,
  SELECT_RESET_COLUMN_VISIBILITY,
  SET_SORTING_COLUMN,
  RESET_SORTING_COLUMN,
  TABLE_LOADING,
  RESET_ALL_COLUMN_VISIBILITY,
  SET_THUMBNAIL_VIEW_SELECTION,
  ITEM_VARIANT_SELECT_ALL,
  SET_EXPANDED_ROW_IDS,
  TOGGLE_ROW_SELECT,
  TOGGLE_SELECT_ALL,
  RESET_TABLE_SELECTION
} from '../actions/types';
import { getLineItemTableId, removeDuplicates } from '../utils';
import filter from 'lodash/filter';
import { VOUCHER_TYPE_STOCK_ADJUSTMENT } from '../constants';
/* This reducer is used for the REACT dev grid table.
 *  selection state keeps the value of all the index of items that is being seleceted by the user.
 */

const initialState = {
  selection: {
    DEFAULT_TABLE_ID: []
  },
  selectedIds: {
    DEFAULT_TABLE_ID: []
  },
  selectedIdsList: {
    //for selectedIds without page
    DEFAULT_TABLE_ID: []
  },
  hiddenColumn: ['hiddenSkuBarcode', 'hiddenItemCode'],
  sorting: [],
  columnWidth: {
    lineItem: [],
    voucherView: []
  },
  loading: false,
  expandedRowIds: {},
  tableListType: ''
};

const tableHiddenColInitial = {
  DEFAULT_TABLE_ID: ['hiddenSkuBarcode', 'hiddenItemCode', 'inStoreQty'],
  [getLineItemTableId(VOUCHER_TYPE_STOCK_ADJUSTMENT)]: ['inStoreQty']
};

export const table = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_SELECT:
      return {
        ...state,
        selection: {
          ...state.selection,
          [action.key]: action.payload
        }
      };
    case 'RESET_SELECTED_ROW_IDS':
      return {
        ...state,
        selectedIds: {
          DEFAULT_TABLE_ID: []
        },
        selectedIdsList: {
          DEFAULT_TABLE_ID: []
        }
      };
    case SELECT_COLUMN_WIDTH:
      const { value, view } = action.payload;
      const currentColumnWidth = state.columnWidth;
      currentColumnWidth[view] = value;
      return {
        ...state,
        columnWidth: {
          ...currentColumnWidth
        }
      };
    case 'SET_SELECTED_ROW_IDS':
      const {
        tableId,
        rowsData,
        selectedIndex,
        pageNo,
        persistSelectionOnFilterChange = true
      } = action;
      let tempSelectedRows = [];
      selectedIndex.length > 0 &&
        selectedIndex.forEach(element => {
          if (rowsData[element]._id) {
            rowsData[element].id = rowsData[element]._id;
          }
          tempSelectedRows.push(rowsData[element]);
        });
      if (persistSelectionOnFilterChange) {
        if (state.selectedIds[tableId]) {
          return {
            ...state,
            selectedIds: {
              ...state.selectedIds,
              [tableId]: {
                ...state.selectedIds[tableId],
                [pageNo]: tempSelectedRows
              }
            }
          };
        }
        return {
          ...state,
          selectedIds: {
            ...state.selectedIds,
            [tableId]: {
              [pageNo]: tempSelectedRows
            }
          }
        };
      } else {
        const tempSelected = removeDuplicates(state.selectedIdsList[tableId] || [], rowsData, 'id');
        const combinedSelectedIdsList = [...tempSelected, ...tempSelectedRows];
        return {
          ...state,
          selectedIdsList: {
            ...state.selectedIdsList,
            [tableId]: combinedSelectedIdsList
          }
        };
      }
    case TOGGLE_ROW_SELECT:
      const { tableKey, row, flag, rowId } = action;
      const oldSelectedList = state.selectedIdsList[tableKey] || [];
      const tempRows = flag
        ? [...oldSelectedList, row]
        : filter(state.selectedIdsList[tableKey], curRow => {
            console.log(curRow.id, rowId);
            return curRow.id !== rowId;
          });
      console.log(tempRows);
      return {
        ...state,
        selectedIdsList: {
          ...state.selectedIdsList,
          [tableKey]: tempRows
        }
      };
    case TOGGLE_SELECT_ALL:
      return {
        ...state,
        selectedIdsList: {
          ...state.selectedIdsList,
          [action.tableKey]: action.rows
        }
      };

    case ITEM_VARIANT_SELECT_ALL:
      return {
        ...state,
        selectedIdsList: {
          ...state.selectedIdsList,
          [action.tableId]: action.rows
        },
        selection: {
          ...state.selection,
          [action.tableId]: action.selection
        }
      };
    case SET_EXPANDED_ROW_IDS:
      return {
        ...state,
        expandedRowIds: {
          ...state.expandedRowIds,
          [action.tableId]: action.rowIds
        }
      };

    case SET_THUMBNAIL_VIEW_SELECTION:
      if (action.flag) {
        return {
          ...state,
          selection: {
            ...state.selection,
            [action.key]: [...state.selection[action.key], ...[action.index]]
          },
          ...(action.persistSelectionOnFilterChange
            ? {
                selectedIds: {
                  ...state.selectedIds,
                  [action.key]: {
                    ...(state.selectedIds[action.key] || {}),
                    [action.pageNo]: [
                      ...(state.selectedIds[action.key][action.pageNo] || []),
                      ...[action.row]
                    ]
                  }
                }
              }
            : {
                selectedIdsList: {
                  ...state.selectedIdsList,
                  [action.key]: [...(state.selectedIdsList[action.key] || []), ...[action.row]]
                }
              })
        };
      } else {
        return {
          ...state,
          selection: {
            ...state.selection,
            [action.key]: filter(state.selection[action.key], item => item !== action.index)
          },
          ...(action.persistSelectionOnFilterChange
            ? {
                selectedIds: {
                  ...state.selectedIds,
                  [action.key]: {
                    ...(state.selectedIds[action.key] || {}),
                    [action.pageNo]: filter(
                      state.selectedIds[action.key][action.pageNo],
                      row => row.id !== action.row.id
                    )
                  }
                }
              }
            : {
                selectedIdsList: {
                  ...state.selectedIdsList,
                  [action.key]: filter(
                    state.selectedIds[action.key],
                    row => row.id !== action.row.id
                  )
                }
              })
        };
      }
    case SET_SORTING_COLUMN:
      return {
        ...state,
        sorting: action.payload,
        tableListType: action.tableListType
      };
    case RESET_SORTING_COLUMN:
      return {
        ...state,
        sorting: [],
        tableListType: ''
      };
    case TABLE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case RESET_COLUMN_WIDTH:
      return {
        ...state,
        columnWidth: {
          lineItem: [],
          voucherView: []
        }
      };
    case SELECT_RESET:
      if (action.key) {
        return {
          ...state,
          selection: {
            ...state.selection,
            [action.key]: []
          },
          selectedIds: {
            ...state.selectedIds,
            [action.key]: []
          },
          selectedIdsList: {
            ...state.selectedIdsList,
            [action.key]: []
          }
        };
      }
      return {
        ...state,
        selection: {
          DEFAULT_TABLE_ID: []
        },
        selectedIds: {
          DEFAULT_TABLE_ID: []
        }
      };
    case RESET_TABLE_SELECTION:
      return {
        ...state,
        selectedIdsList: {
          ...state.selectedIdsList,
          [action.tableKey]: []
        }
      };
    default:
      return state;
  }
};

export const tableHiddenColumns = (state = tableHiddenColInitial, action) => {
  switch (action.type) {
    case SELECT_HIDDEN_COLUMN:
      return {
        ...state,
        [action.key]: action.payload
      };
    case SELECT_RESET_COLUMN_VISIBILITY:
      return {
        ...state,
        [action.key]: ['hiddenSkuBarcode']
      };
    case RESET_ALL_COLUMN_VISIBILITY:
      return {
        ...tableHiddenColInitial
      };
    default:
      return state;
  }
};
