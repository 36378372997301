import React from 'react';
import { getGeocodeByAddress } from './helperFunctions';

export class CityStateAutopopulate extends React.Component {
  state = {
    city: '',
    state: ''
  };
  geocodeByAddress = postalCode => {
    if (postalCode !== '') {
      getGeocodeByAddress(postalCode)
        .then(data => {
          this.setState({
            city: data.city,
            state: data.state
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  getStateAndHelpers = () => {
    return {
      location: {
        state: this.state.state,
        city: this.state.city
      },
      geocodeByAddress: this.geocodeByAddress
    };
  };

  render() {
    return this.props.children(this.getStateAndHelpers());
  }
}
