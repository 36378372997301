import {
  items,
  itemAttributes,
  itemGroups,
  itemTags,
  itemCatalogues,
  getTempItemData,
  getItemsNames,
  getItemsNamesFromRefVoucher,
  getItems,
  getOnShowAddItems,
  getShowItemGroup,
  getSelectedItemGroup,
  getAllItemGroups,
  // getAllItemCollections,
  getItemPayloadDetails,
  getItemPersistData,
  getShowItemSalesPrice,
  getItemGroupSearchText,
  getShowSalesPriceValue,
  getBranchesForItemOpeingStock,
  getItemError,
  getParties,
  getItemGroupAndProperty,
  getItemsByGroup,
  getTabViewItemGroup,
  getErrorStatus,
  getItemUnits,
  getItemsCountByGroup,
  getItemsByGroupPageNo
} from './items';
import {
  vendorAccountsDataList,
  contacts,
  partyTags,
  checkForName,
  modalFlags,
  internalNotes,
  submitResponse,
  getNewContactAdded,
  getAddContactToggleState,
  updateContactName,
  partyRelationTypes,
  partyAccountListFilters,
  getPartyAccountCurrentBalance
} from './contacts';

import {
  getSelectedVoucherDetails,
  lastVisitedVoucher,
  getSavedVouchersSuccess,
  vouchersUpdateState,
  getbranchNamesArray,
  getHideShippingDetails,
  getVoucherDurationType,
  voucherTypesUpdateState,
  selectedVoucher,
  getTempItems,
  vouchers,
  getShowTransportCard,
  getVoucherByNumber,
  getRoundOffValues,
  getIssueDate,
  getParty,
  getSelectedAccountDetails,
  getTransportDetails,
  getselectedBusinessAccount,
  getSearchText,
  getSelectedPurchaseVoucher,
  getopenAddDrawer,
  getUnpaidVouchersList,
  // getvoucherDetailsAccounts,
  getAccountsDetails,
  getToogleBusinessCard,
  getSummaryBlockData,
  getStartDate,
  genratePurchaseSalesPayload,
  getTotalQty,
  getShowCardForVoucherDetails,
  getNarrationValue,
  getTaxAnalysisData,
  getotherChargesTaxBreakup,
  getAccountsName,
  getVoucherDetails,
  getShippingAddress,
  getbusinessAccountsSuggestions,
  getSelectedSalesVoucher,
  getlineAmountSum,
  getVoucherType,
  getShowLink,
  getCurrentBalance,
  getShowLinkForVoucherDetails,
  getshowAlertFlagStatus,
  getAlertDialogMessage,
  getShowItemPicker,
  getLineItemSearchText,
  getTransportationLinkStatus,
  getSelectedCreditNoteVoucher,
  getSeletedDebitNoteVoucher,
  addEditDrawerFlags,
  getBranchSearchText,
  generateChallanLinkedSalesPurchasePayload,
  generatePayloadForLinkedEstimation,
  generateChallanPayload,
  getChallanListForSPVoucher,
  getSelectedPchallanVoucher,
  getSelectedSchallanVoucher,
  getSelectedSestimationVoucher,
  getSelectedPestimationVoucher,
  getVoucherTypes,
  getAccountTypes,
  getSelectedExpenseVoucher,
  generateExpensePayload,
  getDiscountBreakup,
  getTotalQtyFromSelectedVoucher,
  getListOfCurrentVoucher,
  getBranchesArray,
  getCurrentVoucherListPageNo,
  itemAvaliableQtyList,
  getTotalBeforeOtherCharges
} from './vouchers';

import {
  teamMembersData,
  similarCompanyCheck,
  registerCompanyData,
  similarCompaniesData,
  companies,
  companiesData,
  currentCompany,
  states,
  cities,
  countries,
  partiesStates,
  getFinancialYear,
  industryTypes,
  partyAccountTypes,
  getDecimalPoints
} from './companies';
import { marketing } from './marketing';
import { settings } from './settings';
import { user } from './user';
import { snackbar } from './snackbar';
import { imageZoomBox } from './imageZoomBox';
import { table, tableHiddenColumns } from './table';
import { guides } from './guides';
import {
  reports,
  getDetailButtonStatus,
  fetchTrailBalanceData,
  getHeaderProperties,
  getGroupDetails,
  getDateRange,
  getCompanyNameAndId,
  getLedgerButtonStatus,
  getLedgerWiseDetails,
  getYearlyWiseDetails,
  getMonthlyWiseDetails,
  fetchProfitAndLoss,
  fetchBalanceSheet,
  getDayBook,
  getCityWiseReportData
} from './reports';
import {
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_PESTIMATION
} from '../constants';

import { progressTracker, getItemGroupTrackerById } from './progressTracker';

export const blacklist = [
  'progressTracker',
  'vouchers',
  'contacts',
  'items',
  'itemAttributes',
  'itemTags',
  'partyTags',
  'reports',
  'selectedVoucher',
  'currentCompany',
  'modalFlags',
  'internalNotes',
  'submitResponse',
  'addEditDrawerFlags',
  'partyAccountListFilters',
  'snackbar',
  'router',
  'itemAvaliableQtyList'
];

//named export selectors
export {
  getShowLink,
  getbusinessAccountsSuggestions,
  getSelectedVoucherDetails,
  getSavedVouchersSuccess,
  getShowCardForVoucherDetails,
  // getvoucherDetailsAccounts,
  getUnpaidVouchersList,
  getHideShippingDetails,
  getselectedBusinessAccount,
  getItemsNames,
  getItemsNamesFromRefVoucher,
  getVoucherType,
  getShowItemGroup,
  getSelectedPurchaseVoucher,
  getSelectedSalesVoucher,
  getSelectedItemGroup,
  getItemError,
  getParties,
  getItemGroupAndProperty,
  getItemsByGroup,
  getTabViewItemGroup,
  getErrorStatus,
  getAllItemGroups,
  // getAllItemCollections,
  getItemPayloadDetails,
  getItemPersistData,
  getShowItemSalesPrice,
  getItemGroupSearchText,
  getShowSalesPriceValue,
  getVoucherDetails,
  getbranchNamesArray,
  getItems,
  getOnShowAddItems,
  getShowTransportCard,
  getTransportDetails,
  getNewContactAdded,
  getAddContactToggleState,
  getAccountsDetails,
  getStartDate,
  getIssueDate,
  getParty,
  getDetailButtonStatus,
  fetchTrailBalanceData,
  getHeaderProperties,
  getLedgerButtonStatus,
  getLedgerWiseDetails,
  getYearlyWiseDetails,
  getMonthlyWiseDetails,
  getDateRange,
  getCompanyNameAndId,
  getGroupDetails,
  fetchProfitAndLoss,
  fetchBalanceSheet,
  getDayBook,
  getCityWiseReportData,
  getSelectedAccountDetails,
  getVoucherByNumber,
  getCurrentBalance,
  getShowLinkForVoucherDetails,
  getshowAlertFlagStatus,
  getAlertDialogMessage,
  getShowItemPicker,
  getLineItemSearchText,
  getTransportationLinkStatus,
  getSelectedCreditNoteVoucher,
  getSeletedDebitNoteVoucher,
  getlineAmountSum,
  getTempItems,
  getNarrationValue,
  getopenAddDrawer,
  getSummaryBlockData,
  getTotalQty,
  getShippingAddress,
  getToogleBusinessCard,
  getSearchText,
  getRoundOffValues,
  getTaxAnalysisData,
  getotherChargesTaxBreakup,
  genratePurchaseSalesPayload,
  getBranchesForItemOpeingStock,
  getBranchSearchText,
  generateChallanLinkedSalesPurchasePayload,
  generatePayloadForLinkedEstimation,
  generateChallanPayload,
  getChallanListForSPVoucher,
  getSelectedPchallanVoucher,
  getVoucherTypes,
  getAccountTypes,
  getSelectedSchallanVoucher,
  getSelectedSestimationVoucher,
  getSelectedPestimationVoucher,
  getItemUnits,
  getSelectedExpenseVoucher,
  generateExpensePayload,
  getItemsCountByGroup,
  getItemsByGroupPageNo,
  getDecimalPoints,
  getFinancialYear,
  getAccountsName,
  getDiscountBreakup,
  getTotalQtyFromSelectedVoucher,
  getListOfCurrentVoucher,
  getCurrentVoucherListPageNo,
  getItemGroupTrackerById,
  getBranchesArray,
  getPartyAccountCurrentBalance,
  getTotalBeforeOtherCharges
};

//export default reducers
export default {
  voucherTypesUpdateState,
  getVoucherDurationType,
  similarCompaniesData,
  vendorAccountsDataList,
  vouchersUpdateState,
  similarCompanyCheck,
  registerCompanyData,
  lastVisitedVoucher,
  updateContactName,
  getTempItemData,
  teamMembersData,
  selectedVoucher,
  currentCompany,
  states,
  cities,
  countries,
  partiesStates,
  industryTypes,
  partyAccountTypes,
  checkForName,
  modalFlags,
  internalNotes,
  submitResponse,
  items,
  itemAttributes,
  itemGroups,
  itemTags,
  itemCatalogues,
  companies,
  companiesData,
  vouchers,
  contacts,
  partyTags,
  user,
  snackbar,
  imageZoomBox,
  table,
  tableHiddenColumns,
  guides,
  reports,
  addEditDrawerFlags,
  partyRelationTypes,
  partyAccountListFilters,
  marketing,
  settings,
  progressTracker,
  itemAvaliableQtyList
};

//genral selectors
export const getPath = ({
  router: {
    location: { pathname }
  }
}) => pathname;
export const isEditMode = ({
  router: {
    location: { pathname }
  }
}) => pathname.split('/').includes('edit');

export const isPurchaseVoucherPage = ({
  router: {
    location: { pathname }
  }
}) => {
  let path = pathname.split('/');
  return (
    path.includes(VOUCHER_TYPE_PURCHASE) ||
    path.includes(VOUCHER_TYPE_PCHALLAN) ||
    path.includes(VOUCHER_TYPE_PESTIMATION)
  );
};

export const getselectedVoucherId = ({ vouchers: { _selectedVoucherId } }) => _selectedVoucherId;
