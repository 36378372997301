import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from '../actions/types';

const initialState = {
  message: 'Action Success',
  type: 'success',
  open: false
};

export const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      const { message, type = 'success' } = action.payload;
      return {
        ...state,
        message,
        type,
        open: true
      };

    case SNACKBAR_CLOSE:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};
