import {
  companyAuthFetch,
  companyAuthFetchWithAxiosCancelToken,
  extractData,
  fetch
} from './server';
import * as CONSTANTS from '../constants';

//migrate API's

export const migrate = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/vouchers/migrate`, payload);

export const getMigrateFinalAmounts = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/aj-final-amount?ids=${query}`);

export const migrateAj = (companyId, payload, payloadId) =>
  companyAuthFetch.post(
    `/i-companies/${companyId}/vouchers/migrate-to-aj?payloadId=${payloadId}`,
    payload
  );

export const migrateTally = (companyId, payload, payloadId) =>
  companyAuthFetch.post(
    `/i-companies/${companyId}/vouchers/migrate-to-tally?payloadId=${payloadId}`,
    payload
  );

export const migrateCompany = companyId =>
  companyAuthFetch.post(`/i-companies/${companyId}/tally-aj`);

export const fetchMigratedVouchers = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/migrated-vouchers?ids=${query}`);

export const linkAjCompany = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/link/aj`, payload);

export const linkTallyCompany = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/link/tally`, payload);

export const delinkAjCompany = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/delink/aj`, payload);

export const delinkTallyCompany = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/delink/tally`, payload);

export const linkTallyParty = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/link/tally-party`, payload);

export const linkAjParty = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/link/aj-party`, payload);

export const delinkTallyParty = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/delink/tally-party`, payload);

export const delinkAjParty = (companyId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/delink/aj-party`, payload);

//
const createVoucher = (branchId, companyId, { payloadId, ...payload }, type, query) => {
  return companyAuthFetch
    .post(
      `/i-companies/${companyId}/i-branches/${branchId}/vouchers/${type}?payloadId=${payloadId}${companyId}${
        query ? query : type
      }`,
      payload
    )
    .then(extractData);
};

const editVoucher = (vouchersId, branchId, companyId, payload, type) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/i-branches/${branchId}/vouchers/${vouchersId}/${type}`, payload)
    .then(extractData);

export const fetchShareVoucherData = (companyId, refId, selectedVocherId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/vouchers/${selectedVocherId}/share-status`)
    .then(extractData);

export const fetchPublicShareVoucherData = (companyId, selectedVocherId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/vouchers/${selectedVocherId}/share-status/public/page/1`)
    .then(extractData);

export const fetchVoucher = (id, searchString) =>
  companyAuthFetch
    .get(`/i-companies/${id}/vouchers/search?searchString=${searchString}`)
    .then(extractData);

export const fetchVouchersSuggestionByNo = (id, searchString, type, query = '') => {
  let endPoint = `/i-companies/${id}/vouchers/search3?searchText=${searchString}`;
  if (type && type !== '')
    endPoint = `/i-companies/${id}/vouchers/search3?searchText=${searchString}&voucherType=${type}${query}`;
  return companyAuthFetch.get(endPoint).then(extractData);
};
export const fetchVoucherLineItems = (companyId, voucherId) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/vouchers/${voucherId}/line-items `)
    .then(extractData);
};
export const fetchChallanLinkedSales = (companyId, startDate, endDate, page) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/vouchers/challan/sales-linked/page/${page}?startTime=${startDate}&endTime=${endDate}`
    )
    .then(extractData);

export const fetchPchallanLinkedPurchase = (companyId, startDate, endDate, page) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/vouchers/challan/purchase-linked/page/${page}?startTime=${startDate}&endTime=${endDate}`
    )
    .then(extractData);

export const fetchSestimationLinkedSales = (companyId, startDate, endDate, page) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/vouchers/sestimation/sales-linked/page/${page}?startTime=${startDate}&endTime=${endDate}`
    )
    .then(extractData);
export const fetchPestimationLinkedPurchase = (companyId, startDate, endDate, page) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/vouchers/pestimation/purchase-linked/page/${page}?startTime=${startDate}&endTime=${endDate}`
    )
    .then(extractData);
export const fetchBranch = companyId =>
  companyAuthFetch.get(`i-companies/${companyId}/i-branches`).then(extractData);

export const shareVoucherPost = (companyId, accId, payload) => {
  let url = `/i-companies/${companyId}/vouchers/share`;
  if (accId) {
    url = url + `?accountId=${accId}`;
  }
  return companyAuthFetch.post(url, payload).then(extractData);
};

export const fetchMergeEstimation = (branchId, companyId, voucherIds) =>
  companyAuthFetch.get(
    `/i-companies/${companyId}/i-branches/${branchId}/vouchers/merge-estimation?ids=${JSON.stringify(
      voucherIds
    )}`
  );

export const getLastLineItemPrice = (companyId, itemId, voucherType, query) =>
  companyAuthFetch.get(
    `/i-companies/${companyId}/items/${itemId}/last-line-item/${voucherType}?${query}`
  );

export const internalNotesPost = (companyId, voucherID, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/vouchers/${voucherID}/internal-note`, payload);

export const fetchVouchers = (companyId, startDate, endDate) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/vouchers/types?startDate=${startDate}&endDate=${endDate}`)
    .then(extractData);
};

export const getVoucher = voucherId =>
  companyAuthFetch.get(`vouchers/${voucherId}`).then(extractData);

export const getShareVoucher = shareId =>
  fetch.get(`share-statuses/voucher/share-status/${shareId}`).then(extractData);

export const getShareVoucherPdf = shareId =>
  fetch.get(`share-statuses/voucher/share-status/${shareId}/pdf`).then(extractData);

export const generateVoucherPdf = voucherId =>
  companyAuthFetch.get(`vouchers/${voucherId}/pdf/download`).then(extractData);

export const increamentVoucherView = (voucherId, shareId) =>
  companyAuthFetch.put(`/vouchers/${voucherId}/share/${shareId}/increment-count`).then(extractData);

export const getVoucherData = (branchId, companyId, userId, voucherId) =>
  companyAuthFetchWithAxiosCancelToken
    .get(`/i-companies/:companyId/vouchers/:voucherId`, {
      params: {
        companyId,
        voucherId
      }
    })
    .then(extractData);

export const getShareVoucherData = (companyId, branchId, voucherId, businessId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/vouchers/${voucherId}/shareStatus`)
    .then(extractData);

export const handleNoteAddClick = (companyId, voucherId, notesPayload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/vouchers/${voucherId}/internal-note`, notesPayload)
    .then(extractData);

export const handleRecordPaymentSubmitClick = (companyId, voucherId, recordPaymentPayload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/voucher/${voucherId}/record-payment`, recordPaymentPayload)
    .then(extractData);

export const handleAccessToggleClick = (companyId, contact) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/status/${contact.id}/access`, {
      accessEnabled: !contact.accessEnabled
    })
    .then(extractData);

export const handleHideVoucher = (companyId, ids, type) => {
  let query = `ids=${ids.toString()}`;
  return companyAuthFetch.put(`/i-companies/${companyId}/vouchers/hide?${query}`);
};

export const handleHideVoucherByPartyStatement = (companyId, ids, type) => {
  let query = `ids=${ids.toString()}`;
  return companyAuthFetch.put(`/i-companies/${companyId}/vouchers/hide?${query}`);
};

export const handleUnhideVoucher = (companyId, ids, type) => {
  let payload = {
    voucherIds: ids.toString()
  };
  return companyAuthFetch.patch(`/i-companies/${companyId}/vouchers/${type}/unhide`, payload);
};

export const handleUnhideVoucherByPartyStatement = (companyId, ids, type) => {
  let payload = {
    voucherIds: ids.toString()
  };
  return companyAuthFetch.patch(`/i-companies/${companyId}/vouchers/unhide`, payload);
};

export const fetchHideVoucher = (companyId, type, startDate, endDate, page, accId, branchId) => {
  let path = `/i-companies/${companyId}/vouchers/${type}/hidden/page/${page}?type=${type}&startDate=${startDate}&endDate=${endDate}`;
  if (accId.length > 0) path += `&accountId=${accId.toString()}`;
  if (branchId.length > 0) path += `&branchId=${branchId.toString()}`;
  return companyAuthFetch.get(path);
};

export const fetchHideVoucherByPartyStatement = (
  companyId,
  type,
  startDate,
  endDate,
  page,
  accId,
  branchId
) => {
  let path = `/i-companies/${companyId}/vouchers/hidden/page/${page}?startDate=${startDate}&endDate=${endDate}`;
  if (accId.length > 0) path += `&accountId=${accId.toString()}`;
  if (branchId.length > 0) path += `&branchId=${branchId.toString()}`;
  return companyAuthFetch.get(path);
};

export const handleDeleteVoucherClick = (companyId, voucherID) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/vouchers/${voucherID}`);

export const handleMoveToChallan = (companyId, branchId, voucherID, payload) =>
  companyAuthFetch.put(
    `/i-companies/${companyId}/i-branches/${branchId}/vouchers/${voucherID}/challan`,
    payload
  );
export const getItemsByVoucherID = (companyId, voucherID) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/${voucherID}/items`).then(extractData);

export const handlePrintTransportLabel = (companyId, voucherID) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/${voucherID}/transport-label`);

export const handlePreviewVoucherClick = (companyId, voucherID, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/${voucherID}/pdf?${query}`);

export const handleMultiplePrintVoucher = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/multiple-vouchers/pdf?${query}`);

export const handleSKUPreviewVoucherClick = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/sku-bar-code`, payload);

export const handleSKUV3PreviewVoucherClick = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/sku-bar-code/one-per-item`, payload);

export const handleInvockSKUPdfPrint = (companyId, payload, query) =>
  companyAuthFetch.post(`/i-companies/${companyId}/sku-bar-code/invock?${query}`, payload);

//voucher creation apis

export const createSales = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_SALES);

export const createDebit = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'debit');

export const createCredit = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'credit');

export const editCreditNote = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'credit');

export const createStockTransfer = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_STOCK_TRANSFER2);

export const editStockTransfer = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_STOCK_TRANSFER2);

export const createStockAdjustment = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_STOCK_ADJUSTMENT2);

export const editStockAdjustment = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_STOCK_ADJUSTMENT2);

export const editDebitNote = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'debit');

export const createReceipt = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_RECEIPT);

export const createPayment = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PAYMENT);

export const createChallan = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'schallan');

export const createJournal = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_JOURNAL);

export const editJournal = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_JOURNAL);

export const createPurchase = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PURCHASE);

export const createPchallan = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PCHALLAN);

export const createContra = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_CONTRA);

export const createExpense = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_EXPENSE);

export const editContra = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_CONTRA);

// purchase edit
export const editPurchase = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PURCHASE);

export const editPchallan = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PCHALLAN);

export const editSales = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_SALES);

export const editChallan = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_CHALLAN);

export const editExpense = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_EXPENSE);

// edit Receipt Payment
export const editReceipt = (branchId, companyId, voucherId, payload) =>
  editVoucher(voucherId, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_RECEIPT);

export const editPayment = (branchId, companyId, voucherId, payload) =>
  editVoucher(voucherId, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PAYMENT);
//purchase voucher api
export const getSingleVoucher = (voucherId, companyId) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/${voucherId}`).then(extractData);

export const currentBalance = (companyId, businessId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${businessId}/current-balance`)
    .then(extractData);

export const fetchRoundOffConfig = () =>
  companyAuthFetch.get(`/configurations/round-off`).then(extractData);

export const fetchRateCodeConfig = () =>
  companyAuthFetch.get(`/configurations/rate-code-multiplier`).then(extractData);

// export const fetchIndirectExpensesAccounts = companyID =>
//   companyAuthFetch.get(`/i-companies/${companyID}/accounts/indirect-expenses`).then(extractData);

export const fetchCashInHandAccounts = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/cash-in-hand`).then(extractData);

export const fetchBankAccounts = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/bank-accounts`).then(extractData);

// export const fetchAllAccounts = companyID =>
//   companyAuthFetch.get(`/i-companies/${companyID}/accounts`).then(extractData);

export const fetchSalesAccounts = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/sales-accounts`).then(extractData);

export const fetchPurchasesAccounts = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/purchases-accounts`).then(extractData);

export const fetchPurchasesChallan = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/purchase-challan`).then(extractData);

export const fetchSalesChallan = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/sales-challan`).then(extractData);

export const fetchSalesEstimation = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/sales-estimation`).then(extractData);

export const fetchPurchasesEstimation = companyID =>
  companyAuthFetch.get(`/i-companies/${companyID}/accounts/purchases-estimation`).then(extractData);

export const fetchVouchersList = (
  companyID,
  startDate,
  endDate,
  voucherType,
  partyId,
  selectedAccount,
  page = 1,
  preparedBy,
  branch,
  status,
  readLock,
  updateLock,
  sort,
  subType,
  includeDueAmount,
  narration,
  tableListType
) => {
  //TODO Backend need to change the voucherType string and then we can remove this conditions
  let stockType = '';
  if (voucherType === CONSTANTS.VOUCHER_TYPE_CREDIT_NOTE) {
    voucherType = 'credit';
  }
  if (voucherType === CONSTANTS.VOUCHER_TYPE_DEBIT_NOTE) {
    voucherType = 'debit';
  }
  if (voucherType === CONSTANTS.VOUCHER_TYPE_SCHALLAN) {
    voucherType = 'challan';
  }
  if (voucherType === CONSTANTS.VOUCHER_TYPE_STOCK_TRANSFER) {
    voucherType = CONSTANTS.VOUCHER_TYPE_STOCK_TRANSFER2;
    stockType = CONSTANTS.VOUCHER_TYPE_STOCK_TRANSFER;
  }
  if (voucherType === CONSTANTS.VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    voucherType = CONSTANTS.VOUCHER_TYPE_STOCK_TYPE;
    stockType = CONSTANTS.VOUCHER_TYPE_STOCK_ADJUSTMENT;
  }
  let path = `/i-companies/${companyID}/vouchers/${voucherType}/page/${page}?startDate=${startDate}&endDate=${endDate}`;
  if (partyId.length > 0) path += `&partyId=${partyId.toString()}`;
  if (selectedAccount && selectedAccount.id) path += `&accountId=${selectedAccount.id}`;
  if (sort.length > 0 && tableListType === CONSTANTS.VOUCHER_LIST) {
    path += `&sort=${sort[0].columnName} ${sort[0].direction === 'asc' ? 'ASC' : 'DESC'}`;
  } else {
    path += `&sort=createdAt DESC`;
  }
  if (stockType) path += `&type=${stockType}`;
  if (preparedBy.length > 0) path += `&preparedBy=${preparedBy.toString()}`;
  if (branch.length > 0) path += `&branchId=${branch.toString()}`;
  if ((status || status !== '') && status !== 'both') path += `&paidStatus=${status}`;
  if (readLock !== 'both') path += `&readLockEnabled=${readLock}`;
  if (updateLock !== 'both') path += `&updateLockEnabled=${updateLock}`;
  if (subType) path += `&subType=${subType}`;
  if (includeDueAmount) path += `&includeDueAmount=${includeDueAmount}`;
  if (narration) path += `&narration=${narration}`;
  return companyAuthFetch.get(path).then(extractData);
};

export const getBranchDataSource = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/i-branches`).then(extractData);

export const fetchUnpaidVoucher = (accountId, companyId, page, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/vouchers/accounts/${accountId}/against-vouchers/page/${page}?${query}`
    )
    .then(extractData);

//view vouchers
export const createRecordPayment = (branchId, companyId, payload) =>
  companyAuthFetch.post(
    `/i-companies/${companyId}/i-branches/${branchId}/vouchers/receipt`,
    payload
  );

export const reconcileVoucher = (companyId, voucherId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/vouchers/${voucherId}/reconcile`, payload);

export const fetchPaymentMethods = () =>
  companyAuthFetch.get(`/configurations/payment-method-types`).then(extractData);

export const fetchBankList = () =>
  companyAuthFetch.get(`/configurations/banks-name`).then(extractData);

export const deleteDocument = (companyId, id) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/documents/${id}`).then(extractData);

export const fetchVoucherTypes = () => fetch.get(`/configurations/voucher-types`).then(extractData);

export const setReadLock = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/vouchers/read-lock `, payload);

export const setUpdateLock = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/vouchers/update-lock `, payload);

// Linked Schallan
export const createSalesLinkedSchallan = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'sales/challan-list', 'challan-list');

export const editSalesLinkedSchallan = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'challan-list');

export const editLinkedChallan = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'linked-challan');

//Linked Pchallan

export const createPurchaseLinkedPchallan = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'purchase/challan-list', 'challan-list');

export const editPurchaseLinkedPchallan = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'pchallan-list');

export const editLinkedPchallan = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'linked-pchallan');

//Sestimation
export const createSestimation = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_SESTIMATION);

export const editSestimation = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_SESTIMATION);

//linked sestimation
export const createSalesLinkedSestimationVoucher = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'sales/sestimation-list', 'sestimation-list');

export const editSalesLinkedSestimation = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'sestimation-list');

export const editLinkedSestimation = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'linked-sestimation');

//Pestimation
export const createPestimation = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PESTIMATION);

export const editPestimation = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, CONSTANTS.VOUCHER_TYPE_PESTIMATION);

//linked Pestimation
export const createPurchaseLinkedPestimationVoucher = (branchId, companyId, payload) =>
  createVoucher(branchId, companyId, payload, 'purchase/pestimation-list', 'pestimation-list');

export const editPurchaseLinkedPestimation = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'pestimation-list');

export const editLinkedPestimation = (voucherID, branchId, companyId, payload) =>
  editVoucher(voucherID, branchId, companyId, payload, 'linked-pestimation');

export const getVoucherDueAmouont = (companyId, voucherId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/vouchers/${voucherId}/due-amount`)
    .then(extractData);

export const searchNaration = (companyId, page, searchText) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/narration/${page}?${searchText}`);

export const updateUserVoucherTabsSettings = (companyId, userId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/i-users/${userId}/hide-voucher/user-company-settings`, payload)
    .then(extractData);

export const getVoucherTemplates = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/spcd-templates?${query}`);
export const createEwayBill = (companyId, voucherId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/vouchers/${voucherId}/e-way-bill`, payload)
    .then(extractData);

export const createEInvoice = (companyId, voucherId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/vouchers/${voucherId}/e-invoice`, payload)
    .then(extractData);

export const cancelEwayBill = (companyId, voucherId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/vouchers/${voucherId}/cancel-eway-bill`, payload)
    .then(extractData);

export const cancelEInvoice = (companyId, voucherId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/vouchers/${voucherId}/cancel-e-invoice`, payload)
    .then(extractData);
