import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
// import { routerReducer, routerMiddleware } from 'react-router-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import reducers, { blacklist } from './reducers';

import { routerMiddleware, connectRouter } from 'connected-react-router';
import { USER_LOGOUT } from './actions/types';

export const persistConfig = {
  key: 'invock',
  storage,
  blacklist
};
export const history = createBrowserHistory();
const combReducers = combineReducers({
  ...reducers,
  router: connectRouter(history)
});
const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return combReducers(undefined, action);
  }

  return combReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk, routerMiddleware(history)];

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);

// const buildStore = initialState => {
// return createStore(
//   persistedReducer,
//       initialState,
//       compose(
//         applyMiddleware(
//           ...middlewares
//         ),
//       ),
//     );
// };

// export const store = buildStore;
export const persistor = persistStore(store);
