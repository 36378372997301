import {
  BUSINESS_ACCOUNTS,
  COMPANY_DETAILS,
  VIEW_ROLE_CONFIG,
  TEAM_ROLES,
  EDIT_ROLE,
  COMPANY_ADD_ACCOUNT,
  SALES_DISCOUNT_MODAL,
  ADD_PARTY_MODAL,
  ADD_PARTY_TRANSPORTER_MODAL,
  EDIT_CREDIT_DAYS_MODAL,
  EDIT_NAME,
  EDIT_BALANCE,
  EDIT,
  ADD,
  CONTACTS,
  CONST_EDIT_OPEN_CONTACT,
  RECONCILED_VOUCHERS_VIEW_MODAL,
  RECONCILED_SELCTED_GROUP_MODAL,
  TRANSPORTER_LIST,
  PARTY_LIST,
  PARTY_SELECT_LIST,
  PARTY_LIST_DATA,
  PARTY_SELECT_DIALOG_TAGS,
  AJ_TALLY_PARTY_LIST,
  CATALOGUE_MODAL,
  SALES_PERSON_LIST,
  ADD_SALES_PERSON_MODAL,
  ITEM_CATALOGUE_MODAL,
  EXPORT_REPORT_MODAL,
  CATALOGUE_RENAME_MODAL,
  CREATE_I_SHOP_MODAL,
  CONTACT_BOOK_MODAL,
  CATALOGUE_SETTINGS_MODAL,
  GROUP_CODE_MAP_MODAL,
  CATALOGUE_ITEM_POSITION_MODAL,
  REMOVE_BUSINESS_ADMIN_MODAL,
  INDIA,
  OPEN_QUICK_PARTY_CREATION_MODAL,
  YEAR
} from '../constants';

import {
  UPDATE_CONTACT_NAME_SUCCESS,
  UPDATE_CONTACT_NAME_FAILED,
  ADD_CONTACT_NAME_FAILED,
  ADD_CONTACT_NAME_REQUEST,
  ADD_CONTACT_NAME_SUCCESS,
  ADD_CONTACT_DATA_SUCCESSS,
  CHECK_FOR_NAME_SUCCESS,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAILED,
  TOGGLE_OPEN_ADD_CONTACT,
  CONTACT_LIST_REQUESTED,
  STATEMENT_SUCCESS,
  CLOSE_BUSINESS_CARD,
  FETCH_PARTY_STATEMENT_SHARE_SUCCESS,
  SHARE_STATEMENT_SUCCESS,
  GET_PARTY_PROFILE_SUCCESS,
  STATEMENT_LOADING,
  STATEMENT_FAILED,
  HANDLE_TOGGLE_MODAL,
  UPDATE_PARTY_FAILED,
  FETCH_VOUCHERS_SUMMARY_SUCCESS,
  FETCH_UNRECONCILED_VOUCHERS_SUCCESS,
  FETCH_UNRECONCILED_VOUCHERS_INITIATED,
  FETCH_UNRECONCILED_VOUCHERS_FAILED,
  FETCH_INPROGRESS_VOUCHERS_SUCCESS,
  FETCH_INPROGRESS_VOUCHERS_INITIATED,
  FETCH_INPROGRESS_VOUCHERS_FAILED,
  FETCH_CONTACT_SUCCESS,
  RECONCILE_VOUCHERS_FAILED,
  COMPANY_ADD_BRANCH_FAILED,
  COMPANY_UPDATE_BRANCH_FAILED,
  COMPANY_PASSWORD_CHANGE_FAILED,
  COMPANY_UPDATE_DETAILS_FAILED,
  COMPANY_DELETE_BRANCH_FAILED,
  COMPANY_DELETE_BANK_ACCOUNT_FAILED,
  COMPANY_ADD_MEMBER_ROLE_FAILED,
  COMPANY_ADD_ACCOUNT_FAILED,
  FETCH_PARTY_RELATION_TYPES_INITIATED,
  FETCH_PARTY_RELATION_TYPES_SUCCESS,
  FETCH_PARTY_RELATION_TYPES_FAILED,
  FETCH_TRANSPORTER_PARTY_SUCCESS,
  FETCH_TRANSPORTER_PARTY_FAILED,
  FETCH_CONTACTS_SUCCESS,
  UPDATE_SALES_DISCOUNT_REQUEST,
  UPDATE_SALES_DISCOUNT_SUCCESS,
  UPDATE_SALES_DISCOUNT_ERROR,
  UPDATE_NAME_IN_DRAWER,
  FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS,
  FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED,
  FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED,
  UPDATE_SHIPPING_ADDRESS_REQUEST,
  UPDATE_SHIPPING_ADDRESS_SUCCESS,
  UPDATE_SHIPPING_ADDRESS_ERROR,
  UPDATE_BANK_DETAILS_REQUEST,
  UPDATE_BANK_DETAILS_SUCCESS,
  UPDATE_BANK_DETAILS_ERROR,
  FETCH_PARTY_SALES_DISCOUNT_SUCCESS,
  FETCH_PARTY_SALES_DISCOUNT_FAILED,
  SET_PARTY_SALES_DISCOUNT,
  RECONCILE_VOUCHERS_ERROR_RESET,
  HANDLE_TOGGLE_VOUCHER_VIEW_MODE,
  TOGGLE_PARTY_PROFILE_MODAL,
  ADD_PARTY_PICKER_FIELD_DATA,
  FETCH_ACCOUNT_ID_SUCCESS,
  FETCH_PARTY_ACCOUNT_ID_FAILED,
  UPDATE_PARTY_SUCCESS,
  PARTY_SEARCH_SUCCESS,
  PARTY_SEARCH_FAILED,
  PARTY_SEARCH_REQUESTED,
  FETCH_PARTY_CARD_SUCCESS,
  FETCH_PARTY_CARD_FAILED,
  FETCH_ACCOUNT_CARD_SUCCESS,
  FETCH_ACCOUNT_CARD_FAILED,
  STATEMENT_PAGINATE_SUCCESS,
  STATEMENT_PAGINATE_FAILED,
  STATEMENT_PAGINATE_LOADING,
  SET_STATEMENT_BRANCH_FILTER,
  SET_INTERVAL,
  RESET_PARTY_FILTERS,
  STATEMENT_DATE_SET,
  PARTY_LIST_ITEM_UPDATE,
  SET_PARTY_ACCOUNT_LIST_FILTER,
  RESET_PARTY_ACCOUNT_LIST_FILTER,
  LOAD_NEW_RECONCILE_AMOUNT,
  RECONCILATION_INITIATED,
  RECONCILE_VOUCHERS_SUCCESS,
  SET_FILTER_DATES,
  FETCH_PARTY_STATEMENT_SHARE_REQUEST,
  FETCH_PARTY_STATEMENT_SHARE_FAILURE,
  SET_RECONCILE_DATE,
  FETCH_PARTY_TAGS_REQUESTED,
  FETCH_PARTY_TAGS_SUCCESS,
  FETCH_PARTY_TAGS_FAILED,
  ADD_PARTY_TAG_REQUESTED,
  ADD_PARTY_TAG_SUCCESS,
  ADD_PARTY_TAG_IN_SELECTED,
  ADD_PARTY_TAG_FAILED,
  UPDATE_PARTY_TAG_REQUESTED,
  UPDATE_PARTY_TAG_SUCCESS,
  UPDATE_PARTY_TAG_IN_SELECTED,
  UPDATE_PARTY_TAG_FAILED,
  DELETE_PARTY_TAG_REQUESTED,
  DELETE_PARTY_TAG_SUCCESS,
  DELETE_PARTY_TAG_IN_SELECTED,
  DELETE_PARTY_TAG_FAILED,
  HANDLE_CHANGE_PARTY_TAGS,
  ADD_CONTACT_DATA_REQUEST,
  ADD_CONTACT_DATA_FAILED,
  ADD_TAGS_TO_PARTIES_REQUESTED,
  ADD_TAGS_TO_PARTIES_SUCCESS,
  ADD_TAGS_TO_PARTIES_FAILED,
  FETCH_AJ_TALLY_PARTIES_REQUEST,
  FETCH_AJ_TALLY_PARTIES_SUCCESS,
  FETCH_AJ_TALLY_PARTIES_ERROR,
  GET_PARTY_PROFILE_FAILED,
  GET_PARTY_PROFILE_REQUESTED,
  UPDATE_PARTY_REQUESTED,
  FETCH_PARTY_STATEMENT_PUBLIC_SHARE_REQUEST,
  FETCH_PARTY_STATEMENT_PUBLIC_SHARE_SUCCESS,
  FETCH_PARTY_STATEMENT_PUBLIC_SHARE_FAILURE,
  REMOVE_TAGS_FROM_PARTY_REQUESTED,
  REMOVE_TAGS_FROM_PARTY_SUCCESS,
  REMOVE_TAGS_FROM_PARTY_FAILED,
  FETCH_CONTACTS_SEARCH_SUCCESS,
  FETCH_CONTACTS_SEARCH_REQUESTED,
  FETCH_CONTACTS_SEARCH_FAILED,
  REQUEST_PRIMARY_CONTACT_REQUESTED,
  REQUEST_PRIMARY_CONTACT_SUCCESS,
  REQUEST_PRIMARY_CONTACT_FAILED,
  ADD_SECONDARY_CONTACT_REQUESTED,
  ADD_SECONDARY_CONTACT_SUCCESS,
  ADD_SECONDARY_CONTACT_FAILED,
  UPDATE_CONTACT_REQUESTED,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILED,
  GET_CURRENT_BALANCE_REQUESTED,
  GET_CURRENT_BALANCE_SUCCESS,
  GET_CURRENT_BALANCE_FAILED,
  SET_DUPLICATE_VOUCHER_TYPE,
  FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS,
  FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED,
  FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_FAILED,
  SET_STATEMENT_INCLUDE_TYPE_FILTER,
  HANDLE_PARTY_CREATION_MODE,
  SET_INTEREST_CALCULATION_DATE,
  SET_INTEREST_CALCULATION_DATE_INTERVAL_TYPE,
  FETCH_INTEREST_CALCULATION_REQUESTED,
  FETCH_INTEREST_CALCULATION_SUCCESS,
  FETCH_INTEREST_CALCULATION_FAILED,
  RESET_INTEREST_CALCULATION_FILTERS,
  SET_INTEREST_CALCULATION_BRANCH,
  FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS,
  FETCH_PARTY_PURCHASE_DISCOUNT_FAILED,
  SET_PARTY_PURCHASE_DISCOUNT
} from '../actions/types';

import {
  COMPANY_BANK_ACCOUNT,
  BRANCH_ADDRESS,
  CHANGE_COMPANY_PASSWORD,
  INTERNAL_NOTES_DRAWER
} from '../constants';
import {
  getStartOfFinancialYear,
  getEndOfFinancialYear,
  getFormattedDate3,
  addressExists,
  getFormattedDateIso
} from '../utils/helperFunctions';
import { getselectedBusinessAccount } from '.';
import map from 'lodash/map';
import find from 'lodash/find';
import { filter } from 'lodash';
const vendorAccountsDataList = (state = [], action) => {
  if (action.type === BUSINESS_ACCOUNTS) {
    return [...action.response];
  }
  return state;
};
const finStartDate = sessionStorage.getItem('startDate');
const finEndDate = sessionStorage.getItem('endDate');
const statementState = {
  loading: false,
  reconciledAmount: 0,
  closingBalanceSummary: {
    balance: 0,
    isBalanceDebit: false
  },
  reconciledBalanceSummary: {
    balance: 0,
    isBalanceDebit: false
  },
  openingBalanceSummary: {
    balance: 0,
    isBalanceDebit: false
  },
  statement: []
};

const initialState = {
  [PARTY_LIST_DATA]: {
    //for parties page
    partyList: [],
    loadingList: false
  },
  [PARTY_LIST]: {
    //for party picker dropdown
    partyList: [],
    loadingList: false
  },
  [TRANSPORTER_LIST]: {
    //for transporter picker dropdown
    partyList: [],
    loadingList: false
  },
  [SALES_PERSON_LIST]: {
    //for sales person picker dropdown
    partyList: [],
    loadingList: false
  },
  [PARTY_SELECT_LIST]: {
    //for party select dialog
    partyList: [],
    loadingList: false
  },
  [AJ_TALLY_PARTY_LIST]: {
    //for party picker dropdown
    partyList: [],
    loadingList: false
  },
  fetchStatus: null,
  createStatus: null,
  filterBranches: [],
  addContactResponse: {},
  primaryContact: {},
  selectedIntervalFilter: 'year',
  selectedFilterDates: {
    startDate: finStartDate
      ? JSON.parse(finStartDate)
      : getFormattedDateIso(getStartOfFinancialYear(new Date())),
    endDate: finEndDate
      ? JSON.parse(finEndDate)
      : getFormattedDateIso(getEndOfFinancialYear(new Date()))
  },
  selectedInterestCalculationFilterDates: {
    startDate: finStartDate
      ? JSON.parse(finStartDate)
      : getFormattedDateIso(getStartOfFinancialYear(new Date())),
    endDate: finEndDate
      ? JSON.parse(finEndDate)
      : getFormattedDateIso(getEndOfFinancialYear(new Date())),
    selectedInterval: YEAR,
    selectedBranch: []
  },
  partyProfileRequested: {
    partyProfile: false
  },
  updatePartyRequested: {
    partyProfile: false
  },
  partyCard: {
    name: '',
    aliasName: '',
    city: '',
    state: '',
    primaryContact: {
      name: '',
      phone: 0,
      id: ''
    },
    id: ''
  },
  partyStatement: {
    closingBalanceSummary: {
      balance: 0,
      isBalanceDebit: false
    },
    openingBalanceSummary: {
      balance: 0,
      isBalanceDebit: false
    },
    openingBalance: 0,
    closingBalance: 0,
    totalDataCount: 0,
    statement: [
      {
        _id: '',
        type: '',
        voucherNo: '',
        billFinalAmount: 0,
        createdAt: '2018-07-09',
        debit: 0,
        credit: 0,
        balance: 0
      }
    ]
  },
  refAccountId: '',
  partyProfile: {},
  partyStatementLoading: false,
  app: {
    onHideAddContact: false
  },
  error: '',
  unreconciledVouchers: {
    ...statementState
  },
  unreconciledVoucherStatement: {
    ...statementState
  },
  inProgressVouchers: {
    ...statementState
  },
  reconciledVouchersGroupByDate: {
    loading: false,
    data: [],
    totalDataCount: 0,
    loadingNextPage: false,
    page: 1
  },
  reconcileAmountLoader: false,
  reconcileInProgress: false,
  transporterPartyList: [],
  contacts: [],
  contactList: {
    pageNo: 1,
    data: []
  },
  updateContactRequested: false,
  updateSalesDiscountRequest: false,
  updateShippingtRequest: false,
  updateBankDetailsRequest: false,
  newPartyDrawerName: '',
  newPartyModalKey: '',
  salesDiscounts: [],
  purchaseDiscounts: [],
  voucherViewMode: {
    flag: false,
    voucher: '',
    type: '',
    id: '',
    modelKeyObj: {
      key: 'VOUCHER'
    },
    duplicateVoucherType: ''
  },
  openPartyProfileModal: false,
  partyProfileTabIndex: CONTACTS,
  partyPickerField: {},
  partyShareList: {
    contacts: [],
    shareContacts: []
  },
  partyPublicShareStatus: {
    loading: false,
    contacts: [],
    shareContacts: []
  },
  partyPublicBusinessCardShareStatus: {
    loading: false,
    contacts: [],
    shareContacts: []
  },
  shareLoading: false,
  reconcileDate: getFormattedDate3(),
  addPartyRequested: false,
  ajTallyParties: {},
  ajTallyPartiesRequested: false,
  replacePrimaryRequested: false,
  addSecondaryContactRequested: false,
  currentBalance: {
    value: 0,
    loading: false,
    accessGranted: true
  },
  includeType: { estimation: true, challan: true },
  quickPartyCreation: false,
  interestCalculationLoading: false
};
const partyTagsInitial = {
  tagList: [],
  [PARTY_SELECT_DIALOG_TAGS]: [],
  [PARTY_SELECT_LIST]: [],
  tagListLoading: false,
  addPartyTagRequested: false,
  updatePartyTagRequested: false,
  openTagValueForm: false,
  addTagsToPartiesRequested: false,
  removeTagsFromPartyRequested: false
};
const partyAccountListFiltersInitial = {
  [PARTY_LIST_DATA]: {
    searchText: '',
    selectedFilterType: '',
    selectedSearchBy: 'name',
    includePartyTags: false
  },
  [PARTY_SELECT_LIST]: {
    searchText: '',
    selectedFilterType: '',
    selectedSearchBy: 'name',
    includePartyTags: false
  },
  [PARTY_LIST]: {
    searchText: '',
    selectedFilterType: '',
    selectedSearchBy: 'name',
    includePartyTags: false
  },
  [TRANSPORTER_LIST]: {
    searchText: '',
    selectedFilterType: '',
    selectedSearchBy: 'name',
    includePartyTags: false
  },
  [SALES_PERSON_LIST]: {
    searchText: '',
    selectedFilterType: '',
    selectedSearchBy: 'name',
    includePartyTags: false
  },
  [AJ_TALLY_PARTY_LIST]: {
    searchText: '',
    selectedFilterType: '',
    selectedSearchBy: 'name',
    includePartyTags: false
  }
};

const internalNotesInitialState = {
  vouchers: { data: [] },
  party: { data: [] },
  contacts: { data: [] }
};

const modalsInitialState = {
  openEditAddr: false,
  openDeleteAddr: false,
  openEditBank: false,
  openDeleteBank: false,
  openEditBilling: false,
  openContactModal: false,
  openDeleteContact: false,
  openMarkPrimary: false,
  openReconcileModal: false,
  [INTERNAL_NOTES_DRAWER]: false,
  [BRANCH_ADDRESS]: false,
  [COMPANY_BANK_ACCOUNT]: false,
  [CHANGE_COMPANY_PASSWORD]: false,
  [COMPANY_DETAILS]: false,
  [VIEW_ROLE_CONFIG]: false,
  [TEAM_ROLES]: false,
  [EDIT_ROLE]: false,
  [COMPANY_ADD_ACCOUNT]: false,
  [EDIT_CREDIT_DAYS_MODAL]: false,
  [SALES_DISCOUNT_MODAL + EDIT]: false,
  [SALES_DISCOUNT_MODAL + ADD]: false,
  [ADD_PARTY_MODAL]: false,
  [ADD_PARTY_TRANSPORTER_MODAL]: false,
  [ADD_SALES_PERSON_MODAL]: false,
  [EDIT_NAME]: false,
  [CONST_EDIT_OPEN_CONTACT]: false,
  [EDIT_BALANCE]: false,
  [RECONCILED_VOUCHERS_VIEW_MODAL]: false,
  [RECONCILED_SELCTED_GROUP_MODAL]: false,
  [CATALOGUE_MODAL]: false,
  [ITEM_CATALOGUE_MODAL]: false,
  [CREATE_I_SHOP_MODAL]: false,
  [EXPORT_REPORT_MODAL]: false,
  [CATALOGUE_RENAME_MODAL]: false,
  [CATALOGUE_ITEM_POSITION_MODAL]: false,
  [CONTACT_BOOK_MODAL]: false,
  [CATALOGUE_SETTINGS_MODAL]: false,
  [REMOVE_BUSINESS_ADMIN_MODAL]: false,
  [GROUP_CODE_MAP_MODAL]: false,
  [OPEN_QUICK_PARTY_CREATION_MODAL]: false
};

const errorMessageInitialState = {
  submitErrorText: '',
  reconcileErrorText: ''
};

const partyRelationTypesInitialState = {
  loading: false,
  list: []
};

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECONCILE_DATE:
      return {
        ...state,
        reconcileDate: action.payload
      };
    case LOAD_NEW_RECONCILE_AMOUNT:
      return {
        ...state,
        reconcileAmountLoader: action.payload
      };
    case RESET_PARTY_FILTERS:
      return {
        ...state,
        filterBranches: [],
        selectedIntervalFilter: 'year',
        selectedFilterDates: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate
        }
      };
    case TOGGLE_PARTY_PROFILE_MODAL:
      return {
        ...state,
        openPartyProfileModal: action.payload.value,
        partyProfileTabIndex: action.payload.tabIndex
      };
    case ADD_CONTACT_DATA_SUCCESSS:
      return {
        ...state,
        addPartyRequested: false,
        addContactResponse: action.response
      };

    case ADD_CONTACT_DATA_REQUEST:
      return {
        ...state,
        addPartyRequested: true
      };
    case ADD_CONTACT_DATA_FAILED:
      return {
        ...state,
        addPartyRequested: false
      };
    case RECONCILATION_INITIATED:
      return {
        ...state,
        reconcileInProgress: true
      };
    case RECONCILE_VOUCHERS_SUCCESS:
      return {
        ...state,
        reconcileInProgress: false
      };
    case RECONCILE_VOUCHERS_FAILED:
      return {
        ...state,
        reconcileInProgress: false
      };
    case CLOSE_BUSINESS_CARD:
      return {
        ...state,
        addContactResponse: {}
      };
    case TOGGLE_OPEN_ADD_CONTACT:
      return {
        ...state,
        app: {
          ...state.app,
          onHideAddContact: !state.app.onHideAddContact
        }
      };
    case CONTACT_LIST_REQUESTED:
      return {
        ...state,
        [PARTY_LIST_DATA]: {
          ...state[PARTY_LIST_DATA],
          loadingList: true
        }
      };
    case CONTACT_LIST_SUCCESS:
      return {
        ...state,
        fetchStatus: 'success',
        [PARTY_LIST_DATA]: {
          ...state[PARTY_LIST_DATA],
          partyList: action.payload,
          loadingList: false
        }
      };

    case CONTACT_LIST_FAILED:
      return {
        ...state,
        error: action.payload,
        fetchStatus: 'failure',
        [PARTY_LIST_DATA]: {
          ...state[PARTY_LIST_DATA],
          loadingList: false
        }
      };
    case PARTY_SEARCH_REQUESTED:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          loadingNextPage: action.page > 1,
          loadingList: action.page === 1
        }
      };
    case FETCH_AJ_TALLY_PARTIES_REQUEST:
      return {
        ...state,
        ajTallyPartiesRequested: true
      };
    case FETCH_AJ_TALLY_PARTIES_SUCCESS:
      return {
        ...state,
        ajTallyPartiesRequested: false,
        ajTallyParties: {
          // ...state.ajTallyParties,
          ...action.payload
        }
      };
    case FETCH_AJ_TALLY_PARTIES_ERROR:
      return {
        ...state,
        ajTallyPartiesRequested: false
      };
    case PARTY_SEARCH_SUCCESS:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          partyList:
            action.payload.page === 1 || !action.infiniteScroll
              ? action.payload.data
              : action.partyId
              ? map(state[action.storeKey].partyList, party => {
                  if (party.id === action.partyId) {
                    let part = find(
                      action.payload.data,
                      newParty => newParty.id === action.partyId
                    );
                    return part;
                  } else {
                    return party;
                  }
                })
              : [...state[action.storeKey].partyList, ...action.payload.data],
          totalPages: action.payload.totalPages,
          totalDataCount: action.payload.totalDataCount,
          loadingList: false,
          loadingNextPage: false,
          page: action.payload.page
        }
      };
    case FETCH_CONTACTS_SEARCH_SUCCESS:
      return {
        ...state,
        contactList: {
          ...state.contactList,
          data:
            action.payload.page === 1 || !action.infiniteScroll
              ? action.payload.data
              : [...state.contactList.data, ...action.payload.data],
          totalPages: action.payload.totalPages,
          totalDataCount: action.payload.totalDataCount,
          loadingList: false,
          loadingNextPage: false,
          pageNo: action.payload.page
        }
      };
    case FETCH_CONTACTS_SEARCH_REQUESTED:
      return {
        ...state,
        contactList: {
          ...state.contactList,
          loadingList: true,
          loadingNextPage: true
        }
      };
    case FETCH_CONTACTS_SEARCH_FAILED:
      return {
        ...state,
        contactList: {
          ...state.contactList,
          loadingList: false,
          loadingNextPage: false
        }
      };
    case REQUEST_PRIMARY_CONTACT_REQUESTED:
      return {
        ...state,
        replacePrimaryRequested: true
      };
    case REQUEST_PRIMARY_CONTACT_SUCCESS:
      return {
        ...state,
        replacePrimaryRequested: false
      };
    case REQUEST_PRIMARY_CONTACT_FAILED:
      return {
        ...state,
        replacePrimaryRequested: false
      };
    case ADD_SECONDARY_CONTACT_REQUESTED:
      return {
        ...state,
        addSecondaryContactRequested: true
      };
    case ADD_SECONDARY_CONTACT_SUCCESS:
      return {
        ...state,
        addSecondaryContactRequested: false
      };
    case ADD_SECONDARY_CONTACT_FAILED:
      return {
        ...state,
        addSecondaryContactRequested: false
      };
    case PARTY_LIST_ITEM_UPDATE:
      return {
        ...state,
        [PARTY_LIST_DATA]: {
          ...state[PARTY_LIST_DATA],
          partyList: map(state[PARTY_LIST_DATA].partyList, party => {
            if (party.id === action.partyId) {
              return { ...party, ...action.response };
            } else {
              return party;
            }
          })
        }
      };
    case PARTY_SEARCH_FAILED:
      return {
        ...state,
        error: action.payload,
        [action.storeKey]: {
          ...state[action.storeKey],
          loadingNextPage: false,
          loadingList: false
        }
      };
    case ADD_CONTACT_NAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_CONTACT_NAME_SUCCESS:
      return Object.assign({}, ...state, {
        error: '',
        loading: false,
        createStatus: 'success',
        payload: action.payload
      });
    case ADD_CONTACT_NAME_FAILED:
      return Object.assign({}, ...state, {
        payload: {},
        loading: false,
        createStatus: 'failure',
        error: action.error
      });
    case STATEMENT_SUCCESS:
      return {
        ...state,
        [action.key]: action.payload,
        partyStatementLoading: false
      };
    case STATEMENT_FAILED:
      return {
        ...state,
        partyStatementLoading: false
      };
    case STATEMENT_LOADING:
      return {
        ...state,
        partyStatementLoading: true
      };

    case SET_FILTER_DATES:
      return {
        ...state,
        selectedFilterDates: {
          startDate: getFormattedDateIso(action.payload.startDate),
          endDate: getFormattedDateIso(action.payload.endDate)
        }
      };

    case STATEMENT_PAGINATE_LOADING:
      return {
        ...state,
        partyStatementLoading: true,
        [action.key]: {
          ...state[action.key],
          loading: true
        }
        // [action.key]: {
        //   ...initialState[action.key],
        //   startDate: state[action.key].startDate,
        //   endDate: state[action.key].endDate,
        // }
      };
    case STATEMENT_PAGINATE_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.payload.data,
          page: action.payload.page,
          totalDataCount: action.payload.totalDataCount,
          loading: false
        },
        partyStatementLoading: false
      };
    case STATEMENT_DATE_SET:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          startDate: action.start,
          endDate: action.end
        }
      };
    case STATEMENT_PAGINATE_FAILED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.payload.data,
          page: action.payload.page,
          totalDataCount: action.payload.totalDataCount
        },
        partyStatementLoading: false
      };
    case FETCH_PARTY_STATEMENT_SHARE_REQUEST:
      return {
        ...state,
        shareLoading: true
      };

    case FETCH_PARTY_STATEMENT_SHARE_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          shareContacts: action.payload.data
        },
        shareLoading: false
      };
    case FETCH_PARTY_STATEMENT_SHARE_FAILURE:
      return {
        ...state,
        shareLoading: false
      };

    case FETCH_PARTY_STATEMENT_PUBLIC_SHARE_REQUEST:
    case FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_REQUESTED:
      return {
        ...state,
        [action.key]: {
          ...initialState[action.key],
          loading: true
        }
      };

    case FETCH_PARTY_STATEMENT_PUBLIC_SHARE_SUCCESS:
    case FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          ...action.payload
        }
      };
    case FETCH_PARTY_STATEMENT_PUBLIC_SHARE_FAILURE:
    case FETCH_PARTY_BUSINESS_CARD_PUBLIC_SHARE_FAILED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false
        }
      };

    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        partyShareList: {
          ...state.partyShareList,
          contacts: action.payload
        }
      };
    case SHARE_STATEMENT_SUCCESS:
      return {
        ...state,
        [action.key]: action.payload.data
      };
    case GET_PARTY_PROFILE_SUCCESS:
      return {
        ...state,
        partyProfileRequested: {
          ...state.partyProfileRequested,
          [action.key]: false
        },
        [action.key]: action.payload
      };
    case GET_PARTY_PROFILE_FAILED:
      return {
        ...state,
        partyProfileRequested: {
          ...state.partyProfileRequested,
          [action.key]: false
        },
        [action.key]: {}
      };
    case GET_PARTY_PROFILE_REQUESTED:
      return {
        ...state,
        partyProfileRequested: {
          ...state.partyProfileRequested,
          [action.key]: true
        }
      };
    case GET_CURRENT_BALANCE_REQUESTED:
      return {
        ...state,
        currentBalance: {
          ...state.currentBalance,
          loading: true,
          accessGranted: true
        }
      };
    case GET_CURRENT_BALANCE_SUCCESS:
      return {
        ...state,
        currentBalance: {
          ...state.currentBalance,
          value: action.response,
          loading: false,
          accessGranted: true
        }
      };
    case GET_CURRENT_BALANCE_FAILED:
      return {
        ...state,
        currentBalance: {
          ...state.currentBalance,
          value: 0,
          loading: false,
          accessGranted: false
        }
      };
    case UPDATE_PARTY_REQUESTED:
      return {
        ...state,
        updatePartyRequested: {
          ...state.updatePartyRequested,
          [action.key]: true
        }
      };
    case UPDATE_PARTY_SUCCESS:
      return {
        ...state,
        updatePartyRequested: {
          ...state.updatePartyRequested,
          [action.key]: false
        }
      };
    case UPDATE_PARTY_FAILED:
      return {
        ...state,
        updatePartyRequested: {
          ...state.updatePartyRequested,
          [action.key]: false
        }
      };
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        primaryContact: action.payload.data
      };
    case FETCH_PARTY_CARD_SUCCESS:
      return {
        ...state,
        partyCard: action.payload
      };
    case FETCH_PARTY_CARD_FAILED:
      return {
        ...state,
        partyCard: initialState.partyCard
      };
    case FETCH_ACCOUNT_CARD_SUCCESS:
      return {
        ...state,
        partyCard: action.payload
      };
    case FETCH_ACCOUNT_CARD_FAILED:
      return {
        ...state,
        partyCard: initialState.partyCard
      };
    case FETCH_VOUCHERS_SUMMARY_SUCCESS:
      return {
        ...state,
        [action.key]: action.payload
      };
    case FETCH_INPROGRESS_VOUCHERS_SUCCESS:
      return {
        ...state,
        inProgressVouchers: {
          ...action.payload,
          loading: false
        }
      };
    case FETCH_INPROGRESS_VOUCHERS_INITIATED:
      return {
        ...state,
        inProgressVouchers: {
          ...initialState.inProgressVouchers,
          loading: true
        }
      };
    case FETCH_INPROGRESS_VOUCHERS_FAILED:
      return {
        ...state,
        inProgressVouchers: {
          ...initialState.inProgressVouchers,
          loading: false
        }
      };
    case FETCH_UNRECONCILED_VOUCHERS_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...action.payload,
          loading: false
        }
      };
    case FETCH_UNRECONCILED_VOUCHERS_INITIATED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: true
        }
      };
    case FETCH_UNRECONCILED_VOUCHERS_FAILED:
      return {
        ...state,
        [action.key]: {
          ...action.payload,
          loading: false
        }
      };
    case FETCH_TRANSPORTER_PARTY_SUCCESS:
      return {
        ...state,
        transporterPartyList: action.payload
      };
    case FETCH_TRANSPORTER_PARTY_FAILED:
      return {
        ...state,
        transporterPartyList: []
      };
    // case FETCH_CONTACTS_SUCCESS:
    //   return {
    //     ...state,
    //     contacts: action.payload
    //   };
    // case FETCH_CONTACTS_FAILED:
    //   return {
    //     ...state,
    //     contacts: {}
    //   };
    case UPDATE_SALES_DISCOUNT_REQUEST:
      return {
        ...state,
        updateSalesDiscountRequest: true
      };
    case UPDATE_SALES_DISCOUNT_SUCCESS:
      return {
        ...state,
        updateSalesDiscountRequest: false
      };
    case UPDATE_SALES_DISCOUNT_ERROR:
      return {
        ...state,
        updateSalesDiscountRequest: false
      };
    case UPDATE_NAME_IN_DRAWER:
      return {
        ...state,
        newPartyDrawerName: action.name,
        newPartyModalKey: action.modalKey
      };
    case FETCH_RECONCILED_VOUCHERS_GROUP_DATE_SUCCESS:
      return {
        ...state,
        reconciledVouchersGroupByDate: {
          loading: false,
          data: action.payload.data,
          totalDataCount: action.payload.totalDataCount,
          loadingNextPage: false,
          page: action.payload.page
        }
      };
    case FETCH_RECONCILED_VOUCHERS_GROUP_DATE_INITIATED:
      return {
        ...state,
        reconciledVouchersGroupByDate: {
          loading: true,
          data: []
        }
      };
    case FETCH_RECONCILED_VOUCHERS_GROUP_DATE_FAILED:
      return {
        ...state,
        reconciledVouchersGroupByDate: {
          loading: false,
          data: []
        }
      };
    case UPDATE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        updateShippingtRequest: true
      };
    case UPDATE_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        updateShippingtRequest: false
      };
    case UPDATE_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        updateShippingtRequest: false
      };
    case UPDATE_BANK_DETAILS_REQUEST:
      return {
        ...state,
        updateBankDetailsRequest: true
      };
    case UPDATE_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        updateBankDetailsRequest: false
      };
    case UPDATE_BANK_DETAILS_ERROR:
      return {
        ...state,
        updateBankDetailsRequest: false
      };
    case FETCH_PARTY_SALES_DISCOUNT_SUCCESS:
      return {
        ...state,
        salesDiscounts: action.payload
      };
    case FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS:
      return {
        ...state,
        purchaseDiscounts: action.payload
      };
    case FETCH_PARTY_SALES_DISCOUNT_FAILED:
      return {
        ...state,
        salesDiscounts: []
      };
    case FETCH_PARTY_PURCHASE_DISCOUNT_FAILED:
      return {
        ...state,
        purchaseDiscounts: []
      };
    case SET_PARTY_SALES_DISCOUNT:
      return {
        ...state,
        salesDiscounts: action.payload
      };
    case SET_PARTY_PURCHASE_DISCOUNT:
      return {
        ...state,
        purchaseDiscounts: action.payload
      };
    case HANDLE_TOGGLE_VOUCHER_VIEW_MODE:
      return {
        ...state,
        voucherViewMode: {
          ...state.voucherViewMode,
          flag: action.flag,
          voucher: action.voucher,
          type: action.voucherType,
          id: action.id,
          modelKeyObj: action.modelKeyObj,
          duplicateVoucherType: action.duplicateVoucherType
        }
      };
    case SET_DUPLICATE_VOUCHER_TYPE:
      return {
        ...state,
        voucherViewMode: {
          ...state.voucherViewMode,
          duplicateVoucherType: action.duplicateVoucherType
        }
      };
    case ADD_PARTY_PICKER_FIELD_DATA:
      return {
        ...state,
        partyPickerField: action.data
      };
    case FETCH_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        refAccountId: action.refAccountId
      };
    case FETCH_PARTY_ACCOUNT_ID_FAILED:
      return {
        ...state,
        refAccountId: ''
      };
    case SET_STATEMENT_BRANCH_FILTER:
      return {
        ...state,
        filterBranches: action.branches
      };
    case SET_STATEMENT_INCLUDE_TYPE_FILTER:
      return {
        ...state,
        includeType: {
          ...state.includeType,
          [action.key]: action.value
        }
      };
    case SET_INTERVAL:
      return {
        ...state,
        selectedIntervalFilter: action.payload
      };
    case ADD_TAGS_TO_PARTIES_SUCCESS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          tags: action.tags
        }
      };
    case UPDATE_CONTACT_REQUESTED:
      return {
        ...state,
        updateContactRequested: true
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        updateContactRequested: false
      };
    case UPDATE_CONTACT_FAILED:
      return {
        ...state,
        updateContactRequested: false
      };
    case HANDLE_PARTY_CREATION_MODE:
      return {
        ...state,
        quickPartyCreation: action.flag
      };
    case FETCH_INTEREST_CALCULATION_REQUESTED:
      return {
        ...state,
        interestCalculationLoading: true
      };
    case FETCH_INTEREST_CALCULATION_SUCCESS:
    case FETCH_INTEREST_CALCULATION_FAILED:
      return {
        ...state,
        interestCalculationLoading: false
      };
    case SET_INTEREST_CALCULATION_DATE:
      return {
        ...state,
        selectedInterestCalculationFilterDates: {
          ...state.selectedInterestCalculationFilterDates,
          startDate: action.startDate,
          endDate: action.endDate
        }
      };
    case SET_INTEREST_CALCULATION_DATE_INTERVAL_TYPE:
      return {
        ...state,
        selectedInterestCalculationFilterDates: {
          ...state.selectedInterestCalculationFilterDates,
          selectedInterval: action.selectedInterval
        }
      };
    case SET_INTEREST_CALCULATION_BRANCH:
      return {
        ...state,
        selectedInterestCalculationFilterDates: {
          ...state.selectedInterestCalculationFilterDates,
          selectedBranch: action.branch || []
        }
      };
    case RESET_INTEREST_CALCULATION_FILTERS:
      return {
        ...state,
        selectedInterestCalculationFilterDates: {
          startDate: finStartDate
            ? JSON.parse(finStartDate)
            : getFormattedDateIso(getStartOfFinancialYear(new Date())),
          endDate: finEndDate
            ? JSON.parse(finEndDate)
            : getFormattedDateIso(getEndOfFinancialYear(new Date())),
          selectedInterval: YEAR,
          selectedBranch: []
        }
      };
    default:
      return state;
  }
};

const checkForName = (state = {}, action) => {
  switch (action.type) {
    case CHECK_FOR_NAME_SUCCESS:
      return Object.assign({}, ...state, action.payload);
    default:
      return state;
  }
};

const partyRelationTypes = (state = partyRelationTypesInitialState, action) => {
  switch (action.type) {
    case FETCH_PARTY_RELATION_TYPES_INITIATED:
      return {
        ...state,
        loading: true,
        list: []
      };
    case FETCH_PARTY_RELATION_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_PARTY_RELATION_TYPES_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

const updateContactName = (state = null, action) => {
  switch (action.type) {
    case UPDATE_CONTACT_NAME_SUCCESS:
      return action.status;
    case UPDATE_CONTACT_NAME_FAILED:
      return action.status;
    default:
      return state;
  }
};

const modalFlags = (state = modalsInitialState, action) => {
  switch (action.type) {
    case HANDLE_TOGGLE_MODAL:
      return {
        ...state,
        [action.key]: action.payload
      };
    default:
      return state;
  }
};

const internalNotes = (state = internalNotesInitialState, action) => {
  switch (action.type) {
    case 'SET_INTERNAL_NOTES':
      return {
        ...state,
        [action.key]: action.payload
      };
    default:
      return state;
  }
};

const submitResponse = (state = errorMessageInitialState, action) => {
  switch (action.type) {
    case UPDATE_PARTY_FAILED:
      return {
        ...state,
        submitErrorText: action.payload
      };
    case UPDATE_PARTY_SUCCESS:
      return {
        ...state,
        submitErrorText: ''
      };
    case COMPANY_ADD_BRANCH_FAILED:
      return {
        ...state,
        submitErrorText: action.payload
      };
    case COMPANY_UPDATE_BRANCH_FAILED:
      return {
        ...state,
        submitErrorText: action.payload
      };
    case RECONCILE_VOUCHERS_FAILED:
      return {
        ...state,
        reconcileErrorText: action.payload
      };
    case RECONCILE_VOUCHERS_ERROR_RESET:
      return {
        ...state,
        reconcileErrorText: action.payload
      };
    case COMPANY_PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        passwordChangeError: action.payload
      };
    case COMPANY_UPDATE_DETAILS_FAILED:
      return {
        ...state,
        companyDetailsUpdateError: action.payload
      };
    case COMPANY_DELETE_BRANCH_FAILED:
      return {
        ...state,
        branchDeleteError: action.payload
      };
    case COMPANY_DELETE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        bankAccDeleteError: action.payload
      };
    case COMPANY_ADD_MEMBER_ROLE_FAILED:
      return {
        ...state,
        assignRoleError: action.payload
      };
    case COMPANY_ADD_ACCOUNT_FAILED:
      return {
        ...state,
        addCompanyAccountError: action.payload
      };
    default:
      return state;
  }
};

const partyAccountListFilters = (state = partyAccountListFiltersInitial, action) => {
  switch (action.type) {
    case SET_PARTY_ACCOUNT_LIST_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          [action.key]: action.value
        }
      };
    case RESET_PARTY_ACCOUNT_LIST_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...partyAccountListFiltersInitial[action.storeKey]
        }
      };
    default:
      return state;
  }
};

const partyTags = (state = partyTagsInitial, action) => {
  switch (action.type) {
    case ADD_PARTY_TAG_FAILED:
      return {
        ...state,
        addPartyTagRequested: false
      };
    case ADD_PARTY_TAG_REQUESTED:
      return {
        ...state,
        addPartyTagRequested: true
      };
    case ADD_PARTY_TAG_SUCCESS:
      return {
        ...state,
        addPartyTagRequested: false,
        // tagList: map(state.tagList, (party, index) => index === state.tagList.length ? action.payload : party )
        tagList: [...state.tagList, { ...action.payload }]
      };
    case UPDATE_PARTY_TAG_FAILED:
      return {
        ...state,
        updatePartyTagRequested: false
      };
    case UPDATE_PARTY_TAG_REQUESTED:
      return {
        ...state,
        updatePartyTagRequested: true
      };
    case UPDATE_PARTY_TAG_SUCCESS:
      return {
        ...state,
        updatePartyTagRequested: false,
        tagList: map(state.tagList, party =>
          party.id === action.payload.id ? action.payload : party
        )
      };

    case DELETE_PARTY_TAG_FAILED:
      return {
        ...state,
        updatePartyTagRequested: false
      };
    case DELETE_PARTY_TAG_REQUESTED:
      return {
        ...state,
        updatePartyTagRequested: true
      };
    case DELETE_PARTY_TAG_SUCCESS:
      return {
        ...state,
        updatePartyTagRequested: false,
        tagList: filter(state.tagList, party => party.id !== action.tagId)
      };
    case REMOVE_TAGS_FROM_PARTY_REQUESTED:
      return {
        ...state,
        removeTagsFromPartyRequested: true
      };
    case REMOVE_TAGS_FROM_PARTY_SUCCESS:
      return {
        ...state,
        removeTagsFromPartyRequested: false
      };
    case REMOVE_TAGS_FROM_PARTY_FAILED:
      return {
        ...state,
        removeTagsFromPartyRequested: false
      };
    case ADD_TAGS_TO_PARTIES_REQUESTED:
      return {
        ...state,
        addTagsToPartiesRequested: true
      };
    case ADD_TAGS_TO_PARTIES_SUCCESS:
      return {
        ...state,
        addTagsToPartiesRequested: false
      };
    case ADD_TAGS_TO_PARTIES_FAILED:
      return {
        ...state,
        addTagsToPartiesRequested: false
      };
    case FETCH_PARTY_TAGS_REQUESTED:
      return {
        ...state,
        tagListLoading: true
      };
    case FETCH_PARTY_TAGS_SUCCESS:
      return {
        ...state,
        tagListLoading: false,
        tagList: action.payload
      };
    case FETCH_PARTY_TAGS_FAILED:
      return {
        ...state,
        tagListLoading: false,
        tagList: action.payload
      };
    case HANDLE_CHANGE_PARTY_TAGS:
      return {
        ...state,
        [action.key]: action.tags
      };
    case ADD_PARTY_TAG_IN_SELECTED:
      return {
        ...state,
        [action.key]: map(state[action.key], (item, index) =>
          index === state[action.key].length - 1 && item.name === action.payload.name
            ? action.payload
            : item
        )
      };
    case UPDATE_PARTY_TAG_IN_SELECTED:
      return {
        ...state,
        [action.key]: map(state[action.key], item =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    case DELETE_PARTY_TAG_IN_SELECTED:
      return {
        ...state,
        [action.key]: filter(state[action.key], item => item.id !== action.tagId)
      };
    default:
      return state;
  }
};

//selectors
const getAddContactToggleState = ({
  contacts: {
    app: { onHideAddContact }
  }
}) => onHideAddContact;
const getNewContactAdded = ({ contacts: { addContactResponse } }) => addContactResponse;
const getVoucherViewMode = ({ contacts: { voucherViewMode } }) => voucherViewMode;
const getPartyAccountCurrentBalance = ({ contacts: { currentBalance } }) => currentBalance;
const getShippingAddressList = ({
  contacts: {
    partyProfile: { shipping = [] }
  },
  vouchers
}) => {
  const selectedParty = getselectedBusinessAccount(vouchers);
  if (Object.keys(selectedParty).length <= 0) {
    return [];
  } else {
    const billingAddress = {
      name: addressExists(selectedParty) ? 'Billing Address' : 'Billing address not added',
      city: selectedParty.city,
      state: selectedParty.state,
      address: selectedParty.address,
      pincode: selectedParty.pincode,
      country: selectedParty.country || INDIA,
      id: selectedParty.id
    };
    return [{ ...billingAddress }, ...shipping];
  }
};

const getTransporterList = ({
  contacts: {
    partyProfile: { transporterIds = [] }
  }
}) => transporterIds;

export {
  vendorAccountsDataList,
  getNewContactAdded,
  getAddContactToggleState,
  contacts,
  partyTags,
  checkForName,
  updateContactName,
  modalFlags,
  internalNotes,
  submitResponse,
  getVoucherViewMode,
  partyRelationTypes,
  getTransporterList,
  getShippingAddressList,
  partyAccountListFilters,
  getPartyAccountCurrentBalance
};
