import { fetch, mergeParams, extractData, userAuthFetch } from './server';

const OTP_URL = `/i-users/sign-in-user-mobile`;
const VERIFY_OTP_URL = `/i-users/sign-in-user-by-otp`;

export const generateOtp = payload => {
  return fetch
    .post(OTP_URL, payload)
    .then(extractData)
    .then(data => {
      if (payload.testing) {
        alert(`test otp is: ${data.smsId.otp}`);
      }
      return data;
    });
};

export const verifyOtp = data => {
  return fetch.post(VERIFY_OTP_URL, data).then(extractData);
};

export const registerUser = data => {
  return userAuthFetch.patch(`/i-users/register-new-user-data`, data).then(extractData);
};

export const updateUserName = (userId, payload) =>
  userAuthFetch.put(`/i-users/${userId}/name`, payload).then(extractData);

export const updateUserEmail = (userId, payload) =>
  userAuthFetch.put(`/i-users/${userId}/email`, payload).then(extractData);

export const fetchIfscCode = (userId, ifscCode) => {
  return userAuthFetch.get(`/i-users/${userId}/ifsc/${ifscCode}`);
};

export const passwordResetReq = data => {
  return userAuthFetch.post(`/i-users/companies/password-reset-request`, data).then(extractData);
};

export const passwordResetVerification = data => {
  return userAuthFetch
    .post(`/i-users/companies/password-reset-verification`, data)
    .then(extractData);
};

export const fetchUserGuideStatus = (userId, ifscCode) => {
  return userAuthFetch.get(`/i-users/${userId}/user-tour-guides`).then(extractData);
};

export const patchUserGuideStatus = (userId, payload) => {
  return userAuthFetch.patch(`/i-users/${userId}/user-tour-guides`, payload);
};

export const deleteUserToken = (userId, token) => {
  return userAuthFetch.delete(`/i-users/${userId}/access-tokens/${token}`);
};

export const fetchUserReferrals = userId => {
  return userAuthFetch.get(`/i-users/${userId}/referrals`).then(extractData);
};

export const generateUserOtp = (userId, testing) => {
  return userAuthFetch
    .put(`/i-users/${userId}/request-otp-generate?testing=${testing}`)
    .then(extractData);
};

export const getUserProfile = userId =>
  userAuthFetch.get(`/i-users/${userId}/user-profile`).then(extractData);

export const removeUserViaPasscode = passCode =>
  userAuthFetch.delete(`/i-users/${passCode}/remove`).then(extractData);

export const retrieveUserViaPasscode = passCode =>
  userAuthFetch.patch(`/i-users/${passCode}/restore`).then(extractData);
