import axios from 'axios';
import merge from 'lodash/merge';
import includes from 'lodash/includes';
import { store } from '../store';
import {
  USER_TOKEN,
  CURRENT_COMPANY_TOKEN,
  ERROR,
  CURRENT_COMPANY_PDF_TOKEN,
  PLAN_EXPIRED_ALREADY_MESSAGE
} from '../constants';
import { dateDiffInDays, makeRandomId, verifyCachedApi } from '../utils/helperFunctions';
import { HANDLE_CACHE_API } from '../actions/types';
import { handleCompanySignout } from '../actions/companies';
import { logoutUser, openSnackbar } from '../actions';
import { debounce } from 'lodash';
const WARNING_THRESHOLD = 1000 * 45; //if resp takes more that this value alret will trigger
const HIDE_ALERT_THRESHOLD = 1000 * 60; //if alert already shown dont trigger again for this value
const AXIOS_NETWORK_ERROR = 'Network Error';
const UNDEFINED_PARAMS_ERROR = 'Request params invalid';
const AXIOS_CANCEL_TOKEN = 'Cancel repeated request';

const defaultAxiosOptions = {
  baseURL: process.env.REACT_APP_API_URL
  // timeout: 1000
};

let timeoutTracker = {};
let showAlert = true;
//stack of api which are requested
let latestRequest = {};

const { dispatch, getState } = store;

export const PdfBaseUrl = 'http://invock-pdf-builder.herokuapp.com';
// Server request without authentication
export const fetch = axios.create(defaultAxiosOptions);

// Request to server with authorized header

export const userAuthFetch = axios.create(defaultAxiosOptions);

export const companyAuthFetch = axios.create(defaultAxiosOptions);

export const companyAuthFetchWithAxiosCancelToken = axios.create(defaultAxiosOptions);

export const mergeParams = (params = {}, testing) => merge(params, { testing: testing });

userAuthFetch.interceptors.request.use(config => {
  config.metadata = { ...startRequestTimeout(config.url) };
  config.headers['authorization'] = localStorage.getItem(USER_TOKEN);
  return config;
});

companyAuthFetch.interceptors.request.use(config => {
  const {
    currentCompany: { cachedAPI, planDetails = {} }
  } = getState();
  const CancelToken = axios.CancelToken;

  const companyId = extractCompanyIdFromApi(config.url);
  const dateDiff = parseInt(dateDiffInDays(planDetails.expiryDate, new Date()));

  let path = config.url;
  let isCancelAPI = true;

  if (typeof config.params?.isCancelAPI === 'boolean') {
    isCancelAPI = config.params.isCancelAPI;
  }

  if (config.params) {
    Object.keys(config.params).forEach(key => {
      config.url = config.url.replace(`:${key}`, config.params[key]);
      delete config.params[key];
    });
  }

  if (dateDiff < 0 && localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`)) {
    dispatch(openSnackbar(PLAN_EXPIRED_ALREADY_MESSAGE, ERROR));
    if (config.method !== 'get') {
      return {
        ...config,
        cancelToken: new CancelToken(cancel => cancel(PLAN_EXPIRED_ALREADY_MESSAGE))
      };
    }
  }

  let trimmedUrl = config.url.replace('/', '');
  const { flag: isCacheable, index, cached } = verifyCachedApi(trimmedUrl, cachedAPI);
  if (includes(config.url, 'undefined')) {
    console.log(config.url);
    return {
      ...config,
      cancelToken: new CancelToken(cancel => cancel(UNDEFINED_PARAMS_ERROR))
    };
  }
  if (cached && config.method === 'get') {
    // console.log(config, cached, index, isCacheable, '>>>>>>>');
    return {
      ...config,
      cancelToken: new CancelToken(cancel => cancel('Cancel repeated request'))
    };
    // return false
  }
  config.metadata = { ...startRequestTimeout(config.url), isCacheable, cachedApiIndex: index };
  config.headers['authorization'] = localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`);

  // manage repeat api trigger
  let source = CancelToken.source({ metadata: config.metadata });
  let apiQuery = config.url.split('?')[1];

  if (
    latestRequest[path] &&
    JSON.stringify(latestRequest[path]) !== JSON.stringify(apiQuery) &&
    isCancelAPI
  ) {
    // Cancel the previous request
    latestRequest[path].cancel({ message: AXIOS_CANCEL_TOKEN, config: config });
  }

  config.cancelToken = source.token;
  // Store the latest request details
  latestRequest[path] = {
    params: config.apiQuery,
    cancel: source.cancel
  };

  return config;
});

companyAuthFetchWithAxiosCancelToken.interceptors.request.use(config => {
  const {
    currentCompany: { cachedAPI, planDetails = {} }
  } = getState();
  const CancelToken = axios.CancelToken;

  let path = config.url;
  Object.keys(config.params).forEach(key => {
    config.url = config.url.replace(`:${key}`, config.params[key]);
    delete config.params[key];
  });

  const companyId = extractCompanyIdFromApi(config.url);
  const dateDiff = parseInt(dateDiffInDays(planDetails.expiryDate, new Date()));

  if (dateDiff < 0 && localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`)) {
    dispatch(openSnackbar(PLAN_EXPIRED_ALREADY_MESSAGE, ERROR));
    if (config.method !== 'get') {
      return {
        ...config,
        cancelToken: new CancelToken(cancel => cancel(PLAN_EXPIRED_ALREADY_MESSAGE))
      };
    }
  }

  let trimmedUrl = config.url.replace('/', '');
  const { flag: isCacheable, index, cached } = verifyCachedApi(trimmedUrl, cachedAPI);
  if (includes(config.url, 'undefined')) {
    console.log(config.url);
    return {
      ...config,
      cancelToken: new CancelToken(cancel => cancel(UNDEFINED_PARAMS_ERROR))
    };
  }
  if (cached && config.method === 'get') {
    return {
      ...config,
      cancelToken: new CancelToken(cancel => cancel('Cancel repeated request'))
    };
  }
  config.metadata = { ...startRequestTimeout(config.url), isCacheable, cachedApiIndex: index };
  config.headers['authorization'] = localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`);

  // manage repeat api trigger
  let source = CancelToken.source({ metadata: config.metadata });
  let apiQuery = config.url.split('?')[1];

  if (latestRequest[path] && JSON.stringify(latestRequest[path]) !== JSON.stringify(apiQuery)) {
    // Cancel the previous request
    latestRequest[path].cancel({ message: AXIOS_CANCEL_TOKEN, config: config });
  }

  config.cancelToken = source.token;
  // Store the latest request details
  latestRequest[path] = {
    params: config.apiQuery,
    cancel: source.cancel
  };

  return config;
});

function extractCompanyIdFromApi(url) {
  const arr = url.split('/');
  return arr && arr.length >= 3 ? arr[2] : '';
}

companyAuthFetch.interceptors.response.use(function(response) {
  stopRequestTimeout(response.config.metadata.reqId);
  delete latestRequest[response.config.url.split('?')[0]];
  if (response.config.metadata.isCacheable) {
    dispatch({
      type: HANDLE_CACHE_API,
      index: response.config.metadata.cachedApiIndex
    });
  }

  return response;
}, handelErrorResponse);

companyAuthFetchWithAxiosCancelToken.interceptors.response.use(function(response) {
  stopRequestTimeout(response.config.metadata.reqId);
  delete latestRequest[response.config.url.split('?')[0]];
  if (response.config.metadata.isCacheable) {
    dispatch({
      type: HANDLE_CACHE_API,
      index: response.config.metadata.cachedApiIndex
    });
  }

  return response;
}, handelErrorResponse);

userAuthFetch.interceptors.response.use(function(response) {
  stopRequestTimeout(response.config.metadata.reqId);
  return response;
}, handelErrorResponse);

/**
 *
 * @param {string} accessToken stored in LOCAL STORAGE
 * vs Company in Session Storage
 */
export const setUserAuthToken = accessToken => {
  localStorage.setItem(USER_TOKEN, accessToken);
};

/**
 *
 * @param {string} accessToken stored in SESSION STORAGE
 * vs User in LOCAL Storage
 * User token will remain persisted always while company token
 * changes based on selected company
 */
export const setCompanySessionToken = (accessToken, companyId) => {
  localStorage.setItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`, accessToken);
};

/**
 * @param {string} pdfToken stored in in LOCAL Storage
 */
export const setCompanyPdfToken = (accessToken, companyId) => {
  localStorage.setItem(`${CURRENT_COMPANY_PDF_TOKEN}_${companyId}`, accessToken);
};

export const extractData = r => r.data;
export const serverError = error => {
  try {
    if (error?.response?.status === 401) {
      return dispatch(openSnackbar(error.response.data.error.message, ERROR));
    }

    console.log(error);
    if (error.message !== AXIOS_NETWORK_ERROR && error.message !== AXIOS_CANCEL_TOKEN) {
      if (
        error.message === UNDEFINED_PARAMS_ERROR ||
        error.message === PLAN_EXPIRED_ALREADY_MESSAGE
      ) {
        dispatch(openSnackbar(error.message, ERROR));
      } else {
        dispatch(
          openSnackbar(
            error.response && error.response.data && error.response.data !== null
              ? error.response.data.message || error.response.data.error.message
              : 'something went wrong pls try again',
            ERROR
          )
        );
      }
    } else if (error.message === AXIOS_NETWORK_ERROR) {
      dispatch(openSnackbar('Failed to fetch data. Kindly retry the request', ERROR));
    }
  } catch (err) {
    console.log(error);
    console.log(err);
  }
};

function startRequestTimeout(url) {
  //gives each request a random id  to track
  //starts a counter that will show alert once triggered
  const reqId = makeRandomId(5);

  timeoutTracker[reqId] = window.setTimeout(function() {
    if (showAlert) {
      openSnackbar(`Internet connection slow \n${url}`, ERROR);
      showAlert = false;
      window.setTimeout(function() {
        showAlert = true;
      }, HIDE_ALERT_THRESHOLD);
    }
  }, WARNING_THRESHOLD);
  return { reqId };
}

function stopRequestTimeout(reqId) {
  //clears counter when response received
  window.clearTimeout(timeoutTracker[reqId]);
  delete timeoutTracker[reqId];
}

function handelNoInternetError(error) {
  if (showAlert) {
    showAlert = false;
    console.log(error.status, error.message, error.code);
    if (window.navigator.onLine) {
      openSnackbar('Oops Your Internet Connection dropped. Take a Break & Try Again!!!', ERROR);
    } else {
      openSnackbar('No internet connection', ERROR);
    }
    window.setTimeout(function() {
      showAlert = true;
    }, HIDE_ALERT_THRESHOLD);
  }
}

function handelErrorResponse(error) {
  //reconstruct error object
  if (error?.message?.message === AXIOS_CANCEL_TOKEN) {
    error.config = error?.message?.config;
    error.message = error?.message?.message;
  }

  error.config && stopRequestTimeout(error.config.metadata.reqId);
  if (!error.status && error.message.includes(AXIOS_NETWORK_ERROR)) {
    handelNoInternetError(error);
  }
  console.log(error.config);
  error.config && delete latestRequest[error.config.url.split('?')[0]];
  return Promise.reject(error);
}

window.addEventListener(
  'storage',
  debounce(e => handleLocalStorageChange(e), 250)
);

function handleLocalStorageChange(e) {
  if (e.storageArea === localStorage) {
    const companyId = extractCompanyIdFromRoute(window.location.pathname);
    const currentCompanyToken = localStorage.getItem(`${CURRENT_COMPANY_TOKEN}_${companyId}`);
    const userToken = localStorage.getItem(USER_TOKEN);

    if (companyId && !currentCompanyToken) {
      //if company token not found eg. user signout from another tab then signout here as well
      console.log('Logged out on another tab so loging out here');
      store.dispatch(handleCompanySignout(companyId));
    }

    if (
      window.location.pathname !== '/login' &&
      !userToken &&
      !(
        window.location.pathname.includes('/view/iShop/') ||
        window.location.pathname.includes('/view/shared-conversation/')
      )
    ) {
      store.dispatch(logoutUser());
    }
  }
}
function extractCompanyIdFromRoute(url) {
  var rx = /(\/)(.*?)(?=\/home)/g;
  var arr = rx.exec(url);
  return arr && arr.length >= 3 ? arr[2] : '';
}
