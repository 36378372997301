import { serverError } from '../api/server';
import * as api from '../api/parties';

// party business card
export const generatePartyBusinessCard = (accountId, callback) => (dispatch, getState) => {
  const {
    currentCompany: { id: companyId }
  } = getState();
  return api
    .generatePartyBusinessCard(companyId, accountId)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      serverError(error);
    });
};
