import algoliasearch from 'algoliasearch';
const applicationId = 'QKHD9BB8B3';
const apiKey = '34996e0691dbfc92fdaa32c60e23fece';
const algoliaClient = algoliasearch(applicationId, apiKey);

export const algoliaSearch = (query = '', hitsPerPage = 20, filter = '', page = 0, callback) => {
  const index = algoliaClient.initIndex(process.env.REACT_APP_ALGOLIA);
  index.search(
    {
      query: query,
      page: page,
      hitsPerPage,
      filters: filter
    },
    (err, hits) => {
      callback && callback(hits, err);
    }
  );
};
