import io from 'socket.io-client';
import { store } from './store';
import { PROGRESS_TRACKER, SET_UPDATE_CACHED_API } from './actions/types';

const baseSocketUrl = () => {
  if ('REACT_APP_API_HOST' in process.env) {
    return `https://${process.env.REACT_APP_API_HOST}`;
  } else {
    return 'https://invock-backend-test.herokuapp.com';
  }
};

// export const socket = io(baseSocketUrl());

const { dispatch, getState } = store;

// socket.on('disconnect', function() {
//   console.log('Disconnected');
// });

export const subscribeItemMasterEvent = (userId, companyId) => {
  // console.log('EVENT_SUBSCRIBED', `${companyId}-${userId}`);
  // subscribeCompanyEvent(companyId);
  // socket.on(`${companyId}-${userId}`, function(data) {
  //   console.log(data, 'PING');
  //   if (data.type === 'ProgressTracker') {
  //     dispatch({
  //       type: PROGRESS_TRACKER,
  //       payload: {
  //         percentage: data.data.currentStatusInPercentage,
  //         isCompleted: data.data.isCompleted
  //       }
  //     });
  //     if (data.data.isCompleted) {
  //       window.open(data.data.result.info.Location, '_blank').focus();
  //     }
  //   }
  // });
};

export const subscribeCompanyEvent = companyId => {
  // console.log('EVENT_SUBSCRIBED', `${companyId}`);
  // socket.on(`${companyId}`, function(data) {
  //   if (data.type === 'apiDataChanged') {
  //     console.log(data.data.key, 'Socket_API_DATA_CHANGED');
  //     dispatch({
  //       type: SET_UPDATE_CACHED_API,
  //       key: data.data.key
  //     });
  //   }
  // });
};

export const unSubscribeEvents = () => {
  // socket.removeAllListeners();
};

export const socketDisconnect = () => {
  // socket.disconnect();
};

export const socketConnect = (userId, companyId) => {
  // const token = localStorage.getItem('userAccessToken');
  // if (token) {
  //   socket.connect();
  // } else {
  //   socket.disconnect();
  // }
  // if (socket.connected) {
  //   userId && companyId && subscribeItemMasterEvent(userId, companyId);
  // }
  // socket.on('connect', function() {
  //   console.log('Connected');
  //   userId && companyId && subscribeItemMasterEvent(userId, companyId);
  // });
};
