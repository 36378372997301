import {
  FETCH_TRIAL_BALANCE_SUCCESS,
  SHOW_DETAIL_VIEW,
  SET_ROUTING_STATUS,
  SET_GROUP_DETAILS,
  SHOW_LEDGER_VIEW,
  SET_LEDGER_DETAILS,
  RESET_TO_REPORT_MODE_HOME,
  SET_ROUTES_DETAILS,
  SHOW_YEARLY_VIEW,
  SHOW_MONTHLY_VIEW,
  SHOW_PERIOD_DIALOG,
  CHANGE_DATE_RANGE,
  EXPAND_ACCOUNT,
  EXPAND_ALL_ACCOUNT,
  SET_PROFIT_LOSS,
  SET_REPORT_MODE,
  SET_BALANCE_SHEET,
  SHOW_DAY_VIEW,
  SET_DAY_BOOK_LIST,
  SET_REPORT_VOUCHER_TYPE,
  RESET_GROUP_DETAILS,
  SET_INVENTORY_LIST,
  SHOW_INVENTORY_VIEW,
  REPORTS_DATA_REQUESTED,
  REPORTS_DATA_SUCCESS,
  REPORTS_DATA_FAILED,
  SHOW_INVENTORY_GROUP_VIEW,
  SET_INVENTORY_GROUP_LIST,
  SET_DAY_BOOK_ACCOUNT_TYPE,
  SHOW_ACCOUNTS_VIEW,
  SHOW_ACCOUNT_VIEW,
  FETCH_INVENTORY_MONTH_VIEW,
  SET_REPORT_PARTY_FILTER,
  SET_REPORT_BRANCH_FILTER,
  SET_REPORT_VOUCHER_TYPE_FILTER,
  SET_REPORT_DATE_FILTER,
  SET_REPORT_INCLUDE_TYPE_FILTER,
  TOGGLE_REPORT_FILTERS,
  RESET_REPORT_FILTERS,
  SET_REPORT_CITY_FILTER,
  SET_REPORT_INCLUDE_SUNDRY_CRDR_FILTER,
  FETCH_STATE_REPORT_SUCCESS,
  SET_REPORT_PARTIES_STATES_FILTER,
  RESET_REPORT_CITY_FILTER,
  SET_REPORT_DATE_FILTER_RANGE,
  SET_REPORT_INTERVAL_FILTER,
  SET_NUMERIC_BARCODE_FILTER,
  FETCH_CITY_REPORT_SUCCESS,
  FETCH_CITY_REPORT_FAIL,
  SHOW_GSTR1_VIEW,
  SHOW_GSTR1_GROUP_VIEW,
  SHOW_GSTR1_STATEMENT_VIEW,
  EXPORT_REPORTS_DATA_REQUESTED,
  EXPORT_REPORTS_DATA_SUCCESS,
  EXPORT_REPORTS_DATA_FAILED,
  SET_REPORT_TAGS_FILTER,
  SET_REPORT_SEARCH_TEXT,
  TOGGLE_REPORT_BARCODE_SEARCH,
  SHOW_INVENTORY_ALL_ITEMS_VIEW,
  SET_REPORT_ITEM_GROUP_FILTER,
  SET_REPORT_ITEM_TAGS_FILTER,
  TOGGLE_REPORT_PARTY_CODE,
  TOGGLE_REPORT_EXPORT_BUTTONS,
  FETCH_REPORT_VOUCHERS_LIST,
  SET_REPORT_VOUCHER_FILTER,
  SET_REPORT_SALES_PERSON_FILTER,
  SET_SALES_PERSON_DETAILS,
  SET_VENDOR_WISE_DETAILS,
  SHOW_VOUCHER_DUE_LIST,
  SHOW_PARTY_VOUCHER_DUE_LIST,
  SET_REPORT_DUE_DATE_FILTER_RANGE
} from '../actions/types';
import {
  getStartOfFinancialYear,
  getEndOfFinancialYear,
  getFormattedDate3
} from '../utils/helperFunctions';

import {
  PROFIT_AND_LOSS_ACC,
  TRIAL_BALANCE,
  PROFIT_AND_LOSS,
  BALANCE_SHEET,
  DAY_BOOK,
  ACC_GRPS,
  INVENTORY,
  ALL_ACCOUNTS,
  INVENTORY_MONTHS_PATH,
  INVENTORY_ITEMS_PATH,
  INVENTORY_ITEM_STATEMENT_PATH,
  REPORTS_BASE_PATH,
  YEAR,
  GSTR1,
  INVENTORY_ITEM_GROUP_SEARCH_KEY,
  INVENTORY_ITEM_NAME_SEARCH_KEY,
  INVENTORY_ALL_ITEMS_PATH,
  SALES_PERSON,
  INVENTORY_VENDOR_WISE_PATH
} from '.././constants';
import _ from 'lodash';

const finStartDate = sessionStorage.getItem('startDate');
const finEndDate = sessionStorage.getItem('endDate');

const initialState = {
  trialBalanceData: [],
  profitAndLoss: {},
  balanceSheet: {},
  dayBook: [],
  // inventory: {
  //   totalQty: 0,
  //   totalAmount: 0,
  //   inventories: []
  // },
  inventory: {
    [INVENTORY_MONTHS_PATH]: {
      //data initialised here, structure based on payload received from GET API,
      monthly: [],
      total: { outwards: {}, inwards: {}, closingBalance: {}, stockValuationClosingBalance: {} } //totals for footer
    },
    [INVENTORY_ITEM_STATEMENT_PATH]: {
      statement: [],
      pageNo: 1,
      totalDataCount: 0,
      total: {
        outwards: {},
        inwards: {},
        closingBalance: {},
        openingBalance: {},
        stockValuationClosingBalance: {}
      }
    },
    [INVENTORY_ITEMS_PATH]: {
      inventories: [],
      pageNo: 1,
      totalDataCount: 0
    },
    [INVENTORY_ALL_ITEMS_PATH]: {
      inventories: [],
      pageNo: 1,
      totalDataCount: 0
    },
    [REPORTS_BASE_PATH]: {
      inventories: [],
      pageNo: 1,
      totalDataCount: 0
    },
    [INVENTORY_VENDOR_WISE_PATH]: {
      inventories: [],
      pageNo: 1,
      totalDataCount: 0
    }
  },
  gstr: {
    [REPORTS_BASE_PATH]: {
      statement: [],
      pageNo: 1,
      totalDataCount: 0,
      totals: {}
    },
    group: {
      statement: [],
      pageNo: 1,
      totalDataCount: 0,
      totals: {}
    },
    statementView: {
      statement: [],
      pageNo: 1,
      totalDataCount: 0,
      totals: {}
    }
  },
  stateReport: {
    [REPORTS_BASE_PATH]: {
      transactions: []
    }
  },
  cityReport: {
    transactions: []
  },
  inventoryGroup: {
    inventories: []
  },
  groupDetails: [],
  yearlyDetails: {},
  monthlyDetails: {
    data: {
      statement: [],
      openingBalanceSummary: {
        debit: 0,
        credit: 0,
        balance: 0
      },
      closingBalanceSummary: {
        debit: 0,
        credit: 0,
        balance: 0
      }
    }
  },
  dayBookDetails: {
    data: {
      accountStatement: []
    }
  },
  ledgerWiseDetails: [],
  headerProperties: {
    showDetailView: true,
    showDetailsCondencedBtn: false,
    showLedgerGroupBtn: true,
    showLedgerView: true,
    renderSubGrp: true,
    isAccount: false,
    groupName: 'Particulars',
    accountId: '',
    path: '',
    monthstartDate: '',
    monthEndDate: '',
    tableView: 'groupView',
    showAccountStatementView: false,
    showYearlyView: false,
    showDayView: false,
    takeGroupDetails: false,
    showPeriodButton: true,
    showPeriodDialog: false,
    showInventoryGroupView: false,
    reportMode: ''
  },
  dateRange: {
    startDate: finStartDate
      ? JSON.parse(finStartDate)
      : getFormattedDate3(getStartOfFinancialYear(new Date())),
    endDate: finEndDate
      ? JSON.parse(finEndDate)
      : getFormattedDate3(getEndOfFinancialYear(new Date()))
  },
  dayBookVoucherType: 'all',
  // dayBookAccountType: 'all',
  reportsLoading: {
    count: 0
  },
  reportsExportRequested: false,
  filters: {
    showFilters: false,
    selectedParties: [],
    selectedPartiesTags: [],
    selectedItemGroups: [],
    selectedItemTags: [],
    selectedBranches: [],
    selectedCities: [],
    selectedPartiesStates: [],
    selectedVoucherTypes: 'purchase-related,sales-related',
    startDate: finStartDate
      ? JSON.parse(finStartDate)
      : getFormattedDate3(getStartOfFinancialYear(new Date())),
    endDate: finEndDate
      ? JSON.parse(finEndDate)
      : getFormattedDate3(getEndOfFinancialYear(new Date())),

    dueStartDate: finStartDate
      ? JSON.parse(finStartDate)
      : getFormattedDate3(getStartOfFinancialYear(new Date())),
    dueEndDate: finEndDate
      ? JSON.parse(finEndDate)
      : getFormattedDate3(getEndOfFinancialYear(new Date())),
    includeType: { estimation: true, challan: true },
    includePartyAccountTypes: 'all',
    selectedVoucherType: 'all',
    selectedInterval: YEAR,
    selectedDueDateInterval: YEAR,
    numericSkuBarcode: '',
    barcodeSearchOn: false,
    searchText: '',
    [INVENTORY_ITEM_GROUP_SEARCH_KEY]: '',
    [INVENTORY_ITEM_NAME_SEARCH_KEY]: '',
    includePartyCode: false,
    includeItemLabel: false,
    includeItemAttributesAndLabel: false,
    excludeZeroBalanceItemGroup: false,
    selectedVoucher: null,
    selectedSalesPerson: {}
  },
  vouchersList: [],
  salesPersonDetails: [],
  partiesPaymentDueList: {},
  partyVouchersPaymentDueList: {}
};

export const reports = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRIAL_BALANCE_SUCCESS:
      return {
        ...state,
        trialBalanceData: action.payload
      };
    case SHOW_DETAIL_VIEW:
      return {
        ...state,
        trialBalanceData: {
          ...state.trialBalanceData,
          transactions:
            state.headerProperties.reportMode === TRIAL_BALANCE
              ? state.trialBalanceData.transactions.map(item =>
                  state.headerProperties.showDetailView
                    ? { ...item, open: true }
                    : { ...item, open: false }
                )
              : { ...state.trialBalanceData.transactions }
        },
        headerProperties: {
          ...state.headerProperties,
          showDetailView: !state.headerProperties.showDetailView
        },
        profitAndLoss:
          state.headerProperties.reportMode === PROFIT_AND_LOSS
            ? _.forEach(state.profitAndLoss, item =>
                // console.log(key)
                Array.isArray(item)
                  ? item.forEach(element =>
                      state.headerProperties.showDetailView
                        ? (element.open = true)
                        : (element.open = false)
                    )
                  : item
              )
            : { ...state.profitAndLoss },
        balanceSheet:
          state.headerProperties.reportMode === BALANCE_SHEET
            ? _.forEach(state.balanceSheet, item =>
                Array.isArray(item)
                  ? item.forEach(element =>
                      element.accountGroup === PROFIT_AND_LOSS_ACC
                        ? (element.open = true)
                        : state.headerProperties.showDetailView
                        ? (element.open = true)
                        : (element.open = false)
                    )
                  : item
              )
            : { ...state.balanceSheet }
      };
    case SET_ROUTES_DETAILS:
      return {
        ...state,
        routes: action.routes
      };
    case SET_ROUTING_STATUS:
      return {
        ...state,
        onClickStatus: {
          ...state.onClickStatus,
          isAccount: action.status.isAccount,
          groupName: action.status.groupName
        }
      };
    case SET_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: action.payload.response,
        headerProperties: {
          ...state.headerProperties,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: false,
          renderSubGrp: false,
          isAccount: action.payload.isAccount,
          groupName: action.payload.groupName,
          path: action.payload.path,
          takeGroupDetails: true,
          showAccountStatementView: false,
          showYearlyView: false,
          showDayView: false,
          reportMode: action.payload.reportMode
            ? action.payload.reportMode
            : state.headerProperties.reportMode
        }
      };
    case SHOW_LEDGER_VIEW:
      return {
        ...state,
        headerProperties: {
          ...state.headerProperties,
          showLedgerView: !state.headerProperties.showLedgerView,
          showDetailsCondencedBtn: !state.headerProperties.showDetailsCondencedBtn
        }
      };
    case SET_LEDGER_DETAILS:
      return {
        ...state,
        ledgerWiseDetails: action.payload
        // action.payload
      };
    case RESET_TO_REPORT_MODE_HOME:
      return {
        ...initialState,
        trialBalanceData: state.trialBalanceData,
        ledgerWiseDetails: state.ledgerWiseDetails,
        profitAndLoss: state.profitAndLoss,
        balanceSheet: state.balanceSheet,
        inventory: state.inventory,
        inventoryGroup: state.inventoryGroup,
        headerProperties: {
          ...initialState.headerProperties,
          reportMode: state.headerProperties.reportMode
        }
      };
    case RESET_GROUP_DETAILS:
      return {
        ...initialState,
        groupDetails: state.groupDetails,
        headerProperties: {
          ...initialState.headerProperties,
          groupName: ACC_GRPS[state.headerProperties.reportMode],
          reportMode: state.headerProperties.reportMode,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: false,
          renderSubGrp: false,
          takeGroupDetails: true,
          showAccountStatementView: false,
          showDayView: false,
          showYearlyView: false
        }
      };
    case SHOW_YEARLY_VIEW:
      return {
        ...state,
        yearlyDetails: action.payload.response,
        headerProperties: {
          ...state.headerProperties,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showYearlyView: action.payload.yearViewToggle
            ? !state.headerProperties.showYearlyView
            : state.headerProperties.showYearlyView,
          groupName: action.payload.accountName,
          accountId: action.payload.accountId,
          showAccountStatementView: action.payload.yearViewToggle
            ? !state.headerProperties.showAccountStatementView
            : state.headerProperties.showAccountStatementView,
          showDayView: false,
          takeGroupDetails: false
        }
      };
    case SHOW_MONTHLY_VIEW:
      return {
        ...state,
        monthlyDetails: {
          ...action.payload.response
        },
        headerProperties: {
          ...state.headerProperties,
          monthstartDate: action.payload.item.start,
          monthEndDate: action.payload.item.end,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: true,
          showYearlyView: false,
          showDayView: false
        }
      };
    case SHOW_DAY_VIEW:
      return {
        ...state,
        dayBookDetails: { ...action.payload.response },
        dayBookVoucherType: action.payload.type,
        dayBookAccountrType: action.payload.account,
        headerProperties: {
          ...state.headerProperties,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: false,
          showYearlyView: false,
          takeGroupDetails: false,
          showDayView: true,
          reportMode: DAY_BOOK
        }
      };
    case SHOW_VOUCHER_DUE_LIST:
      return {
        ...state,
        partiesPaymentDueList: action.payload,
        headerProperties: {
          ...state.headerProperties,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: false,
          showYearlyView: false,
          takeGroupDetails: false,
          showDayView: false
        }
      };
    case SHOW_PARTY_VOUCHER_DUE_LIST:
      return {
        ...state,
        partyVouchersPaymentDueList: action.payload,
        headerProperties: {
          ...state.headerProperties,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: false,
          showYearlyView: false,
          takeGroupDetails: false,
          showDayView: false
        }
      };
    case SHOW_INVENTORY_VIEW:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [REPORTS_BASE_PATH]: {
            ...action.payload.response.data,
            pageNo: action.payload.response.page,
            totalDataCount: action.payload.response.totalDataCount
          }
        },
        headerProperties: {
          ...state.headerProperties,
          // daysStartDate: action.payload.item.start,
          // daysEndDate: action.payload.item.end,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: false,
          showYearlyView: false,
          showInventoryGroupView: false,
          takeGroupDetails: true,
          showDayView: false,
          reportMode: INVENTORY
        }
        // dateRange: {
        //   startDate: action.payload.item.start,
        //   endDate: action.payload.item.end
        // }
      };
    case SHOW_GSTR1_VIEW:
      return {
        ...state,
        gstr: {
          ...state.gstr,
          [REPORTS_BASE_PATH]: {
            ...action.response
          }
        },
        headerProperties: {
          ...state.headerProperties,
          reportMode: GSTR1
        }
      };
    case SHOW_GSTR1_GROUP_VIEW:
      return {
        ...state,
        gstr: {
          ...state.gstr,
          group: {
            ...action.response.data,
            pageNo: action.response.page,
            totalDataCount: action.response.totalDataCount
          }
        },
        headerProperties: {
          ...state.headerProperties,
          reportMode: GSTR1
        }
      };
    case SHOW_GSTR1_STATEMENT_VIEW:
      return {
        ...state,
        gstr: {
          ...state.gstr,
          statementView: {
            ...action.response.data,
            pageNo: action.response.page,
            totalDataCount: action.response.totalDataCount
          }
        },
        headerProperties: {
          ...state.headerProperties,
          reportMode: GSTR1
        }
      };
    case SHOW_ACCOUNTS_VIEW:
      return {
        ...state,
        headerProperties: {
          ...state.headerProperties,
          daysStartDate: action.payload.item.start,
          daysEndDate: action.payload.item.end,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: false,
          showYearlyView: false,
          showInventoryGroupView: false,
          takeGroupDetails: true,
          showDayView: false,
          reportMode: ALL_ACCOUNTS
        },
        dateRange: {
          startDate: action.payload.item.start,
          endDate: action.payload.item.end
        }
      };
    case SHOW_ACCOUNT_VIEW:
      return {
        ...state,
        headerProperties: {
          ...state.headerProperties,
          groupName: action.payload.accountName,
          accountId: action.payload.accountId
        }
      };
    case SHOW_INVENTORY_ALL_ITEMS_VIEW:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [INVENTORY_ALL_ITEMS_PATH]: {
            ...action.payload.response.data,
            pageNo: action.payload.response.page,
            totalDataCount: action.payload.response.totalDataCount
          }
        },
        inventoryGroupId: action.payload.groupId,
        headerProperties: {
          ...state.headerProperties,
          reportMode: INVENTORY
        }
      };
    case SHOW_INVENTORY_GROUP_VIEW:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [INVENTORY_ITEMS_PATH]: {
            ...action.payload.response.data,
            pageNo: action.payload.response.page,
            totalDataCount: action.payload.response.totalDataCount
          }
        },
        inventoryGroupId: action.payload.groupId,
        headerProperties: {
          ...state.headerProperties,
          showDetailView: true,
          showDetailsCondencedBtn: false,
          showLedgerGroupBtn: false,
          showLedgerView: true,
          renderSubGrp: false,
          showAccountStatementView: false,
          showYearlyView: false,
          showInventoryGroupView: true,
          takeGroupDetails: true,
          showDayView: false,
          reportMode: INVENTORY
        }
      };
    case 'FETCH_ITEM_STATEMENT_VIEW':
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [INVENTORY_ITEM_STATEMENT_PATH]: {
            ...action.payload.response.data,
            pageNo: action.payload.response.page,
            totalDataCount: action.payload.response.totalDataCount
          }
        },
        headerProperties: {
          ...state.headerProperties,
          reportMode: INVENTORY
        }
      };
    case FETCH_INVENTORY_MONTH_VIEW:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [INVENTORY_MONTHS_PATH]: action.payload.response
        },
        headerProperties: {
          ...state.headerProperties,
          // daysStartDate: action.payload.item.start,
          // daysEndDate: action.payload.item.end,
          // showDetailView: true,
          // showDetailsCondencedBtn: false,
          // showLedgerGroupBtn: false,
          // showLedgerView: true,
          // renderSubGrp: false,
          // showAccountStatementView: false,
          // showYearlyView: false,
          // showInventoryGroupView: true,
          // takeGroupDetails: true,
          // showDayView: false,
          reportMode: INVENTORY
        }
      };
    case FETCH_STATE_REPORT_SUCCESS:
      return {
        ...state,
        stateReport: {
          ...state.stateReport,
          [REPORTS_BASE_PATH]: action.payload
        }
      };
    case FETCH_CITY_REPORT_SUCCESS:
      return {
        ...state,
        cityReport: {
          ...state.cityReport,
          ...action.payload
        }
      };
    case FETCH_CITY_REPORT_FAIL:
      return {
        ...state,
        cityReport: {
          ...initialState.cityReport
        }
      };
    case SHOW_PERIOD_DIALOG:
      return {
        ...state,
        headerProperties: {
          ...state.headerProperties,
          showPeriodDialog: !state.headerProperties.showPeriodDialog
        }
      };
    case CHANGE_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          startDate: action.range.startDate,
          endDate: action.range.endDate
        },
        headerProperties: {
          ...state.headerProperties,
          showPeriodDialog: false
        }
      };
    case EXPAND_ACCOUNT:
      return {
        ...state,
        profitAndLoss:
          state.headerProperties.reportMode === 'profit-and-loss'
            ? {
                ...state.profitAndLoss,
                [action.details.key]: state.profitAndLoss[action.details.key].map((item, i) =>
                  i === action.details.index ? { ...item, open: !item.open } : item
                )
              }
            : { ...state.profitAndLoss },
        balanceSheet:
          state.headerProperties.reportMode === 'balance-sheet'
            ? {
                ...state.balanceSheet,
                [action.details.key]: state.balanceSheet[action.details.key].map((item, i) =>
                  i === action.details.index ? { ...item, open: !item.open } : item
                )
              }
            : { ...state.balanceSheet }
      };
    case EXPAND_ALL_ACCOUNT:
      return {
        ...state,
        ...action.details
      };
    case SET_PROFIT_LOSS:
      return {
        ...state,
        profitAndLoss: action.response
      };
    case SET_REPORT_MODE:
      return {
        ...state,
        headerProperties: {
          ...initialState.headerProperties,
          reportMode: action.reportMode
        }
      };
    case SET_BALANCE_SHEET:
      return {
        ...state,
        balanceSheet: action.response
      };
    case SET_DAY_BOOK_LIST:
      return {
        ...state,
        dayBook: action.response
      };
    case SET_REPORT_VOUCHER_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedVoucherType: action.data
        }
      };
    case SET_DAY_BOOK_ACCOUNT_TYPE:
      return {
        ...state,
        dayBookAccountType: action.data
      };
    case SET_INVENTORY_LIST:
      return {
        ...state,
        inventory: action.response
      };
    case SET_INVENTORY_GROUP_LIST:
      return {
        ...state,
        inventoryGroup: action.response
      };
    case REPORTS_DATA_REQUESTED:
      return {
        ...state,
        reportsLoading: {
          count: state.reportsLoading.count + 1
        }
      };
    case REPORTS_DATA_SUCCESS:
      return {
        ...state,
        reportsLoading: {
          count: state.reportsLoading.count - 1
        }
      };
    case REPORTS_DATA_FAILED:
      return {
        ...state,
        reportsLoading: {
          count: state.reportsLoading.count - 1
        }
      };
    case TOGGLE_REPORT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          showFilters: action.flag
        }
      };
    case RESET_REPORT_FILTERS:
      initialState.filters = {
        ...initialState.filters,
        startDate: JSON.parse(sessionStorage.getItem('startDate')),
        endDate: JSON.parse(sessionStorage.getItem('endDate'))
      };
      return {
        ...state,
        filters: {
          ...initialState.filters,
          showFilters: state.filters.showFilters
        }
      };
    case SET_REPORT_PARTY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedParties: action.selectedParties
        }
      };
    case SET_REPORT_SALES_PERSON_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedSalesPerson: action.selectedSalesPerson
        }
      };
    case SET_REPORT_TAGS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedPartiesTags: action.selectedPartiesTags
        }
      };
    case SET_REPORT_ITEM_GROUP_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedItemGroups: action.selectedItemGroups
        }
      };
    case SET_REPORT_ITEM_TAGS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedItemTags: action.selectedItemTags
        }
      };
    case SET_REPORT_BRANCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedBranches: action.selectedBranches
        }
      };
    case SET_REPORT_CITY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCities: action.selectedCities
        }
      };
    case RESET_REPORT_CITY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCities: initialState.filters.selectedCities
        }
      };
    case SET_REPORT_PARTIES_STATES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedPartiesStates: action.selectedParties
        }
      };
    case SET_REPORT_VOUCHER_TYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedVoucherTypes: action.selectedVoucherTypes
        }
      };
    case SET_REPORT_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.selectedDate
        }
      };
    case SET_REPORT_DATE_FILTER_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.startDate,
          endDate: action.endDate
        }
      };
    case SET_REPORT_DUE_DATE_FILTER_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          dueStartDate: action.startDate,
          dueEndDate: action.endDate
        }
      };
    case SET_REPORT_INTERVAL_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.selectedInterval
        }
      };
    case SET_NUMERIC_BARCODE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          numericSkuBarcode: action.payload
        }
      };
    case SET_REPORT_INCLUDE_TYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          includeType: {
            ...state.filters.includeType,
            [action.key]: action.flag
          }
        }
      };
    case SET_REPORT_INCLUDE_SUNDRY_CRDR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          includePartyAccountTypes: action.includePartyAccountTypes
        }
      };
    case EXPORT_REPORTS_DATA_REQUESTED:
      return {
        ...state,
        reportsExportRequested: true
      };
    case EXPORT_REPORTS_DATA_SUCCESS:
      return {
        ...state,
        reportsExportRequested: false
      };
    case EXPORT_REPORTS_DATA_FAILED:
      return {
        ...state,
        reportsExportRequested: false
      };
    case SET_REPORT_SEARCH_TEXT:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.searchText
        }
      };
    case TOGGLE_REPORT_BARCODE_SEARCH:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.flag
        }
      };
    case TOGGLE_REPORT_PARTY_CODE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.flag
        }
      };
    case TOGGLE_REPORT_EXPORT_BUTTONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.flag
        }
      };
    case FETCH_REPORT_VOUCHERS_LIST:
      return {
        ...state,
        vouchersList: action.response
      };
    case SET_REPORT_VOUCHER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedVoucher: action.id
        }
      };
    case SET_SALES_PERSON_DETAILS:
      return {
        ...state,
        salesPersonDetails: action.payload,
        headerProperties: {
          ...initialState.headerProperties,
          reportMode: SALES_PERSON
        }
      };
    case SET_VENDOR_WISE_DETAILS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [INVENTORY_VENDOR_WISE_PATH]: action.payload
        }
      };
    default:
      return state;
  }
};

export const getDetailButtonStatus = ({
  reports: {
    headerProperties: { showDetailView }
  }
}) => showDetailView;
export const fetchTrailBalanceData = ({ reports: { trialBalanceData } }) => trialBalanceData;
export const getHeaderProperties = ({ reports: { headerProperties } }) => headerProperties;
export const getGroupDetails = ({ reports: { groupDetails } }) => groupDetails;
export const getLedgerButtonStatus = ({
  reports: {
    headerProperties: { showLedgerView }
  }
}) => showLedgerView;
export const getDateRange = ({ reports: { dateRange } }) => dateRange;
export const getCompanyNameAndId = ({ currentCompany: { name, id } }) => {
  return { name, id };
};
export const getLedgerWiseDetails = ({ reports: { ledgerWiseDetails } }) => ledgerWiseDetails;
export const getYearlyWiseDetails = ({ reports: { yearlyDetails } }) => yearlyDetails;
export const getMonthlyWiseDetails = ({ reports: { monthlyDetails } }) => monthlyDetails;
export const fetchProfitAndLoss = ({ reports: { profitAndLoss } }) => profitAndLoss;
export const fetchBalanceSheet = ({ reports: { balanceSheet } }) => balanceSheet;
export const getDayBook = ({ reports: { dayBookDetails } }) => dayBookDetails;
export const getInventory = ({ reports: { inventory } }) => inventory;
export const getStateReportData = ({ reports: { stateReport } }) => stateReport;
export const getCityWiseReportData = ({ reports: { cityReport } }) => cityReport;
export const getGstr = ({ reports: { gstr } }) => gstr;
