import map from 'lodash/map';
import React from 'react';
import _, { random } from 'lodash';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import moment from 'moment';
import XLSX from 'xlsx';
import { geocodeByAddress } from 'react-places-autocomplete';
import VoucherCalModule from 'voucher-calculation';
import { store } from '../store';
import {
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_DEBIT_NOTE,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_PESTIMATION,
  VOUCHER_TYPE_SALES,
  VOUCHER_TYPE_SCHALLAN,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_EXPENSE,
  PURCHASE_PRICE,
  WHOLESALE_PRICE,
  RETAIL_PRICE,
  TODAY,
  WEEK,
  MONTH,
  YEAR,
  DAY,
  SUNDRY_CREDITOR_PATH,
  SUNDRY_DEBITOR_PATH,
  RECONCILED,
  TABLE_COL_WIDTH_MULT,
  VOUCHER_TYPE_LINKED_PESTIMATION,
  VOUCHER_TYPE_LINKED_SESTIMATION,
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  UNREGISTERED_BUSINESS,
  VOUCHER_TYPE_RECEIPT,
  VOUCHER_TYPE_PAYMENT,
  CASH_IN_HAND,
  BANK_ACCOUNTS,
  RUPEES,
  VOUCHER_TYPE_CREDIT_NOTE,
  tagBgColors,
  CREDIT_SHORT,
  DEBIT_SHORT,
  EDIT,
  PERCENT,
  DEFAULT_TABLE_ID,
  CURRENCY_DECIMAL_ROUND,
  BUSINESS_ADMIN,
  PROFIT_AND_LOSS_ACC,
  ROUND_OFF,
  INDIRECT_EXPENSE,
  PURCHASE
} from '../constants';
import find from 'lodash/find';
import { noImageThumb, FileIcon } from '../images';
import split from 'lodash/split';
import { White12, White12Med } from '../components/Common';
import browserDetails from './browserDetails';
import { getRoundOffValue, getRateCodeValue } from '../actions';
import { convertAmountToWords } from 'voucher-calculation/lib/amount-to-words';

const getUnitPrice = VoucherCalModule.salesDiscountPureFn;
const areaPriority = [
  'country',
  'administrative_area_level_1',
  'locality',
  'administrative_area_level_2'
];

export const getLineBreakUpText = string => {
  const splitText = string && string.length && string.replace(/\n/gi, '|').split('|');
  return splitText && splitText.length > 0 ? (
    <React.Fragment>
      {splitText.map((text, i) => (
        <div key={i}>{text}</div>
      ))}
    </React.Fragment>
  ) : (
    <div>{string}</div>
  );
};

export const getAccount = (accounts, accId, voucherType = '') => {
  if (!accounts || accounts.length === 0) {
    return {};
  }
  const filteredAccounts = accounts.filter(acc => acc.refAccountId !== accId);
  if (filteredAccounts.length > 0) {
    const sundryDebtors = filteredAccounts.filter(
      acc => acc.refPath === 'primary/current-assets/sundry-debtors'
    );
    if (sundryDebtors.length > 0) {
      return sundryDebtors[0];
    }
    const sundryCreditors = filteredAccounts.filter(
      acc => acc.refPath === 'primary/current-liabilities/sundry-creditors'
    );
    if (sundryCreditors.length > 0) {
      return sundryCreditors[0];
    }
    const cashInHand =
      voucherType !== VOUCHER_TYPE_RECEIPT && voucherType !== VOUCHER_TYPE_PAYMENT
        ? filteredAccounts.filter(acc => acc.refPath === CASH_IN_HAND)
        : [];
    if (cashInHand.length > 0) {
      return cashInHand[0];
    }

    for (const account of filteredAccounts) {
      if (
        ((voucherType !== VOUCHER_TYPE_RECEIPT || voucherType !== VOUCHER_TYPE_PAYMENT) &&
          (account.refPath === CASH_IN_HAND || account.refPath === BANK_ACCOUNTS)) ||
        (voucherType === VOUCHER_TYPE_EXPENSE &&
          account.refAccountName === ROUND_OFF &&
          account.refAccountGroupName === INDIRECT_EXPENSE)
      ) {
        continue;
      } else {
        return account;
      }
    }

    return filteredAccounts[0];
  } else {
    return {};
  }
};

export const getValuesByKey = (dataArr, key = '') => {
  const array = [];
  dataArr &&
    dataArr.length > 0 &&
    dataArr.forEach(d => (key ? array.push(d[key]) : array.push(d.id)));
  return array;
};

export const arrayToAlgoliaQueryStr = (array, key) =>
  reduce(array, (prev, id) => `${prev}&${key}=${id}`, '');

export const getAddressFormat = value => {
  return value && value.trim() !== '' ? `${value}, ` : '';
};

export const getUniqArray = (data, uniqByProperty, property) => {
  if (!data || data.length === 0 || !uniqByProperty || uniqByProperty === '') return [];
  let uniqArray = [];
  data.forEach((d, i) => {
    // d.child = true;
    if (uniqArray.length > 0) {
      const currentCharge = uniqArray.filter(a => a[uniqByProperty] === d[uniqByProperty]);
      if (currentCharge.length > 0) {
        property &&
          d[property] &&
          uniqArray.forEach((c, j) => {
            if (c[uniqByProperty] === d[uniqByProperty]) {
              const tempArray = cloneDeep(uniqArray);
              tempArray[j][property] += d[property];
              uniqArray = tempArray;
            }
          });
      } else {
        uniqArray.push(d);
      }
      return;
    }
    uniqArray.push(d);
  });
  return uniqArray;
};

export const getDateInterval = (type, fyStartDate, fyEndDate) => {
  let tempEndDate = getEndOfRange(DAY);
  let tempStartDate;

  switch (type) {
    case WEEK:
      tempStartDate = moment().add(-1, WEEK)._d;
      break;
    case MONTH:
      tempStartDate = moment().add(-1, MONTH)._d;
      break;
    case YEAR:
      tempStartDate = fyStartDate;
      tempEndDate = fyEndDate;
      break;
    case TODAY:
    default:
      tempStartDate = getStartOfRange(DAY);
      break;
  }
  return { tempStartDate, tempEndDate };
};

export const customSortForSrNoByGroup = (a, b) => {
  if (isNaN(a) && isNaN(b) && a.split('-').length > 0 && b.split('-').length > 0) {
    var o1 = parseInt(a.split('-')[1], 10);
    var o2 = parseInt(b.split('-')[1], 10);

    var p1 = a.split('-')[0].toLowerCase();
    var p2 = b.split('-')[0].toLowerCase();

    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
  } else {
    if (a < b) return -1;
    if (a > b) return 1;
  }
  return 0;
};

export const customSortForSku = (a, b) => {
  var o1 = parseInt(a.split('-')[2], 10);
  var o2 = parseInt(b.split('-')[2], 10);

  var p1 = a.split('-')[0].toLowerCase();
  var p2 = b.split('-')[0].toLowerCase();

  if (p1 < p2) return -1;
  if (p1 > p2) return 1;
  if (o1 < o2) return -1;
  if (o1 > o2) return 1;
  return 0;
};

export const errorObj = (error, alertbox = false) => {
  alertbox
    ? console.log('error:', error)
    : alert(
        error.response && error.response.data && error.response.data !== null
          ? error.response.data.error.message
          : 'Something went wrong, please try again!'
      );
};

export const getfloatingValue = (
  value,
  decimalPlaces = CURRENCY_DECIMAL_ROUND,
  getStringValueflag = false
) => {
  if (value === '' || isNaN(value)) value = 0;
  return getStringValueflag
    ? parseFloat(value).toFixed(decimalPlaces)
    : Number(parseFloat(value).toFixed(decimalPlaces));
};

/**
 * Calculate the unit price from the maximum retail price and tax percentage.
 *
 * @param {number} price - The maximum retail price
 * @param {number} taxPercentage - The tax percentage
 * @return {number} The calculated unit price
 */
export const getUnitPriceFromMRP = (price, taxPercentage, decimalPlace) => {
  return getfloatingValue((price * 100) / (100 + taxPercentage), decimalPlace);
};

/**
 * Calculate the unit price for a given payload, taking into party state and company voucher settings.
 *
 * @param {object} state - The voucher state object
 * @param {object} payload - The item payload object
 * @param {object} companyVoucherSettings - The company voucher settings object
 * @return {number} The calculated unit price
 */
export const getUnitPriceForPayload = (state, payload, companyVoucherSettings) => {
  let itemGroupId = payload.itemGroupId;
  let salesDiscounts = state.selectedPartyAccount.salesDiscounts;
  let wspRspunitPriceWithoutExcluseiveDiscount = getUnitPrice.getLineItemUnitPrice(
    itemGroupId,
    parseFloat(payload.unitSellWholeSalePrice),
    parseFloat(payload.unitSellRetailPrice),
    salesDiscounts
  );

  let unitPrice = 0;

  if (state.type === VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    unitPrice =
      companyVoucherSettings[state.type] && companyVoucherSettings[state.type].unitPrice
        ? payload[companyVoucherSettings[state.type].unitPrice]
        : payload.unitSellWholeSalePrice;
  } else {
    unitPrice = purchaseTypeFlag(state.type)
      ? payload.unitPurchasePrice
      : wspRspunitPriceWithoutExcluseiveDiscount;
  }

  return unitPrice;
};

export const getUnitDiscounteForPayload = (state, payload) => {
  let itemGroupId = payload.itemGroupId;
  let salesDiscounts = state.selectedPartyAccount.salesDiscounts;
  let wspRspDiscount = getUnitPrice.getLineItemUnitDiscount(
    itemGroupId,
    parseFloat(payload.wholesaleDiscountValue),
    payload.wholesaleDiscountUnit,
    parseFloat(payload.retailDiscountValue),
    payload.retailDiscountUnit,
    salesDiscounts
  );
  const unitDiscount = purchaseTypeFlag(state.type)
    ? {
        itemDiscountValue: 0,
        itemDiscountUnit: PERCENT
      }
    : state.type === VOUCHER_TYPE_STOCK_ADJUSTMENT
    ? {
        itemDiscountValue: parseFloat(payload.wholesaleDiscountValue),
        itemDiscountUnit: payload.wholesaleDiscountUnit
      }
    : wspRspDiscount;
  return unitDiscount;
};

export const getItemPriceTooltip = unit => {
  let tooltip = '';
  switch (unit) {
    case 'W':
      tooltip = WHOLESALE_PRICE;
      break;
    case 'R':
      tooltip = RETAIL_PRICE;
      break;
    default:
      tooltip = PURCHASE_PRICE;
  }
  return tooltip;
};

export const getToolTipText = (tooltip, row, voucherType, standardItemPrice) => {
  return voucherType === VOUCHER_TYPE_STOCK_ADJUSTMENT ? (
    <React.Fragment>
      <div style={{ textAlign: 'left' }}>
        <White12Med>Retail Price:</White12Med>
        <White12>{` ${RUPEES}${row.unitSellRetailPrice}`}</White12>
      </div>
      <div style={{ textAlign: 'left' }}>
        <White12Med>Wholesale Price: </White12Med>
        <White12>{` ${RUPEES}${row.unitSellWholeSalePrice}`}</White12>
      </div>
      {row.qtyAvaliable && (
        <div style={{ textAlign: 'left' }}>
          <White12Med>Quantity Avaliable:</White12Med>
          <White12>{` ${row.qtyAvaliable}`}</White12>
        </div>
      )}
    </React.Fragment>
  ) : (
    `Item ${tooltip} is ${standardItemPrice} Rs`
  );
};

//TODO Need this from NPM function.
export const getUnitForLineItemUnitPrice = (props, row, voucherState, standardPrice) => {
  let itemGroupId = row.itemGroupId;
  let salesDiscounts = voucherState.selectedPartyAccount.salesDiscounts;
  let unit = '';
  if (props.voucherType === VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    unit = '';
  } else if (
    purchaseTypeFlag(props.voucherType) &&
    parseFloat(standardPrice).toFixed(2) === parseFloat(row.unitPurchasePrice).toFixed(2)
  ) {
    unit = 'P';
  } else if (salesTypeFlag(props.voucherType)) {
    if (
      parseFloat(standardPrice).toFixed(2) === parseFloat(row.unitSellWholeSalePrice).toFixed(2)
    ) {
      unit = 'W';
    } else if (
      parseFloat(standardPrice).toFixed(2) === parseFloat(row.unitSellRetailPrice).toFixed(2)
    ) {
      unit = 'R';
    } else if (
      parseFloat(standardPrice).toFixed(2) === parseFloat(row.unitSellWholeSalePrice).toFixed(2) &&
      parseFloat(row.unitSellWholeSalePrice).toFixed(2) ===
        parseFloat(row.unitSellRetailPrice).toFixed(2) &&
      salesDiscounts &&
      salesDiscounts.length > 0
    ) {
      for (var i = 0; i < salesDiscounts.length; i++) {
        var salesDiscount = salesDiscounts[i];
        if (salesDiscount.itemgroupId === itemGroupId) {
          unit = salesDiscount.useWholesalePrice === true ? 'W' : 'R';
        }
      }
    }
  }
  return unit;
};

export const getTotalOfVoucherCrDrBalance = vouchers => {
  let totals = _.reduce(
    vouchers,
    (sum, voucher) => {
      sum.db += voucher.debit;
      sum.cr += voucher.credit;
      sum.rc += voucher.debit - voucher.credit;
      return sum;
    },
    { db: 0, cr: 0, rc: 0 }
  );
  return totals;
};

export const getTotalOfTax = tax => {
  let totalIGST = 0;
  let totalCGST = 0;
  let totalSGST = 0;
  let totalTax = 0;
  tax &&
    tax.length > 0 &&
    tax.forEach(t => {
      totalIGST = t.igst + totalIGST;
      totalCGST = t.cgst + totalCGST;
      totalSGST = t.sgst + totalSGST;
      totalTax = t.tax + totalTax;
    });
  return {
    totalCGST,
    totalIGST,
    totalSGST,
    totalTax
  };
};

export const getSelectedGroup = (id, itemGroups) => {
  const selectedGroup = itemGroups.filter(grp => grp.id === id)[0];
  return selectedGroup || {};
};

export const roundOffFlag = roundOff => {
  let flag = false;
  flag =
    roundOff &&
    roundOff.length > 0 &&
    roundOff[0].roundOffMethod !== '' &&
    roundOff[0].precisionMultiplier !== 0;
  return flag;
};

export const rateCodeFlag = input => {
  if (!input || !input.rateCode) {
    return false;
  }
  const {
    rateCode: { wsp1, wsp2, rsp1, rsp2 }
  } = input;
  let flag = false;
  flag = wsp1 + wsp2 + rsp1 + rsp2 !== 0;
  return flag;
};

// get a flag if voucher type has same functionality as purchase

export const purchaseTypeFlag = voucherType => {
  const flag =
    voucherType === VOUCHER_TYPE_PURCHASE ||
    voucherType === VOUCHER_TYPE_PCHALLAN ||
    voucherType === VOUCHER_TYPE_PESTIMATION ||
    voucherType === VOUCHER_TYPE_LINKED_PESTIMATION ||
    voucherType === VOUCHER_TYPE_DEBIT_NOTE ||
    voucherType === VOUCHER_TYPE_EXPENSE;

  return flag;
};

export const dateDiffInDays = (a, b) => {
  const diff = moment(a).diff(moment(b), 'days', true);
  return diff;
};

export const salesTypeFlag = voucherType => {
  const flag =
    voucherType === VOUCHER_TYPE_SALES ||
    voucherType === VOUCHER_TYPE_SCHALLAN ||
    voucherType === VOUCHER_TYPE_SESTIMATION ||
    voucherType === VOUCHER_TYPE_LINKED_SESTIMATION ||
    voucherType === VOUCHER_TYPE_CREDIT_NOTE;

  return flag;
};

export const showExclusiveDiscount = voucherType => {
  const flag =
    voucherType === VOUCHER_TYPE_SALES ||
    voucherType === VOUCHER_TYPE_SCHALLAN ||
    voucherType === VOUCHER_TYPE_SESTIMATION ||
    voucherType === VOUCHER_TYPE_LINKED_SESTIMATION ||
    voucherType === VOUCHER_TYPE_CREDIT_NOTE ||
    voucherType === VOUCHER_TYPE_PURCHASE ||
    voucherType === VOUCHER_TYPE_PCHALLAN ||
    voucherType === VOUCHER_TYPE_PESTIMATION ||
    voucherType === VOUCHER_TYPE_LINKED_PESTIMATION ||
    voucherType === VOUCHER_TYPE_DEBIT_NOTE;

  return flag;
};

export const calculateTotal = (arr, key) => {
  const add = (a, b) => a + b;
  const amount = _.map(arr, items => parseFloat(items[key]));
  const totalAmount = amount.length > 0 ? amount.reduce(add).toFixed(2) : 0.0;
  return parseFloat(totalAmount);
};

export const setRefIdTotempJournals = (accountNameList, selectedAccount) => {
  // const selectedAccounts = _.filter(accountNameList, items => items.name === value);
  // if (selectedAccounts.length > 0) {
  //   let tempObj = selectedAccounts[0];
  return {
    name: selectedAccount
      ? selectedAccount.business
        ? `${selectedAccount.business.name} (${selectedAccount.business.aliasName} ${
            selectedAccount.business.city ? '- ' + selectedAccount.business.city : ''
          })`
        : selectedAccount.name
      : '',
    // description: selectedAccount.description,
    refAccountId: selectedAccount.id,
    accountGroupName: selectedAccount.accountGroupName,
    refPath: selectedAccount.path
  };
  // }
};

export const getdocumentSchema = fileObjList => {
  const payload =
    fileObjList && fileObjList.length > 0
      ? fileObjList.map(fileObj => {
          const { url, fileType, name } = fileObj;
          return {
            id: fileObj.id || undefined,
            url,
            fileType,
            name
          };
        })
      : [];
  return payload;
};

export function objectToArray(object) {
  return map(object, item => item);
}

export function getBranchId(allBranches, selectedBranch) {
  // const branchId = allBranches.length === 1 ? allBranches[0].id : selectedBranch.id;
  const branchId = (selectedBranch && selectedBranch.id) || (allBranches && allBranches[0].id);
  return branchId;
}

export function getBranchState(allBranches, selectedBranch) {
  const branchState = allBranches.length === 1 ? allBranches[0].state : selectedBranch.state;
  return branchState;
}

export function getPartyVendorCode(itemList, itemId) {
  const currentItem = itemList.length > 0 && itemList.filter(item => item.id === itemId);
  const vendorDesignCode = currentItem.length > 0 ? currentItem[0].partyDesignCode : '';
  return vendorDesignCode;
}

export function getItemUnitPrice(item, type) {
  // const currentItem = itemList.length > 0 && itemList.filter(item => item.id === itemId);
  let unitPrice = 0;

  if (type && item) {
    unitPrice = purchaseTypeFlag(type)
      ? item.unitPurchasePrice
      : salesTypeFlag(type)
      ? item.unitSellRetailPrice
      : item.unitSellWholeSalePrice;
  }
  return unitPrice;
}

export function getWSP(item) {
  // const currentItem = itemList.length > 0 && itemList.filter(item => item.id === itemId);
  const wholeSellPrice = item.unitSellWholeSalePrice ? item.unitSellWholeSalePrice : 0;
  const wholesaleMarkup = item.wholesaleMarkup ? item.wholesaleMarkup : 0;
  return `${wholeSellPrice} (${wholesaleMarkup}%)`;
}

export function getRSP(item) {
  // const currentItem = itemList.length > 0 && itemList.filter(item => item.id === itemId);
  const retailSellPrice = item.unitSellRetailPrice ? item.unitSellRetailPrice : 0;
  const retailMarkup = item.retailMarkup ? item.retailMarkup : 0;
  return `${retailSellPrice} (${retailMarkup}%)`;
}

export function getTaxPercentageAndHsn(itemGroups, itemGroupId) {
  const currentGroup =
    itemGroups.length > 0 ? itemGroups.filter(grp => grp.id === itemGroupId) : [];
  const taxPercentage = currentGroup.length > 0 ? currentGroup[0].taxPercentage : 0;
  const hsn = currentGroup.length > 0 ? currentGroup[0].hsn : '';
  const isService = currentGroup.length > 0 ? currentGroup[0].isService : false;
  return { taxPercentage, hsn, isService };
}

export function getRateCode(itemList, itemId) {
  const currentItem = itemList.length > 0 && itemList.filter(item => item.id === itemId);
  const rateCode = currentItem.length > 0 && currentItem[0].rateCode ? currentItem[0].rateCode : 0;
  return rateCode;
}

export function getPartyGstBusinessTypeRef(
  type,
  selectedPartyAccount = {},
  allBranches,
  selectedBranch = {}
) {
  if (type === VOUCHER_TYPE_STOCK_ADJUSTMENT) {
    return UNREGISTERED_BUSINESS;
  }
  const { gstBusinessType, gstPartyType } = selectedPartyAccount;
  const gstBranchType =
    allBranches.length === 1 ? allBranches[0].gstBusinessType : selectedBranch.gstBusinessType;
  let partyGstBusinessTypeRef =
    type === VOUCHER_TYPE_PURCHASE ||
    type === VOUCHER_TYPE_DEBIT_NOTE ||
    type === VOUCHER_TYPE_PCHALLAN ||
    type === VOUCHER_TYPE_PESTIMATION ||
    type === VOUCHER_TYPE_EXPENSE
      ? gstBusinessType || gstPartyType
      : gstBranchType;
  return partyGstBusinessTypeRef;
}

export function getPartyNameFilteredById(partyId = '', data = []) {
  const party = data.filter(d => d.id === partyId || d._id === partyId);
  return party.length > 0 ? party[0].name : '';
}

export function getPartyLabel(party) {
  return party ? (
    <div>
      {party.name}{' '}
      <span style={{ fontSize: 11, fontWeight: '500', whiteSpace: 'nowrap' }}>
        {party.aliasName && `(${party.aliasName})`} {party.city && `- ${party.city}`}
      </span>
    </div>
  ) : (
    ''
  );
}

export function getFilteredData(data, property, query, filterPartyType) {
  if (!query && !filterPartyType) {
    return data;
  }
  let filteredArray = [];
  let re;
  let tempQuery;

  switch (property) {
    case 'name':
      filteredArray = filter(data, item => {
        const string = `${item && item['name'] ? item['name'].toString().toLowerCase() : ''} ${
          item && item['aliasName'] ? item['aliasName'].toString().toLowerCase() : ''
        }`;
        if (string.indexOf(query.toLowerCase()) > -1) {
          return item;
        }
      });
      break;
    case 'channelList':
      filteredArray = filter(data, item => {
        if (
          item &&
          item['name']
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        ) {
          return item;
        }
      });
      break;
    case 'party':
      filteredArray = filter(data, item => {
        if (
          item[property] &&
          item[property]['name']
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        ) {
          return item;
        }
      });
      break;
    case 'filterList':
      tempQuery = escapeRegExp(query);
      re = new RegExp(tempQuery, 'i');
      filteredArray = filter(data, item => {
        if (
          !filterPartyType ||
          item.relationType === filterPartyType ||
          item.accountGroupName === filterPartyType
        )
          if (
            re.test(item.name) ||
            re.test(item.aliasName) ||
            re.test(item.address) ||
            re.test(item.city) ||
            re.test(item.state)
          ) {
            return item;
          }
      });
      break;
    case 'contactList':
      tempQuery = escapeRegExp(query);
      re = new RegExp(tempQuery, 'i');

      filteredArray = filter(data, item => {
        if (re.test(item.name) || re.test(item.phone)) {
          return item;
        }
      });
      break;

    default:
      filteredArray = filter(data, item => {
        if (
          item[property] &&
          item[property]
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        ) {
          return item;
        }
      });
  }

  return filteredArray;
}

export function arrayToObject(array) {
  const obj = {};
  map(array, (item, index) => (obj[index] = item));
  return obj;
}

export const formatDate = date =>
  JSON.stringify(date)
    .replace('"', '')
    .replace('"', '');

export const getFormattedDate = date => {
  return moment(date).format('DD/MM/YYYY');
};

export const getFormattedDate2 = date => {
  return moment(date).format('DD-MM-YY');
};

export const getFormattedDate3 = date => {
  return moment(date).format('YYYY-MM-DD');
};

export const getFormattedTime = date => {
  return moment(date).format('H:mm A');
};

export const getFormattedDateIso = date => {
  return moment(date).toISOString();
};

export const getFormattedDateWithTime = (date, time) => {
  return moment(
    getFormattedDate3(date) + ' ' + moment(time).format('LTS'),
    'YYYY-MM-DD HH:mm:ss A'
  );
};

export const getStartOfFinancialYear = refDate => {
  const START_FINANCIAL_APRIL_MONTH = 3;
  const START_DAY = 1;
  let month = moment(refDate).get('month');
  let year = moment(refDate).get('year');
  if (month < START_FINANCIAL_APRIL_MONTH) {
    year = year - 1;
  }
  const startDate = moment([year, START_FINANCIAL_APRIL_MONTH, START_DAY]);
  return moment(getStartTimeOfDay(startDate));
};

export const getEndOfFinancialYear = refDate => {
  const END_FINANCIAL_MARCH_MONTH = 2;
  const END_DAY = 31;
  let month = moment(refDate).get('month');
  let year = moment(refDate).get('year');
  if (month > END_FINANCIAL_MARCH_MONTH) {
    year = year + 1;
  }
  const endDate = moment([year, END_FINANCIAL_MARCH_MONTH, END_DAY]);
  return moment(getEndTimeOfDay(endDate));
};

export const getStartTimeOfDay = date => {
  return getFormattedDateIso(moment(date).startOf('day'));
};

export const getEndTimeOfDay = date => {
  return getFormattedDateIso(moment(date).endOf('day'));
};

export const getStartOfRange = (range, date) => {
  return moment(date).startOf(range);
};

export const getEndOfRange = (range, date) => {
  return moment(date).endOf(range);
};

/**
 * When logging out of User completely, we will use this
 */
export function clearAllStorage() {
  localStorage.clear(); // user token
  sessionStorage.clear(); // company token
  setTimeout(() => {
    window.location.reload();
  }, 200);
}

/**
 * When logging out of a company, we will use this
 */
export function clearCompanyStorage() {
  sessionStorage.clear(); // company token
  setTimeout(() => {
    window.location.reload();
  }, 200);
}
/**
 * capitalize first letter of a string
 */
export function firstLetterCapital(str) {
  return (
    str &&
    str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    })
  );
}

export function capitalizeFirstLetter(str) {
  return str && str.replace(/\b([a-z])/g, substr => substr.toUpperCase());
}

export function isAlphaNumeric(str) {
  const regex = /^[a-z0-9 ]+$/i;
  return str === '' || regex.test(str);
}

export function isNumeric(str) {
  const regex = /^[0-9]+$/i;
  return str === '' || regex.test(str);
}

export function onlyOperatorsAndNumbers(str) {
  const regex = /^[0-9()--+*/.]+$/i;
  return str === '' || regex.test(str);
}

export function isValidUrlString(str) {
  const regex = /^[a-z0-9-_]+$/i;
  return str === '' || regex.test(str);
}

export function detectPhoneEmailOrName(value) {
  const emailReg = /@/g;
  if (isNumeric(value)) {
    return 'phone';
  } else if (emailReg.test(value)) {
    return 'email';
  } else {
    return 'name';
  }
}

export function arrayToQueryString(array) {
  let string = reduce(
    array,
    function(result, value, key) {
      if (result) result += ',';
      result += '"' + value + '"';
      return result;
    },
    ''
  );
  return '[' + string + ']';
}

export const getGeocodeByAddress = postalCode => {
  // if (postalCode !== '') {
  return geocodeByAddress(postalCode.toString()).then(places => {
    let address = places[0].address_components.reverse();
    const geocode = {
      lat: places[0].geometry.location.lat(),
      lng: places[0].geometry.location.lng()
    };
    // let filteredData = [];
    let filteredData = reduce(
      areaPriority,
      (result, area) => {
        let temp = filter(address, item => item.types[0] === area)[0];
        temp && result.push(temp);
        return result;
      },
      []
    );
    if (filteredData)
      return {
        city: filteredData[2].short_name,
        state: filteredData[1].long_name,
        country: filteredData[0].long_name,
        geoLoc: geocode
      };
  });
  // }
};

export const getAcronym = name => {
  if (!name) return '';
  let matches = name.match(/\b(\w)/g);
  return matches ? matches.slice(0, 2).join('') : name[0];
};

export function roundAmount(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function escapeRegExp(strToEscape) {
  return strToEscape.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function nFormatter(num, digits = 1) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e5, symbol: 'l' },
    { value: 1e7, symbol: 'crore' }
    // { value: 1e12, symbol: 'T' },
    // { value: 1e15, symbol: 'P' },
    // { value: 1e18, symbol: 'E' }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function createExcelSheet(data, name) {
  /* convert AOA to worksheet */
  const ws = XLSX.utils.aoa_to_sheet(data);
  // const ws = XLSX.utils.json_to_sheet(list);

  /* build new workbook */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

  // /* write file */
  // const wbout = XLSX.write(wb, {type:'binary', bookType:"xlsx"});
  // const file = DDP + "sheetjsw.xlsx";
  XLSX.writeFile(wb, name + '.xlsx');
}

export const decimalField = (newValue, callback) => {
  !isNaN(newValue) && callback(newValue === '' ? 0 : newValue);
};

export const addressExists = address => {
  if (address.city || address.state || address.address || address.pincode) return true;
  else return false;
};

export const parseError = error => {
  if (isArray(error)) {
    return error[0] ? error[0].message : '';
  } else {
    return error.message || error;
  }
};

export function parseQuery(queryString) {
  return new URLSearchParams(queryString);
}

export const getSearchParamsValue = key => {
  const url = new window.URL(window.location.href);
  const searchParams = parseQuery(url.searchParams);
  return searchParams.get(key);
};

export const setSearchPrarms = (key, value) => {
  const url = new window.URL(window.location.href);
  const searchParams = parseQuery(url.searchParams);
  searchParams.set(key, value);
  window.history.replaceState({}, '', `${url.pathname}?${searchParams}`);
};

export const getEstimateContainerWidth = toggleFlex =>
  toggleFlex ? window.innerWidth - 20 - 60 - 60 : window.innerWidth * 0.8 - 20 - 60 - 60; //0.8 is the percentage width of the right container, padding/margin is subtracted from the innerWidth, mentioned each seperately for understanding

export const removeDuplicates = (list, comparisonList, comparisonKey) => {
  return filter(list, item => {
    let res = find(comparisonList, compItem => {
      return compItem[comparisonKey] === item[comparisonKey];
    });
    return !res;
  });
};

export const getItemGroupTableId = groupId => `itemGroupTable_${groupId}`;

export const getImageUrl = image =>
  image && image.url
    ? image.fileType && image.fileType.includes('image')
      ? image.url
      : FileIcon
    : noImageThumb;

export const getImageUrlFromList = (imagesList = []) => {
  let url = noImageThumb;
  for (const image of imagesList) {
    url = getImageUrl(image);
    if (url !== noImageThumb) return url;
  }
  return url;
};

export const getPageFromPath = path => split(path, '/')[3];

export function makeRandomId(length) {
  //generates a random string of specified length
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getVoucherTransactionIsDebit = (transactions, accountId) => {
  const transaction = find(transactions, transaction => transaction.refAccountId === accountId);
  let flag = true;
  if (transaction) {
    if (transaction.debitAmount === 0) {
      flag = false;
    }
  }
  return flag;
};

export const ifPreventVoucherEditing = voucherData => {
  let flag = false;
  const transaction = filter(
    voucherData.transactions,
    trans => trans.refPath === SUNDRY_CREDITOR_PATH || trans.refPath === SUNDRY_DEBITOR_PATH
  );
  flag = transaction[0]
    ? transaction[0].reconcileByIUser
      ? transaction[0].reconcileByIUser.status === RECONCILED
      : false
    : false;
  return flag;
};
export const getStickyTableHeader = (containerWidth, toggleFlex) => {
  const mult = containerWidth * TABLE_COL_WIDTH_MULT;
  const col = toggleFlex
    ? [
        {
          columnName: 'Created At',
          width: containerWidth ? mult * 4 : 105
        },
        {
          columnName: 'Issue Date',
          width: containerWidth ? mult * 4 : 105
        },
        {
          columnName: 'Voucher No',
          width: containerWidth ? mult * 5 : 120
        },
        {
          columnName: 'Supplier Ref',
          width: containerWidth ? mult * 5 : 115
        },
        {
          columnName: 'Account Name',
          width: containerWidth ? mult * 8 : 105
        },
        {
          columnName: 'Narration',
          width: containerWidth ? mult * 9 : 105
        },
        {
          columnName: 'Debit',
          width: containerWidth ? mult * 4 : 105
        },
        {
          columnName: 'Credit',
          width: containerWidth ? mult * 5 : 105
        },
        {
          columnName: 'Balance',
          width: containerWidth ? mult * 4 : 115
        }
      ]
    : [
        {
          columnName: 'Issue Date',
          width: containerWidth ? mult * 5 : 105
        },
        {
          columnName: 'Voucher No',
          width: containerWidth ? mult * 6 : 120
        },
        {
          columnName: 'Account Name',
          width: containerWidth ? mult * 19 : 105
        },
        {
          columnName: 'Debit',
          width: containerWidth ? mult * 6 : 105
        },
        {
          columnName: 'Credit',
          width: containerWidth ? mult * 6 : 105
        },
        {
          columnName: 'Balance',
          width: containerWidth ? mult * 4 : 115
        }
      ];
  return col;
};

export function isAttributeInGroupConfig(selectedItemGroups, attributeId) {
  if (!selectedItemGroups || selectedItemGroups.length < 1) {
    return true;
  } else {
    for (const group of selectedItemGroups) {
      if (group.attributeConfig && group.attributeConfig.includes(attributeId)) {
        return true;
      }
    }
    return false;
  }
}

export function getRandomColor() {
  return tagBgColors[random(0, 6, false)];
}
export const getVoucherPublicShareMsg = (companyName, link, voucherNo, Total, type) => {
  return link;
  //  Voucher Details:
  //  Voucher No: ${voucherNo},
  //  Total Amount: ${Total}`;
};

export const getStatementPublicShareMsg = (companyName, link) => {
  return `${companyName} has shared Account Statement with you. To view details of shared Account Statement click on below link. 
  ${link}`;
};

export const handleWatsAppShare = msg => {
  console.log(msg);
  window.open(`https://web.whatsapp.com/send?text=${msg}`, '_blank').focus();
};

export const handleApiWatsAppShare = (msg, phone) => {
  window.location = phone
    ? `https://api.whatsapp.com/send?phone=+91${phone}&text=${msg}`
    : `https://api.whatsapp.com/send?text=${msg}`;
  // window.open(`https://api.whatsapp.com/send?text=${msg}`, '_blank').focus();
};

export const getCatalogueShareMsg = link => {
  return `Hello, please select items from this link and reply us back.
  ${link}`;
};

export const getNewWayPrintingButtonAccess = voucherType => {
  const flag =
    purchaseTypeFlag(voucherType) ||
    salesTypeFlag(voucherType) ||
    voucherType === VOUCHER_TYPE_CREDIT_NOTE ||
    voucherType === VOUCHER_TYPE_DEBIT_NOTE;
  return flag;
};

// SKU Print Only Needed On
// All Purchase & Sales Types: Purchase, Sales, Estimate, Challan,
// CR, DR
// Stock Adjustment

export const getSKUPrintButtonAccess = voucherType => {
  const flag =
    purchaseTypeFlag(voucherType) ||
    salesTypeFlag(voucherType) ||
    voucherType === VOUCHER_TYPE_CREDIT_NOTE ||
    voucherType === VOUCHER_TYPE_DEBIT_NOTE ||
    voucherType === VOUCHER_TYPE_STOCK_ADJUSTMENT;
  return flag;
};

// Transportation Print For
// All Purchase & Sales Types: Purchase, Sales, Estimate, Challan

export const getTransportLabelPrintButtonAccess = voucherType => {
  const flag = purchaseTypeFlag(voucherType) || salesTypeFlag(voucherType);
  return flag;
};

export const extractIdsFromSelection = (selectedIds = [], tableId = DEFAULT_TABLE_ID) => {
  const ids = selectedIds[tableId];
  return reduce(
    ids,
    (idList, currentPage) => {
      const currentIds = reduce(
        currentPage,
        (tempIds, item) => {
          tempIds.push(item.id);
          return tempIds;
        },
        []
      );
      idList = idList.concat(currentIds);
      return idList;
    },
    []
  );
};

export const getFormattedAmount = (value, decimalPlaces) => {
  return `Rs. ${getfloatingValue(value, decimalPlaces, true)} ${
    value < 0 ? CREDIT_SHORT : DEBIT_SHORT
  }`;
};
export function handleVoucherFormClose(
  mode,
  closeAndResetVoucherFormState,
  voucherType,
  onBack,
  toggleAddEditDrawer
) {
  if (mode === EDIT) {
    closeAndResetVoucherFormState(voucherType);
    onBack && onBack();
  } else {
    toggleAddEditDrawer(voucherType, false);
  }
}

export function getSortcutKeyForOS() {
  const { os } = browserDetails();
  if (os === 'MacOS') {
    return 'option';
  } else {
    return 'alt';
  }
}

export const setFocus = id => {
  const picker = document.getElementById(id);
  picker && picker.focus();
};

export const validatePhone = value => {
  var re = /^[0-9]{10}$/;
  return re.test(value);
};

export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = name => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = name => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const getReceiptPaymentPayload = ({
  billFinalAmount,
  issueDate,
  selectedVoucherAccount,
  transaction,
  subType = 'account',
  paidAmount,
  documents = [],
  narration = '',
  voucherList = [],
  accountList = [],
  supplierInvoiceDate,
  supplierInvoiceNo
}) => {
  return {
    billFinalAmount,
    internalNotes: '',
    issueDate,
    verifiedBy: [],
    // voucherNo: '',
    /// _selectedVoucherAccount,
    supplierInvoiceNo,
    supplierInvoiceDate,
    refAccountName: selectedVoucherAccount.refAccountName,
    accountGroupName: selectedVoucherAccount.accountGroupName,
    refPath: selectedVoucherAccount.refPath,
    refAccountId: selectedVoucherAccount.refAccountId,
    supplierInvoiceNo: '',
    supplierInvoiceDate: issueDate,
    transaction,
    subType,
    paidAmount,
    documents,
    narration,
    voucherList,
    accountList
  };
};

export const verifyCachedApi = (url, list = []) => {
  let flag = false;
  let index = 0;
  let cached = false;
  list &&
    list.length > 0 &&
    list.forEach((l, i) => {
      let regExp = new RegExp(`^${l.apiPath}$`);
      if (url.match(regExp)) {
        flag = true;
        index = i;
        if (l.cached) {
          cached = true;
        }
      }
    });
  return { flag, index, cached };
};

// export const checkQueryUrl = url => {
//   const query = url.split('?');
//   if (query && query.length > 0) {
//     return true;
//   }
//   return false;
// };

export const hasAccessToAction = action => {
  const { getState } = store;
  const {
    currentCompany: { roles }
  } = getState();
  const hasAccess = reduce(
    roles,
    (prev, curr) => {
      return prev || curr === BUSINESS_ADMIN;
    },
    false
  );
  return hasAccess;
};

// ITEM CALCULATION FUNCTIONS
export const calculateItemWholesaleRetailPrice = (
  initialPrice,
  markup,
  isAutoRoundOffEnabled,
  roundOffConfig,
  decimalPlaces
) => {
  let newPrice = VoucherCalModule.itemUnitWspRsp.getItemWholesaleRetailPrice(initialPrice, markup);
  if (isNaN(newPrice)) {
    newPrice = 0;
  }
  let roundOff = 0;
  if (isAutoRoundOffEnabled) {
    const roundOffValue = getRoundOffValue(newPrice, roundOffConfig);
    roundOff = roundOffValue - newPrice;
    return { value: roundOffValue, roundOff: getfloatingValue(roundOff, decimalPlaces) };
  }
  return { value: newPrice, roundOff: getfloatingValue(roundOff, decimalPlaces) };
};

export const calculateItemDiscountAmount = (
  initialAmount = 0,
  discountValue = 0,
  unit,
  decimalPlaces = CURRENCY_DECIMAL_ROUND
) => {
  const tempAmount = unit && unit === RUPEES ? discountValue : initialAmount * discountValue * 0.01;
  return getfloatingValue(tempAmount, decimalPlaces);
};

export const calculateItemMarkup = (basePrice, comparePrice) => {
  let markup = VoucherCalModule.itemUnitWspRsp.calculateReverseMarkup(basePrice, comparePrice);
  if (isNaN(markup)) {
    markup = 0;
  }
  return markup;
};

export const calculateItemRetailPrice = ({
  unitPurchasePrice,
  unitSellWholeSalePrice,
  retailMarkupBase,
  retailMarkup,
  decimalPlaces,
  RSPAutoRoundOff,
  roundOffConfig
}) => {
  let newUnitSellRetailPrice = 0;
  let rspAdjustment = 0;
  if (retailMarkupBase === 'unitPurchasePrice') {
    const { value, roundOff } = calculateItemWholesaleRetailPrice(
      getfloatingValue(unitPurchasePrice, decimalPlaces),
      getfloatingValue(retailMarkup, decimalPlaces),
      RSPAutoRoundOff,
      roundOffConfig,
      decimalPlaces
    );

    newUnitSellRetailPrice = value;
    rspAdjustment = roundOff;
  } else if (retailMarkupBase === 'unitSellWholeSalePrice') {
    const { value, roundOff } = calculateItemWholesaleRetailPrice(
      getfloatingValue(unitSellWholeSalePrice, decimalPlaces),
      getfloatingValue(retailMarkup, decimalPlaces),
      RSPAutoRoundOff,
      roundOffConfig,
      decimalPlaces
    );
    newUnitSellRetailPrice = value;
    rspAdjustment = roundOff;
  }
  return { newUnitSellRetailPrice, rspAdjustment };
};

export const calculateItemOnRetailPriceChange = ({
  unitSellRetailPrice,
  unitSellWholeSalePrice,
  retailMarkupBase,
  unitPurchasePrice,
  retailDiscountUnit,
  retailDiscountValue,
  decimalPlaces,
  RSPAutoRoundOff,
  roundOffConfig = [],
  rateCodeConfig = {}
}) => {
  let retailMarkup = 0;
  if (retailMarkupBase === 'unitPurchasePrice') {
    retailMarkup = calculateItemMarkup(unitPurchasePrice, unitSellRetailPrice);
  } else if (retailMarkupBase === 'unitSellWholeSalePrice') {
    retailMarkup = calculateItemMarkup(unitSellWholeSalePrice, unitSellRetailPrice);
  }

  const { rspAdjustment, rateCode, RSPDiscountValue } = calculateOnItemRetailMarkupChange({
    retailMarkup,
    unitSellWholeSalePrice,
    unitPurchasePrice,
    retailMarkupBase,
    retailDiscountValue,
    retailDiscountUnit,
    decimalPlaces,
    RSPAutoRoundOff,
    roundOffConfig,
    rateCodeConfig
  });

  return { rspAdjustment, rateCode, RSPDiscountValue, retailMarkup };
};

export const calculateOnWholesalePriceChange = ({
  unitSellWholeSalePrice,
  unitSellRetailPrice,
  retailMarkup,
  retailMarkupBase,
  unitPurchasePrice,
  wholesaleDiscountValue,
  wholesaleDiscountUnit,
  retailDiscountUnit,
  retailDiscountValue,
  decimalPlaces,
  RSPAutoRoundOff,
  roundOffConfig = [],
  rateCodeConfig = {},
  includeMarkUp = true
}) => {
  const wholesaleMarkup = calculateItemMarkup(unitPurchasePrice, unitSellWholeSalePrice);
  const WSPDiscountValue = calculateItemDiscountAmount(
    unitSellWholeSalePrice,
    wholesaleDiscountValue,
    wholesaleDiscountUnit,
    decimalPlaces
  );
  if (includeMarkUp) {
    const {
      newRetailPrice,
      rspAdjustment,
      rateCode,
      RSPDiscountValue
    } = calculateOnItemRetailMarkupChange({
      retailMarkup,
      unitSellWholeSalePrice,
      unitPurchasePrice,
      retailMarkupBase,
      retailDiscountValue,
      retailDiscountUnit,
      decimalPlaces,
      RSPAutoRoundOff,
      roundOffConfig,
      rateCodeConfig
    });

    return {
      wholesaleMarkup,
      newRetailPrice,
      RSPDiscountValue,
      WSPDiscountValue,
      rspAdjustment,
      rateCode
    };
  }
  const rateCode = getRateCodeValue(unitSellWholeSalePrice, unitSellRetailPrice, rateCodeConfig);
  return { wholesaleMarkup, rateCode, WSPDiscountValue };
};

export const calculateOnItemRetailMarkupChange = ({
  retailMarkup,
  unitSellWholeSalePrice,
  unitPurchasePrice,
  retailMarkupBase,
  retailDiscountValue,
  retailDiscountUnit,
  decimalPlaces,
  RSPAutoRoundOff,
  roundOffConfig,
  rateCodeConfig
}) => {
  const { newUnitSellRetailPrice, rspAdjustment } = calculateItemRetailPrice({
    unitPurchasePrice,
    unitSellWholeSalePrice,
    retailMarkupBase,
    retailMarkup,
    decimalPlaces,
    RSPAutoRoundOff,
    roundOffConfig
  });

  const RSPDiscountValue = calculateItemDiscountAmount(
    newUnitSellRetailPrice,
    retailDiscountValue,
    retailDiscountUnit,
    decimalPlaces
  );
  const rateCode = getRateCodeValue(unitSellWholeSalePrice, newUnitSellRetailPrice, rateCodeConfig);

  return {
    newRetailPrice: newUnitSellRetailPrice,
    rspAdjustment,
    rateCode,
    RSPDiscountValue
  };
};

export const calculateOnItemWholeSaleMarkupChange = ({
  wholesaleMarkup,
  retailMarkup,
  retailMarkupBase,
  unitPurchasePrice,
  wholesaleDiscountValue,
  wholesaleDiscountUnit,
  retailDiscountUnit,
  retailDiscountValue,
  decimalPlaces,
  RSPAutoRoundOff,
  WSPAutoRoundOff,
  roundOffConfig = [],
  rateCodeConfig = {}
}) => {
  const {
    value: unitSellWholeSalePrice,
    roundOff: wspAdjustment
  } = calculateItemWholesaleRetailPrice(
    unitPurchasePrice,
    wholesaleMarkup,
    WSPAutoRoundOff,
    roundOffConfig
  );

  const { newUnitSellRetailPrice, rspAdjustment } = calculateItemRetailPrice({
    unitPurchasePrice,
    unitSellWholeSalePrice,
    retailMarkupBase,
    retailMarkup,
    decimalPlaces,
    RSPAutoRoundOff,
    roundOffConfig
  });

  const WSPDiscountValue = calculateItemDiscountAmount(
    unitSellWholeSalePrice,
    wholesaleDiscountValue,
    wholesaleDiscountUnit,
    decimalPlaces
  );
  const RSPDiscountValue = calculateItemDiscountAmount(
    newUnitSellRetailPrice,
    retailDiscountValue,
    retailDiscountUnit,
    decimalPlaces
  );
  const rateCode = getRateCodeValue(unitSellWholeSalePrice, newUnitSellRetailPrice, rateCodeConfig);

  return {
    newWholesalePrice: unitSellWholeSalePrice,
    newRetailPrice: newUnitSellRetailPrice,
    wspAdjustment,
    rspAdjustment,
    rateCode,
    WSPDiscountValue,
    RSPDiscountValue
  };
};

export const getLineItemTableId = voucherType => {
  return `${voucherType}-line-item-table`;
};

export const getPdfToken = tokenObject => {
  let token = '';
  try {
    token = tokenObject.pdfGeneratorData.tokens.access.token;
  } catch {
    token = '';
  }
};
export const countFilterApllied = appliedFilter => {
  const filter = appliedFilter;
  let counter = 0;
  for (const key in filter) {
    if (typeof filter[key] === 'object' && filter[key] !== null) {
      if (Array.isArray(filter[key])) {
        counter += filter[key].length;
      } else {
        counter += countFilterApllied(filter[key]);
      }
    } else if (
      filter[key] !== '' &&
      filter[key] !== null &&
      filter[key] !== 'both' &&
      filter[key] !== undefined &&
      filter[key] !== false &&
      filter[key] !== 'all'
    ) {
      counter++;
    }
  }
  return counter;
};

export const PrintReportUsingHTML = (
  heading,
  dateRange,
  companyName,
  branches,
  includePercentageBsPl
) => {
  let printAbleHTML = document.getElementById('print-balance').innerHTML;
  let newWindow = window.open();
  let style = `<style>
    body {
      margin-left: 10px;
    }
    .fw-bold{
      font-weight: bold !important;
    }
    tr{
      width:100%;
      height:fit-content;
      text-align: justify;
    }
    th, td, a{
      font-size: 11px !important;
    }
    tr, td{
      height: 0px !important;
    }
    .BSPL-col-left, a{
      width:50px !important;
      white-space: initial;
    }
    .BSPL-col-right{
      width:100px !important;
    }
    .textAlign{
      text-align:center;
      margin:0;
      font-weight: 400;
    }
    .BSPLtable-footer{
      margin:10px;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      font-size: 15px; 
      padding-left: 24px; 
      padding-right: 24px;
      color: #000;
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
    }
    thead{
      border: none !important;
    }
    a{
      text-decoration: none;
      color: #000;
    }
    .printBSPL-table{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    footer {
      font-size: 10px;
      text-align: center;
    } 
    svg, img{
      display: none;
    } 
    .printable-header{
      font-weight: bold;
      font-size: 15px !important;
      border-bottom: 1px solid #000;
      height: 20px !important;
    }
    .sub-printable-header{
      font-weight: bold;
      font-size: 13px !important;
    }
    .sub-group-data{
      border: none !important;
      padding: 0 20px;
    }
    .sub-group-data, .sub-group-data td{
      height: 2px !important;
    }
    .sub-group-data .rs-value{      
      border-bottom: 1px solid #000;
    }
    .printable-sub-item-left{
      padding-left: 40px !important;
    }
    .rs-indicator{
      display: none
    }
    td:nth-child(1) {
      width: 50%;
    }
    td:nth-child(2) {
        width: 50%;
        padding: 0 !important;
        margin: 0 10px 0 0 !important;
    }
    td:nth-child(3) {
      width: ${includePercentageBsPl ? '15%' : 0};
      padding: 0 !important;
      margin: 0 10px 0 0 !important;
      text-align: end !important;
    }
    .sub-printable-header td:nth-child(2) {
      padding: 0 5px 0 0 !important;
    }
    .line-top{
      margin-bottom: 0;
    }
    .printable-sub-item-right,.sub-group-data{
      position: relative;
      right: 70px;
    }
    .percentage-value {
      margin-left: 2.5rem;
    }
    @page {
      size: A4;
      margin: 5mm 5mm 5mm 5mm;
    }
    @media print {
      footer {
        margin: 10px 0;
        position: relative;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
      .header{
        position: fixed;
        top: 0;
      }
      .content-block{
        page-break-inside: auto;
      }
    }
    }
    </style>`;
  const body = `<div>
   <header>
    <h2 class="textAlign fw-bold">${companyName}</h2>
    <h4 class="textAlign">${branches[0].name}</h4>
    <h5 class="textAlign" style="max-width:600px;margin:0 auto; border-bottom: 1px solid #000; padding:5px 0">${
      branches[0].address
    }</h5>
    <h5 class="textAlign fw-bold" style="margin: 7px 0">${heading}</h5>
    <h5 class="textAlign" style="margin: 9px 0">
      ${moment(dateRange.startDate).format('LL')} - ${moment(dateRange.endDate).format('LL')}
    </h5>
   </header>
    <div>
    <div class="content-block">
    <hr class="line-top" />
      ${printAbleHTML}
    </div>
    <footer>
      INVOCK (www.invock.com) - Automated Business Assistant
    </footer>
    </div>
    <script>
      const nodeList = document.querySelectorAll(".rs-value");
      for (let i = 0; i < nodeList.length; i++) {
        if(parseInt(nodeList[i].innerText) !== 0) {
        nodeList[i].innerText = parseFloat(nodeList[i].innerText).toFixed(2).toLocaleString('en-IN',{
        maximumFractionDigits: 2   
        })
        }
        else {
              nodeList[i].parentElement.parentElement.remove();
        }
      }
    </script>
    </div>`;
  newWindow.document.write(body);
  newWindow.document.head.innerHTML = style;
  newWindow.stop();
  newWindow.print();
  newWindow.close();
  return false;
};

export const expandAllAccountGroup = (balanceSheet, toggle) => {
  let data = { ...balanceSheet };
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].map((acc, i) => {
        if (Object.keys(data) && acc.accountGroup !== PROFIT_AND_LOSS_ACC) {
          if (acc.hasOwnProperty('open')) acc.open = toggle;
          data[key] = {
            ...acc
          };
        }
      });
    }
  }
};

export const calcTripingValue = () => {
  return parseInt(
    `${new Date(JSON.parse(sessionStorage.getItem('startDate'))).getFullYear()}` +
      `${new Date(JSON.parse(sessionStorage.getItem('endDate'))).getFullYear()}`
  );
};

export const filterDateDiff = (startDate, endDate) => {
  let stDate = JSON.parse(sessionStorage.getItem('startDate'));
  let enDate = JSON.parse(sessionStorage.getItem('endDate'));
  return (
    dateDiffInDays(getFormattedDate3(enDate), getFormattedDate3(endDate)) ||
    dateDiffInDays(getFormattedDate3(stDate), getFormattedDate3(startDate))
  );
};

export const generateUserBusinessMessage = (url, iUser) => {
  return `
  Hello,
  ${url}
  This is my *INVOCK Business Card*. 

  *The PDF has clickable links where you can get*:
  - Payment Details 
  - My Contact
  - My iShop Digital Store

  Save it on your phone or keep this message starred for future reference.

  *I am loving INVOCK for my business*

  You can also try it at https://bit.ly/invockapp

  Regards,
  ${iUser.name}
`;
};

export const generatePartyBusinessCardMessage = (url, iUser, party) => {
  return `
  *Hello ${party.name}*,
  ${url}
  I have created your *INVOCK Party Card*.
  
  *The PDF has clickable links where you can check*:
  - Account balance 
  - Payment details
  
  Save it on your phone or keep this message starred for future reference.
  
  *I am loving INVOCK for my business*
  
  You can also try it at https://bit.ly/invockapp
  
  Regards,
  ${iUser.name}
  `;
};

export const dynamicSort = (data, sortingCriteria) => {
  return data.sort((a, b) => {
    for (const criterion of sortingCriteria) {
      const { columnName, direction } = criterion;
      const comparison = a[columnName] > b[columnName] ? 1 : a[columnName] < b[columnName] ? -1 : 0;
      if (direction === 'desc') {
        // Reverse the order if descending
        return comparison * -1;
      }
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  });
};

export const looseJsonParse = obj => {
  return Function('"use strict";return (' + obj + ')')();
};

export const convertAmountIntoWords = amount => {
  return convertAmountToWords(amount);
};
