import { companyAuthFetch, extractData, fetch } from './server';
import moment from 'moment';
import { getFormattedDateIso } from '../utils/helperFunctions';

export const fetchContacts = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/item-vendors/page/all`).then(extractData);

export const fetchPartyContacts = (companyId, partyId) =>
  companyAuthFetch.get(`/i-companies/${companyId}/parties/${partyId}/contacts`).then(extractData);

export const fetchPartyStatement = (companyId, partyId, startDate, endDate, branchesString) => {
  let url = `/i-companies/${companyId}/businesses/${partyId}/statement?startDate=${getFormattedDateIso(
    moment(startDate)
  )}&endDate=${getFormattedDateIso(moment(endDate))}`;
  if (branchesString) url += `&branches=${branchesString}`;
  return companyAuthFetch.get(url).then(extractData);
};

export const fetchAccountStatement = (companyId, accountId, startDate, endDate, branchesString) => {
  let url = `/i-companies/${companyId}/accounts/${accountId}/statement?startDate=${getFormattedDateIso(
    moment(startDate)
  )}&endDate=${getFormattedDateIso(moment(endDate))}`;
  if (branchesString) url += `&branches=${branchesString}`;
  return companyAuthFetch.get(url).then(extractData);
};

export const exportAccountStatement = (
  companyId,
  accountId,
  startDate,
  endDate,
  iBranchIds,
  statementType,
  include,
  page,
  selectedSalesPerson
) => {
  let url = `/i-companies/${companyId}/accounts/${accountId}/statement/export?startDate=${getFormattedDateIso(
    moment(startDate)
  )}&endDate=${getFormattedDateIso(
    moment(endDate)
  )}&statementType=${statementType},&page=${page}&include=${include}`;
  if (iBranchIds) url += iBranchIds;

  if (selectedSalesPerson && selectedSalesPerson.id) {
    url += `&salesPersonId=${selectedSalesPerson.id}`;
  }

  return companyAuthFetch.get(url).then(extractData);
};

export const partyStatementPagination = (companyId, partyId, pageNo, query) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/statement/page/${pageNo}?${query}`)
    .then(extractData);
};

export const accountStatementPagination = (companyId, partyId, pageNo, query) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/${partyId}/statement/page/${pageNo}?${query}`)
    .then(extractData);
};

export const addContact = (companyId, payload) => {
  return companyAuthFetch.post(`/i-companies/${companyId}/business-profile`, payload);
};

export const updateContactName = (companyId, phoneId, name) => {
  return companyAuthFetch.put(`/i-companies/${companyId}/contacts/${phoneId}`, {
    name
  });
};

export const checkForName = (companyId, phone) => {
  return companyAuthFetch.get(`/i-companies/${companyId}/contacts/${phone}`);
};

export const getPartyStatementShare = (companyId, accId) => {
  return companyAuthFetch.get(`/i-companies/${companyId}/accounts/${accId}/statement/share-status`);
};

export const getPartyStatementPublicShareStatus = (companyId, accId) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/${accId}/share-status/public/page/1`)
    .then(extractData);
};

export const getPartyEnvelope = (companyId, partyId) => {
  return companyAuthFetch.get(`/i-companies/${companyId}/parties/${partyId}/envelope`);
};

export const printReconcilation = (companyId, partyId, payload) => {
  return companyAuthFetch.post(
    `/i-companies/${companyId}/parties/${partyId}/reconcilation-print`,
    payload
  );
};

export const sendPartyStatement = (companyId, refId, payload) => {
  return companyAuthFetch
    .post(`/i-companies/${companyId}/accounts/${refId}/statement/share `, payload)
    .then(extractData);
};

export const printPartyStatement = (companyId, partyId, query) => {
  return companyAuthFetch.get(
    `/i-companies/${companyId}/accounts/${partyId}/statement-print?${query}`
  );
};

export const updateOpeningBalance = (companyId, partyId, data) => {
  return companyAuthFetch.put(
    `/i-companies/${companyId}/businesses/${partyId}/opening-balance`,
    data
  );
};

export const updatePartyName = (companyId, partyId, data) => {
  return companyAuthFetch.put(`/i-companies/${companyId}/businesses/${partyId}/name`, data);
};

export const fetchPartyProfile = (companyId, partyId) =>
  companyAuthFetch.get(`/i-companies/${companyId}/party-profile/${partyId}`).then(extractData);

// export const updateContact = (companyId, partyId, contactId, data) => {
//   return companyAuthFetch.put(
//     `/i-companies/${companyId}/businesses/${partyId}/contacts/${contactId}`,
//     data
//   );
// };

export const updateContact = (companyId, contactId, data) => {
  return companyAuthFetch.put(`/i-companies/${companyId}/contacts/${contactId}`, data);
};

export const addPartyContact = (companyId, partyId, data) => {
  return companyAuthFetch.post(`/i-companies/${companyId}/businesses/${partyId}/contacts`, data);
};

export const replacePrimaryContact = (companyId, partyId, data) => {
  return companyAuthFetch.put(
    `/i-companies/${companyId}/businesses/${partyId}/contacts/replace-primary`,
    data
  );
};

export const markContactAsPrimary = (companyId, partyId, contactId) => {
  return companyAuthFetch.put(
    `/i-companies/${companyId}/businesses/${partyId}/contacts/${contactId}/primary-contact`
  );
};

export const deleteContact = (companyId, partyId, contactId) => {
  return companyAuthFetch.delete(
    `/i-companies/${companyId}/businesses/${partyId}/contacts/${contactId}`
  );
};

export const deleteParty = (companyId, partyId) => {
  return companyAuthFetch.delete(`/i-companies/${companyId}/businesses/${partyId}/hide`);
};

export const deleteAccount = (companyId, accId) => {
  return companyAuthFetch.delete(`/i-companies/${companyId}/accounts/${accId}/soft`);
};

export const updateBillingDetails = (companyId, partyId, data) => {
  return companyAuthFetch.put(
    `/i-companies/${companyId}/businesses/${partyId}/billing-details`,
    data
  );
};

export const updateShippingAddr = (companyId, partyId, shippingAddrId, data) => {
  return companyAuthFetch.put(
    `/i-companies/${companyId}/businesses/${partyId}/shipping-address/${shippingAddrId}`,
    data
  );
};

export const addShippingAddr = (companyId, partyId, data) => {
  return companyAuthFetch.post(
    `/i-companies/${companyId}/businesses/${partyId}/shipping-address`,
    data
  );
};

export const deleteShippingAddr = (companyId, partyId, shippingAddrId) => {
  return companyAuthFetch.delete(
    `/i-companies/${companyId}/businesses/${partyId}/shipping-address/${shippingAddrId}`
  );
};

export const updateBankDetail = (companyId, partyId, bankDetailId, data) => {
  return companyAuthFetch.put(
    `/i-companies/${companyId}/businesses/${partyId}/bank-details/${bankDetailId}`,
    data
  );
};

export const addBankDetail = (companyId, partyId, data) => {
  return companyAuthFetch.post(
    `/i-companies/${companyId}/businesses/${partyId}/bank-details`,
    data
  );
};

export const deleteBankDetail = (companyId, partyId, bankDetailId) => {
  return companyAuthFetch.delete(
    `/i-companies/${companyId}/businesses/${partyId}/bank-details/${bankDetailId}`
  );
};

export const updateSalesDiscounts = (companyId, partyId, data) => {
  return companyAuthFetch
    .post(`/i-companies/${companyId}/businesses/${partyId}/sales-discount`, data)
    .then(extractData);
};

export const updatePurchaseDiscounts = (companyId, partyId, data) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/businesses/${partyId}/purchase-discount`, data)
    .then(extractData);

export const updateCreditDays = (companyId, partyId, data) => {
  return companyAuthFetch
    .put(`/i-companies/${companyId}/business/${partyId}/update-credit-days`, data)
    .then(extractData);
};

export const addPartyTransporter = (companyId, partyId, data) => {
  return companyAuthFetch.put(`/i-companies/${companyId}/businesses/${partyId}/transporters`, data);
};

export const deletePartyTransporter = (companyId, partyId, transporterId) => {
  return companyAuthFetch.delete(
    `/i-companies/${companyId}/businesses/${partyId}/transporters/${transporterId}`
  );
};

export const fetchVouchersSummary = (companyId, partyId, startDate, endDate) => {
  let url = `/i-companies/${companyId}/party/${partyId}/vouchers/types?startDate=${getFormattedDateIso(
    moment(startDate)
  )}&endDate=${getFormattedDateIso(moment(endDate))}`;
  return companyAuthFetch.get(url).then(extractData);
};

export const fetchUnreconciledVouchers = (companyId, partyId, pageNo, query) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/${partyId}/statement/page/${pageNo}?${query}`)
    .then(extractData);
};

export const fetchIfscCode = (companyId, ifscCode) => {
  return companyAuthFetch.get(`/i-companies/${companyId}/ifsc/${ifscCode}`);
};

export const fetchContact = (companyId, contactId) => {
  return companyAuthFetch.get(`/i-companies/${companyId}/contacts/${contactId}`);
};

export const fetchPartyById = (companyId, partyId) => {
  return companyAuthFetch.get(`/i-companies/${companyId}/businesses/${partyId}`);
};

export const reconcileVouchers = (companyId, data, accId) => {
  return companyAuthFetch.put(`/i-companies/${companyId}/accounts/${accId}/reconcile-mode`, data);
};

export const fetchPartyRelationTypes = () => {
  return companyAuthFetch.get(`/configurations/party-relation-type`).then(extractData);
};

export const fetchPartyRefAccount = (companyId, partyId) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/account`)
    .then(extractData);
};

export const fetchTransporterParties = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/businesses/transporter-party`).then(extractData);

// export const fetchReconciledVouchersGroupByDate = (companyId, partyId) => {
//   return companyAuthFetch
//     .get(`/i-companies/${companyId}/parties/${partyId}/reconciled-transactions-group-by-date`)
//     .then(extractData);
// };

export const fetchGstnDetails = gstn =>
  fetch.get(`/configurations/gsint-details/${gstn}`).then(extractData);

export const fetchPartySalesDiscount = (companyId, partyId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/party-sales-discount`)
    .then(extractData);

export const fetchPartyPurchaseDiscount = (companyId, partyId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/party-purchase-discount`)
    .then(extractData);

export const fetchPartyDesignCodes = (companyId, partyId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/items/party-design-codes`)
    .then(extractData);

export const handlePreviewReconciledPrint = (companyId, partyId, date) =>
  companyAuthFetch.get(
    `/i-companies/${companyId}/parties/${partyId}/reconciled-print?reconciledDate=${date}`
  );

export const handlePreviewUnreconciledPrint = (companyId, partyId) =>
  companyAuthFetch.get(`/i-companies/${companyId}/parties/${partyId}/unreconciled-print`);

//accounts related
export const handleAccountPreviewReconciledPrint = (companyId, accountId, date) =>
  companyAuthFetch.get(
    `/i-companies/${companyId}/accounts/${accountId}/statement/reconciled-print?reconciledDate=${date}`
  );

export const handleAccountPreviewUnreconciledPrint = (companyId, accountId) =>
  companyAuthFetch.get(
    `/i-companies/${companyId}/accounts/${accountId}/statement/unreconciled-print`
  );

export const fetchPartyCard = (companyId, partyId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/billing-and-contact`)
    .then(extractData);

export const fetchAccountReconciledVouchersGroupByDate = (companyId, accountId, pageNo) => {
  return companyAuthFetch
    .get(
      `/i-companies/${companyId}/accounts/${accountId}/vouchers/reconciled-by-date/page/${pageNo}`
    )
    .then(extractData);
};

export const fetchAccountUnreconciledVouchers = (companyId, accountId) => {
  return companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/${accountId}/statement/unreconcile`)
    .then(extractData);
};

export const accountCurrentBalance = (companyId, accountId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/${accountId}/balance?${query}`)
    .then(extractData);

export const partySearch = (companyId, pageNo, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/page/${pageNo}?${query}`)
    .then(extractData);

export const partySearchViaContact = (companyId, pageNo, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/parties/contacts/page/${pageNo}?${query}`)
    .then(extractData);

export const ajTallyPartySearch = (companyId, pageNo, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/search/page/${pageNo}/ajand-tally-party?${query}`)
    .then(extractData);

export const fetchAjTallyParty = (companyId, partyId, type = 'aj') =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/${partyId}/ajand-tally-party?companyType=${type}`)
    .then(extractData);

export const fetchAjTallyBranch = (companyId, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/businesses/search/aj-and-tally-branch?${query}`)
    .then(extractData);

export const contactSearch = (companyId, pageNo, query) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/contacts/page/${pageNo}?${query}`)
    .then(extractData);

export const setContactImage = (companyId, contactId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/contacts/${contactId}/image`, payload)
    .then(extractData);

export const updatePartyInternalNotes = (companyId, partyId, payload) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/party/${partyId}/internal-note`, payload)
    .then(extractData);

export const addInternalNotes = (companyId, key, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/internal-note?include=${key}`, payload)
    .then(extractData);

export const fetchInternalNotes = (companyId, refId, page) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/internal-note/${refId}/page/${page}`)
    .then(extractData);

export const updateInternalNotes = (companyId, id, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/internal-notes/${id}`, payload).then(extractData);

export const deleteInternalNotes = (companyId, id) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/internal-notes/${id}`).then(extractData);

export const getPartyTags = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/party-tags`).then(extractData);

export const postPartyTag = (companyId, payload) =>
  companyAuthFetch.post(`/i-companies/${companyId}/party-tags`, payload).then(extractData);

export const updatePartyTag = (companyId, tagId, payload) =>
  companyAuthFetch.put(`/i-companies/${companyId}/party-tags/${tagId}`, payload).then(extractData);

export const deletePartyTag = (companyId, tagId) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/party-tags/${tagId}`).then(extractData);

export const addTagsToParties = (companyId, partyIds, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/business/tags?partyIds=${partyIds}`, payload)
    .then(extractData);

export const removeTagsFromParty = (companyId, query) =>
  companyAuthFetch.delete(`/i-companies/${companyId}/business/tags${query}`).then(extractData);

export const exportParties = companyId =>
  companyAuthFetch.get(`/i-companies/${companyId}/parties/export`).then(extractData);

export const importParties = (companyId, url) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/parties/import?url=${url}`, {})
    .then(extractData);

export const importItems = (companyId, url) =>
  companyAuthFetch.post(`/i-companies/${companyId}/items/import?url=${url}`, {}).then(extractData);

export const updateItemViaImport = (companyId, url) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/update-items/import?url=${url}`, {})
    .then(extractData);

export const importItemsOpeningStock = (companyId, url) =>
  companyAuthFetch
    .put(`/i-companies/${companyId}/items/opening-stock/import?url=${url}`, {})
    .then(extractData);

// fetch party business card
export const fetchPartyBusinessPublicShareStatus = (companyId, accId) =>
  companyAuthFetch
    .get(`/i-companies/${companyId}/accounts/${accId}/share-status/business-card/public/page/1`)
    .then(extractData);

export const quickPartyCreate = (companyId, payload) =>
  companyAuthFetch
    .post(`/i-companies/${companyId}/business-profile/quick`, payload)
    .then(extractData);

export const getAccountInterestStatement = (companyId, accountId, pageNo, query) =>
  companyAuthFetch
    .get(
      `/i-companies/${companyId}/accounts/${accountId}/interest-statement/page/${pageNo}?${query}`
    )
    .then(extractData);
