import { DEFAULT_TABLE_ID } from '../constants';
import {
  TABLE_SELECT,
  SELECT_RESET,
  SELECT_RESET_COLUMN_VISIBILITY,
  SELECT_HIDDEN_COLUMN,
  SELECT_COLUMN_WIDTH,
  RESET_COLUMN_WIDTH,
  RESET_SORTING_COLUMN,
  SET_SORTING_COLUMN,
  SET_THUMBNAIL_VIEW_SELECTION,
  SET_EXPANDED_ROW_IDS,
  TOGGLE_ROW_SELECT,
  TOGGLE_SELECT_ALL,
  RESET_TABLE_SELECTION
} from './types';

export const setSortingColumn = (value, tableListType) => {
  return {
    type: SET_SORTING_COLUMN,
    payload: value,
    tableListType
  };
};

export const resetSortingColumn = () => {
  return {
    type: RESET_SORTING_COLUMN
  };
};

/**
 *
 * @param {array} rowsData
 * @param {array} selectedIndex
 * @param {string} tableId
 * @param {numeric || string} pageNo
 */

export const selectItem = (rowsData, tableId) => {
  return {
    type: TABLE_SELECT,
    key: tableId,
    payload: rowsData
  };
};

export const setSelectedRowIds = (
  rowsData,
  selectedIndex,
  tableId,
  pageNo,
  persistSelectionOnFilterChange
) => {
  return {
    type: 'SET_SELECTED_ROW_IDS',
    tableId,
    rowsData,
    selectedIndex,
    pageNo,
    persistSelectionOnFilterChange
  };
};

export const toggleTableRowSelect = (row, tableKey, flag, rowId) => {
  return {
    type: TOGGLE_ROW_SELECT,
    row,
    tableKey,
    rowId,
    flag
  };
};

export const toggleSelectAll = (rows, tableKey) => {
  return {
    type: TOGGLE_SELECT_ALL,
    rows,
    tableKey
  };
};

export const setExpandedRowIds = (rowIds, tableId) => {
  return {
    type: SET_EXPANDED_ROW_IDS,
    rowIds,
    tableId
  };
};

export const setThumbnailSelection = (
  key,
  row,
  pageNo,
  index,
  flag,
  persistSelectionOnFilterChange = true
) => {
  return {
    type: SET_THUMBNAIL_VIEW_SELECTION,
    key,
    pageNo,
    row,
    index,
    flag,
    persistSelectionOnFilterChange
  };
};

export const resetSelectedRowIds = () => {
  return {
    type: 'RESET_SELECTED_ROW_IDS'
  };
};

export const resetTableSelection = tableKey => {
  return {
    type: RESET_TABLE_SELECTION,
    tableKey
  };
};

///////////////////////////////////////////////////

export const selectionReset = key => {
  return {
    type: SELECT_RESET,
    key
  };
};

export const selectHiddenColumn = (value, key = DEFAULT_TABLE_ID) => {
  return {
    type: SELECT_HIDDEN_COLUMN,
    payload: value,
    key
  };
};

export const hiddenColumnReset = (key = DEFAULT_TABLE_ID) => {
  return {
    type: SELECT_RESET_COLUMN_VISIBILITY,
    key
  };
};

export const selectColumnWidth = (value, view) => {
  return {
    type: SELECT_COLUMN_WIDTH,
    payload: { value, view }
  };
};

export const resetColumnWidth = () => {
  return {
    type: RESET_COLUMN_WIDTH
  };
};
