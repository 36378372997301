import { companyAuthFetch, extractData, fetch } from './server';
import * as CONSTANTS from '../constants';

/**
 *
 * @param {string} companyId
 * @param {string} voucherType
 * To get user templates for specified voucher types
 */
export const getUserTemplates = (companyId, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/invock-pdf-templates${query}`);

/**
 *
 * @param {string} companyId
 * @param {string} voucherType
 * To generate default template code
 */
export const generateDefaultTemplateCode = (companyId, query) =>
  companyAuthFetch.post(
    `/i-companies/${companyId}/create-default-invock-pdf-templates${query}`,
    {}
  );

export const printPdf = (companyId, voucherId, code, query) =>
  companyAuthFetch.get(`/i-companies/${companyId}/vouchers/${voucherId}/pdf/${code}${query}`);
