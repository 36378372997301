import {
  FETCH_CONVERSATION_CHANNEL_LIST,
  SET_SELECTED_CHANNEL,
  MARKETING_API_REQUEST,
  MARKETING_API_SUCCESS,
  FETCH_CHANNEL_CONVERSATION,
  POST_CHANNEL_CONVERSATION_REQUEST,
  FETCH_CATALOGUE_ITEM_GROUPS_REQUESTED,
  FETCH_CATALOGUE_ITEM_GROUPS_SUCCESS,
  FETCH_CATALOGUE_ITEM_GROUPS_FAILED,
  FETCH_CATALOGUE_TAGS_REQUESTED,
  FETCH_CATALOGUE_TAGS_SUCCESS,
  FETCH_CATALOGUE_TAGS_FAILED
} from '../actions/types';

const initialState = {
  loading: false,
  postConversationRequestStatus: false,
  conversationChannels: [],
  selectedChannel: {},
  currentConversation: [],
  catalogueItemGroups: {
    loading: false,
    list: [],
    totalDataCount: 0,
    totalPages: 0
  },
  catalogueTags: {
    loading: false,
    list: [],
    totalDataCount: 0,
    totalPages: 0
  }
};

export const marketing = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONVERSATION_CHANNEL_LIST:
      return {
        ...state,
        conversationChannels: action.payload
      };
    case SET_SELECTED_CHANNEL:
      return {
        ...state,
        selectedChannel: action.payload
      };
    case FETCH_CHANNEL_CONVERSATION:
      return {
        ...state,
        currentConversation: action.payload.filter(l => l.contentType.toLowerCase() === 'item')
      };
    case MARKETING_API_REQUEST:
      return {
        ...state,
        loading: true
      };
    case MARKETING_API_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case POST_CHANNEL_CONVERSATION_REQUEST:
      return {
        ...state,
        postConversationRequestStatus: true
      };
    case FETCH_CATALOGUE_ITEM_GROUPS_REQUESTED:
      return {
        ...state,
        catalogueItemGroups: {
          ...state.catalogueItemGroups,
          loading: true
        }
      };
    case FETCH_CATALOGUE_ITEM_GROUPS_SUCCESS:
      return {
        ...state,
        catalogueItemGroups: {
          ...state.catalogueItemGroups,
          loading: false,
          list:
            action.page === 1
              ? action.payload.data
              : [...state.catalogueItemGroups.list, ...action.payload.data],
          page: action.page,
          totalDataCount: action.payload.totalDataCount,
          totalPages: action.payload.totalPages
        }
      };
    case FETCH_CATALOGUE_ITEM_GROUPS_FAILED:
      return {
        ...state,
        catalogueItemGroups: {
          ...state.catalogueItemGroups,
          loading: false,
          list: []
        }
      };
    case FETCH_CATALOGUE_TAGS_REQUESTED:
      return {
        ...state,
        catalogueTags: {
          ...state.catalogueTags,
          loading: true
        }
      };
    case FETCH_CATALOGUE_TAGS_SUCCESS:
      return {
        ...state,
        catalogueTags: {
          ...state.catalogueTags,
          loading: false,
          list:
            action.page === 1 || action.page === 'all'
              ? action.payload.data
              : [...state.catalogueTags.list, ...action.payload.data],
          page: action.page,
          totalDataCount: action.payload.totalDataCount,
          totalPages: action.payload.totalPages
        }
      };
    case FETCH_CATALOGUE_TAGS_FAILED:
      return {
        ...state,
        catalogueTags: {
          ...state.catalogueTags,
          loading: false,
          list: []
        }
      };
    default:
      return state;
  }
};
