import { IMAGE_ZOOM_BOX_OPEN, IMAGE_ZOOM_BOX_CLOSE } from '../actions/types';

const initialState = {
  images: [],
  open: false
};

export const imageZoomBox = (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_ZOOM_BOX_OPEN:
      return {
        open: true,
        images: action.payload
      };

    case IMAGE_ZOOM_BOX_CLOSE:
      return {
        open: false,
        images: []
      };
    default:
      return state;
  }
};
