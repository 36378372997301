import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemeDefaultValues } from '../ThemeProvider';
export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderItem = styled.div`
  font-weight: 600;
  font-family: ${ThemeDefaultValues.baseFontFamily};
  font-size: ${props => (props.type ? '22px' : '18px')};
  color: ${props => (props.type ? '#ffffff' : '#d5f0eb')};
  cursor: ${props => (props.type ? 'default' : 'pointer')};
`;

export const LoaderContainer = styled(FlexCenter)`
  width: 100%;
  height: 60vh;
`;

export const Button = styled.div`
  //changed to div from Link as we are not changing URL here so link is not required
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${props => (props.height ? props.height : '40px')};
  width: ${props => (props.width ? props.width : 'auto')};
  color: #ffffff;
  cursor: pointer;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '100px')};
  text-align: center;
  text-decoration: none;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#25D366')};
  border: 1px solid #25d366;
  padding: 0 1.5rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-top: ${props => (props.margintop ? props.margintop : '30px')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
`;

export const UploadButton = styled(props => <Link {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.height ? props.height : '62px')};
  width: ${props => (props.width ? props.width : '77px')};
  color: #888888;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  background-color: rgba(181, 181, 181, 0.2);
  border: 1px solid #eee;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-top: ${props => (props.margintop ? props.margintop : '30px')};
  &:hover {
    box-shadow: none;
    transition: box-shadow 600ms none;
  }
`;

export const ImageHolder = styled.img`
  cursor: pointer;
  background: transparent;
  color: #868686;
  width: ${p => (p.width ? p.width : '20px')};
  height: ${p => (p.height ? p.height : '20px')};
  position: ${p => (p.position ? p.position : null)};
  top: ${p => (p.top ? p.top : null)};
  left: ${p => (p.left ? p.left : null)};
  ${props => (props.type === 'empty' ? `margin-bottom: 20px;` : '')}
`;
export const InputLabelStyle = {
  fontSize: '0.987rem',
  color: 'rgba(0,0,0,0.7)',
  width: 'fit-content'
};
export const Image = styled.img``;
