import {
  SET_GUIDE_STEP_INDEX,
  SET_GUIDE_STEP_INDEX_NEXT,
  SET_GUIDE_STEP_INDEX_PREV,
  SET_GUIDE_STATUS,
  SET_GUIDE_PROMPTS_STATUS,
  RESET_GUIDE_PROMPTS,
  GET_USER_GUIDE_STATUS_SUCCESS
} from '../actions/types';
import { RP_ADD_GUIDE, PU_ADD_GUIDE, PU_LINE_ITEM_GUIDE, WELCOME_GUIDE } from '../constants';
import find from 'lodash/find';
/* This reducer is used for the REACT dev grid table.
 *  selection state keeps the value of all the index of items that is being seleceted by the user.
 */

const initialState = {
  isRunning: false,
  stepIndex: 0,
  prompts: {
    [RP_ADD_GUIDE]: true,
    [PU_ADD_GUIDE]: true,
    [PU_LINE_ITEM_GUIDE]: true,
    [WELCOME_GUIDE]: true
  },
  guidesList: []
};

export const guides = (state = initialState, action) => {
  switch (action.type) {
    case SET_GUIDE_STATUS:
      return {
        ...state,
        isRunning: action.isRunning,
        stepIndex: 0
      };
    case SET_GUIDE_STEP_INDEX:
      return {
        ...state,
        stepIndex: action.index
      };
    case SET_GUIDE_STEP_INDEX_NEXT:
      return {
        ...state,
        stepIndex: state.stepIndex + 1
      };
    case SET_GUIDE_STEP_INDEX_PREV:
      return {
        ...state,
        stepIndex: action.stepIndex - 1
      };
    case SET_GUIDE_PROMPTS_STATUS:
      return {
        ...state,
        prompts: {
          ...state.prompts,
          [action.key]: action.flag
        }
      };
    case RESET_GUIDE_PROMPTS:
      return {
        ...state,
        prompts: {
          ...initialState.prompts
        }
      };
    case GET_USER_GUIDE_STATUS_SUCCESS:
      return {
        ...state,
        guidesList: [...action.payload]
      };
    default:
      return state;
  }
};

export const getGuideStatus = ({ guides: { guidesList } }, key) => {
  const guideStatus = find(guidesList, guide => guide.key === key);
  return guideStatus;
};
