import map from 'lodash/map';
import find from 'lodash/find';
import {
  MEMBERS_LIST,
  SIMILAR_COMPANIES_DATA,
  SIMILAR_COMPANIES_CHECK,
  REGISTER_COMPANY_PAYLOAD,
  INDIA,
  CURRENCY_DECIMAL_ROUND,
  QTY_DECIMAL_ROUND,
  PDF_GENERATOR
} from '../constants';

import {
  COMPANIES_LIST_SUCCESS,
  CHANGE_COMPANY,
  COMPANY_AUTH_SUCCESS,
  COMPANY_BRANCH_SUCCESS,
  ADD_COMPANIES_LIST,
  COMPANY_BRANCH_UPDATE,
  COMPANY_BRANCH_REQUESTED,
  COMPANY_SIGNOUT,
  COMPANY_FETCH_ROLE_CONFIG_SUCCESS,
  COMPANY_FETCH_ROLE_CONFIG_FAILED,
  COMPANY_ROLE_CONFIG_REQUESTED,
  COMPANY_FETCH_TEAM_ROLE_SUCCESS,
  COMPANY_FETCH_TEAM_ROLE_FAILED,
  COMPANY_ADD_BANK_ACCOUNT_SUCCESS,
  COMPANY_UPDATE_BANK_ACCOUNT_SUCCESS,
  COMPANY_UPDATE_DETAILS_SUCCESS,
  COMPANY_DELETE_BANK_ACCOUNT_SUCCESS,
  COMPANY_FETCH_TEAM_ROLE_REQUESTED,
  COMPANY_FETCH_TEAM_SUCCESS,
  COMPANY_FETCH_TEAM_FAILED,
  COMPANY_FETCH_TEAM_REQUESTED,
  COMPANY_FETCH_ACCOUNT_GROUP_SUCCESS,
  COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_SUCCESS,
  // COMPANY_FETCH_ALL_ACCOUNTS_SUCCESS,
  // COMPANY_FETCH_ALL_ACCOUNTS_FAILED,
  COMPANY_FETCH_BANK_ACCOUNTS_SUCCESS,
  COMPANY_FETCH_BANK_ACCOUNTS_REQUESTED,
  COMPANY_FETCH_BANK_ACCOUNTS_FAILED,
  COMPANY_RESET_ROLES_REQUESTED,
  COMPANY_RESET_ROLES_SUCCESS,
  COMPANY_RESET_ROLES_FAILED,
  FETCH_STATES_SUCCESS,
  FETCH_STATES_FAILED,
  FETCH_STATES_REQUESTED,
  FETCH_COMPANY_TEMPLATE_LIST,
  PDF_TEMPLATES_REQUESTED,
  PDF_TEMPLATES_SUCCESS,
  CHANGE_FINANCIAL_YEAR,
  FETCH_INDUSTRY_TYPES_REQUESTED,
  FETCH_INDUSTRY_TYPES_SUCCESS,
  FETCH_INDUSTRY_TYPES_FAILED,
  ACCOUNT_SEARCH_REQUESTED,
  ACCOUNT_SEARCH_SUCCESS,
  ACCOUNT_SEARCH_FAILED,
  FETCH_CITIES_REQUESTED,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILED,
  FETCH_PARTIES_STATES_REQUESTED,
  FETCH_PARTIES_STATES_SUCCESS,
  FETCH_PARTIES_STATES_FAILED,
  FETCH_PARTY_ACCOUNT_TYPES_REQUESTED,
  FETCH_PARTY_ACCOUNT_TYPES_SUCCESS,
  FETCH_PARTY_ACCOUNT_TYPES_FAILED,
  ACCOUNT_FORM_REQUESTED,
  ACCOUNT_FORM_SUCCESS,
  ACCOUNT_FORM_FAILED,
  FETCH_ITEM_ATTRIBUTES_SUCCESS,
  ADD_ITEM_ATTRIBUTE_SUCCESS,
  SET_PARTY_UPLOADING_FLAG,
  FETCH_ITEM_ATTRIBUTES_REQUESTED,
  FETCH_ITEM_ATTRIBUTES_FAILED,
  COMPANY_FETCH_ACTIVE_USER_SESSIONS_SUCCESS,
  COMPANY_FETCH_ACTIVE_USER_SESSIONS_REQUESTED,
  COMPANY_FETCH_ACTIVE_USER_SESSIONS_FAILED,
  COMPANY_DELETE_USER_SESSION_REQUESTED,
  COMPANY_DELETE_USER_SESSION_SUCCESS,
  COMPANY_DELETE_USER_SESSION_FAILED,
  COMPANIES_LIST_REQUESTED,
  COMPANIES_LIST_FAILED,
  FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILED,
  HANDLE_CACHE_API,
  SET_CACHE_API_LIST,
  SET_UPDATE_CACHED_API,
  COMPANY_UPDATE_DETAILS_REQUESTED,
  COMPANY_UPDATE_DETAILS_FAILED,
  COMPANY_SET_E_SHOP_STATUS_REQUESTED,
  COMPANY_SET_E_SHOP_STATUS_SUCCESS,
  COMPANY_SET_E_SHOP_STATUS_FAILED,
  COMPANY_UPDATE_E_SHOP_NAME_REQUESTED,
  COMPANY_UPDATE_E_SHOP_NAME_SUCCESS,
  COMPANY_UPDATE_E_SHOP_NAME_FAILED,
  COMPANY_GET_E_SHOP_SHARE_MESSAGE_REQUESTED,
  COMPANY_GET_E_SHOP_SHARE_MESSAGE_SUCCESS,
  COMPANY_GET_E_SHOP_SHARE_MESSAGE_FAILED,
  TOGGLE_BRANCH_SETTING_MODAL,
  FETCH_COMPANY_SETTINGS_SUCCESS,
  COMPANY_DELETE_TEAM_MEMBER_REQUESTED,
  COMPANY_DELETE_TEAM_MEMBER_SUCCESS,
  COMPANY_DELETE_TEAM_MEMBER_FAILED
} from '../actions/types';
import {
  getStartOfFinancialYear,
  getEndOfFinancialYear,
  getFormattedDateIso,
  getPdfToken
} from '../utils/helperFunctions';

const finStartDate = sessionStorage.getItem('startDate');
const finEndDate = sessionStorage.getItem('endDate');

const initialCurrentCompany = {
  loadingBranches: false,
  loadingRoleConfig: false,
  loadingTeamRoles: false,
  loadingBankDetails: false,
  loadingUserSessions: false,
  sessionDeleteRequested: false,
  loadingTemplates: false,
  resetTeamRoles: false,
  branches: [],
  team: [],
  loadingTeam: false,
  roleConfig: [],
  teamRoles: {},
  accountGroups: [],
  accountList: [],
  expenseIncomeAccounts: [],
  companyTemplates: [],
  newTemplates: [],
  decimalPlaces: CURRENCY_DECIMAL_ROUND,
  currencyDecimals: CURRENCY_DECIMAL_ROUND,
  itemQtyDecimals: QTY_DECIMAL_ROUND,
  selectedFinancialYear: {
    startDate: finStartDate
      ? JSON.parse(finStartDate)
      : getFormattedDateIso(getStartOfFinancialYear(new Date())),
    endDate: finEndDate
      ? JSON.parse(finEndDate)
      : getFormattedDateIso(getEndOfFinancialYear(new Date())),
    label: 'Current'
  },
  branchSettingMode: {
    flag: false,
    currentBranch: {}
  },
  accountIsUpdating: false,
  addItemAttributeRequested: false,
  attributeList: [],
  attributeListLoading: false,
  partyUploadingInprogress: false,
  cachedAPI: [],
  companyDetailsUpdateRequested: false,
  eShopStatusRequested: false,
  eShopStatus: 'enabled',
  eShopPathName: '',
  eShopNameUpdateRequested: false,
  eShopShareMessage: '',
  eShopShareMessageRequested: false,
  removeTeamMemberRequested: false,
  allowDuplicateItems: false,
  ewayBillEInvoiceSetup: false,
  planDetails: {},
  printService: PDF_GENERATOR
};

const statesInitialState = {
  loading: false,
  list: []
};

const countriesInitialState = {
  loading: false,
  list: [{ code: 'IN', name: INDIA, iso: 'IND' }]
};

const industryTypesInitialState = {
  loading: false,
  list: []
};

const citiesInitialState = {
  loading: false,
  list: []
};

const partyAccountTypesInitialState = {
  loading: false,
  list: []
};

export const similarCompaniesData = (state = null, payload) => {
  if (payload.type === SIMILAR_COMPANIES_DATA) {
    return payload.payload;
  }
  return state;
};

export const similarCompanyCheck = (state = false, payload) => {
  if (payload.type === SIMILAR_COMPANIES_CHECK) {
    return payload.payload;
  }
  return state;
};

export const registerCompanyData = (state = null, payload) => {
  if (payload.type === REGISTER_COMPANY_PAYLOAD) {
    return payload.payload;
  }
  return state;
};

export const teamMembersData = (state = null, payload) => {
  if (payload.type === MEMBERS_LIST) {
    return payload.payload;
  }
  return state;
};

const initialState = [];
const initialCompanies = {
  companiesLoading: false
};
export const companiesData = (state = initialCompanies, action) => {
  switch (action.type) {
    case COMPANIES_LIST_REQUESTED:
      return {
        ...state,
        companiesLoading: true
      };
    case COMPANIES_LIST_FAILED:
      return {
        ...state,
        companiesLoading: false
      };
    case COMPANIES_LIST_SUCCESS:
      return {
        ...state,
        companiesLoading: false
      };
    default:
      return state;
  }
};
export const companies = (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES_LIST_SUCCESS:
      var merge = map(action.payload, company => {
        const filterCompany = state.length > 0 && find(state, { id: company.id });
        if (
          filterCompany &&
          filterCompany.accessToken &&
          filterCompany.accessToken !== '' &&
          filterCompany.accessToken !== null
        ) {
          return {
            ...filterCompany,
            ...company
          };
        }
        return {
          ...company
        };
      });
      return merge;
    case COMPANY_AUTH_SUCCESS:
      // NOTE: we dont need to do return [...state] as es6 map already gives new copy of array
      return state.map(company =>
        company.id === action.payload.accessToken.iCompanyId
          ? {
              ...company,
              accessToken: action.payload.accessToken.id,
              pdfToken: action.payload.pdfGeneratorData
                ? getPdfToken(action.payload)
                : state.pdfToken,
              ttl: action.payload.accessToken.ttl,
              roles: action.payload.accessToken.roles
            }
          : { ...company }
      );
    case ADD_COMPANIES_LIST:
      return state.concat(action.payload);
    case COMPANY_BRANCH_UPDATE:
      return state.map(company =>
        company.id === action.payload[0].iCompanyId
          ? {
              ...company,
              branches: action.payload,
              loadingBranches: false
            }
          : { ...company }
      );
    case COMPANY_UPDATE_DETAILS_SUCCESS:
      return state.map(company =>
        company.id === action.iCompanyId
          ? {
              ...company,
              name: action.payload.name ? action.payload.name : company.name,
              industry: action.payload.industry ? action.payload.industry : company.industry,
              pancard: action.payload.pancard ? action.payload.pancard : company.pancard,
              printName: action.payload.printName ? action.payload.printName : company.printName,
              financialYearStart: action.payload.financialYearStart
                ? action.payload.financialYearStart
                : company.financialYearStart,
              bookStartDate: action.payload.bookStartDate
                ? action.payload.bookStartDate
                : company.bookStartDate,
              currencyDecimals: action.payload.currencyDecimals
                ? action.payload.currencyDecimals
                : company.currencyDecimals,
              itemQtyDecimals: action.payload.itemQtyDecimals
                ? action.payload.itemQtyDecimals
                : company.itemQtyDecimals,
              dateFormat: action.payload.dateFormat ? action.payload.dateFormat : company.dateFormat
            }
          : { ...company }
      );

    case FETCH_COMPANY_SETTINGS_SUCCESS:
      return state.map(company =>
        company.id === action.iCompanyId
          ? {
              ...company,
              currencyDecimals: action.payload.currencyDecimals
                ? action.payload.currencyDecimals
                : company.currencyDecimals,
              itemQtyDecimals: action.payload.itemQtyDecimals
                ? action.payload.itemQtyDecimals
                : company.itemQtyDecimals,
              allowDuplicateItems: action.payload.allowDuplicateItems,
              ewayBillEInvoiceSetup: action.payload.ewayBillEInvoiceSetup,
              planDetails: action.payload.planDetails,
              printService: action.payload.printService,
              companyVoucherSettings: action.payload.companyVoucherSettings,
              printItemImages: action.payload.printItemImages,
              iShopSettings: action.payload.iShopSettings,
              eShopPathName: action.payload.eShopPathName,
              includeDiscountInInventory: action.payload.includeDiscountInInventory
            }
          : { ...company }
      );
    case COMPANY_SIGNOUT:
      return state.map(company =>
        company.id === action.payload
          ? {
              ...company,
              accessToken: null,
              ttl: null,
              pdfToken: null,
              roles: []
            }
          : { ...company }
      );
    case FETCH_COMPANY_TEMPLATE_LIST:
      return state.map(company =>
        company.id === action.companyId
          ? {
              ...company,
              companyTemplates: action.payload
            }
          : { ...company }
      );
    case 'FETCH_NEW_TEMPLATE_LIST':
      return state.map(company =>
        company.id === action.companyId
          ? {
              ...company,
              newTemplates: action.payload
            }
          : { ...company }
      );
    default:
      return state;
  }
};

export const currentCompany = (state = initialCurrentCompany, action) => {
  switch (action.type) {
    case SET_UPDATE_CACHED_API:
      let list = state.cachedAPI;
      list.forEach(item => {
        if (item.key === action.key) {
          item.cached = false;
        }
      });
      return {
        ...state,
        cachedAPI: list
      };
    case TOGGLE_BRANCH_SETTING_MODAL:
      return {
        ...state,
        branchSettingMode: {
          ...state.branchSettingMode,
          flag: action.flag,
          currentBranch: action.branch
        }
      };
    case SET_CACHE_API_LIST:
      return {
        ...state,
        cachedAPI: action.payload
      };
    case HANDLE_CACHE_API:
      let tempList = state.cachedAPI;
      tempList[action.index].cached = true;
      return {
        ...state,
        cachedAPI: tempList
      };
    case SET_PARTY_UPLOADING_FLAG:
      return {
        ...state,
        partyUploadingInprogress: action.payload
      };
    case CHANGE_FINANCIAL_YEAR:
      return {
        ...state,
        selectedFinancialYear: action.payload
      };
    case FETCH_COMPANY_TEMPLATE_LIST:
      return {
        ...state,
        companyTemplates: action.payload
      };
    case 'FETCH_NEW_TEMPLATE_LIST':
      return {
        ...state,
        newTemplates: action.payload
      };
    case PDF_TEMPLATES_REQUESTED:
      return {
        ...state,
        loadingTemplates: true
      };
    case PDF_TEMPLATES_SUCCESS:
      return {
        ...state,
        loadingTemplates: false
      };
    case CHANGE_COMPANY:
      return {
        ...state,
        ...initialCurrentCompany,
        ...action.payload,
        eShopStatus: action.payload.eShopPathName ? true : false
      };
    case COMPANY_SIGNOUT:
      return initialCurrentCompany;
    case COMPANY_BRANCH_SUCCESS:
      return {
        ...state,
        branches: action.payload,
        loadingBranches: false
      };
    case COMPANY_BRANCH_REQUESTED:
      return {
        ...state,
        loadingBranches: true
      };
    case COMPANY_FETCH_ROLE_CONFIG_SUCCESS:
      return {
        ...state,
        loadingRoleConfig: false,
        roleConfig: action.payload
      };
    case COMPANY_FETCH_ROLE_CONFIG_FAILED:
      return {
        ...state,
        loadingRoleConfig: false,
        roleConfig: []
      };
    case COMPANY_ROLE_CONFIG_REQUESTED:
      return {
        ...state,
        loadingRoleConfig: true
      };
    case COMPANY_FETCH_TEAM_ROLE_SUCCESS:
      return {
        ...state,
        loadingTeamRoles: false,
        teamRoles: action.payload
      };
    case COMPANY_FETCH_TEAM_ROLE_FAILED:
      return {
        ...state,
        loadingTeamRoles: false,
        teamRoles: {}
      };
    case COMPANY_FETCH_TEAM_ROLE_REQUESTED:
      return {
        ...state,
        loadingTeamRoles: true
      };
    case COMPANY_RESET_ROLES_REQUESTED:
      return {
        ...state,
        resetTeamRoles: true
      };
    case COMPANY_RESET_ROLES_SUCCESS:
      return {
        ...state,
        resetTeamRoles: false
      };
    case COMPANY_RESET_ROLES_FAILED:
      return {
        ...state,
        resetTeamRoles: false
      };
    case COMPANY_FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loadingTeam: false,
        team: action.payload
      };
    case COMPANY_FETCH_TEAM_FAILED:
      return {
        ...state,
        loadingTeam: false,
        team: {}
      };
    case COMPANY_FETCH_TEAM_REQUESTED:
      return {
        ...state,
        loadingTeam: true
      };
    case COMPANY_UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankDetails: state.bankDetails.map(account =>
          account.id === action.payload.id ? { ...action.payload } : { ...account }
        )
      };
    case COMPANY_DELETE_BANK_ACCOUNT_SUCCESS:
      const index = state.bankDetails.findIndex(account => account.id === action.bankAccId);
      return {
        ...state,
        bankDetails: [...state.bankDetails.slice(0, index), ...state.bankDetails.slice(index + 1)]
      };
    case COMPANY_ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankDetails: [...state.bankDetails, action.payload]
      };
    case COMPANY_FETCH_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loadingBankDetails: false,
        bankDetails: [...action.payload]
      };
    case COMPANY_FETCH_BANK_ACCOUNTS_REQUESTED:
      return {
        ...state,
        loadingBankDetails: true
      };
    case COMPANY_FETCH_BANK_ACCOUNTS_FAILED:
      return {
        ...state,
        loadingBankDetails: false
      };
    case COMPANY_UPDATE_DETAILS_REQUESTED:
      return {
        ...state,
        companyDetailsUpdateRequested: true
      };
    case COMPANY_UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        name: action.payload.name ? action.payload.name : state.name,
        industry: action.payload.industry ? action.payload.industry : state.industry,
        pancard: action.payload.pancard ? action.payload.pancard : state.pancard,
        printName: action.payload.printName ? action.payload.printName : state.printName,
        financialYearStart: action.payload.financialYearStart
          ? action.payload.financialYearStart
          : state.financialYearStart,
        bookStartDate: action.payload.bookStartDate
          ? action.payload.bookStartDate
          : state.bookStartDate,
        currencyDecimals: action.payload.currencyDecimals
          ? action.payload.currencyDecimals
          : state.currencyDecimals,
        itemQtyDecimals: action.payload.itemQtyDecimals
          ? action.payload.itemQtyDecimals
          : state.itemQtyDecimals,
        dateFormat: action.payload.dateFormat ? action.payload.dateFormat : state.dateFormat,
        companyDetailsUpdateRequested: false,
        iShopSettings: action.payload.iShopSettings
      };
    case FETCH_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        currencyDecimals: action.payload.currencyDecimals
          ? action.payload.currencyDecimals
          : state.currencyDecimals,
        itemQtyDecimals: action.payload.itemQtyDecimals
          ? action.payload.itemQtyDecimals
          : state.itemQtyDecimals,
        ewayBillEInvoiceSetup: action.payload.ewayBillEInvoiceSetup,
        planDetails: action.payload.planDetails,
        printService: action.payload.printService,
        companyVoucherSettings: action.payload.companyVoucherSettings,
        printItemImages: action.payload.printItemImages,
        iShopSettings: action.payload.iShopSettings,
        eShopPathName: action.payload.eShopPathName,
        includeDiscountInInventory: action.payload.includeDiscountInInventory
      };
    case COMPANY_UPDATE_DETAILS_FAILED:
      return {
        ...state,
        companyDetailsUpdateRequested: false
      };
    case COMPANY_FETCH_ACCOUNT_GROUP_SUCCESS:
      return {
        ...state,
        accountGroups: action.payload
      };

    case COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_SUCCESS:
      return {
        ...state,
        expenseIncomeAccounts: action.payload
      };

    /* Removing for caching API fix */

    // case COMPANY_FETCH_ACCOUNT_GROUP_FAILED:
    //   return {
    //     ...state,
    //     accountGroups: []
    //   };

    // case COMPANY_FETCH_EXPENSE_INCOME_ACCOUNTS_FAILED:
    //   return {
    //     ...state,
    //     expenseIncomeAccounts: []
    //   };

    /////***********/////////

    // case COMPANY_FETCH_ALL_ACCOUNTS_SUCCESS:
    //   return {
    //     ...state,
    //     expenseAccounts: action.payload
    //   };
    // case COMPANY_FETCH_ALL_ACCOUNTS_FAILED:
    //   return {
    //     ...state,
    //     expenseAccounts: []
    //   };
    case ACCOUNT_SEARCH_REQUESTED:
      return {
        ...state,
        loadingNextPage: action.page > 1,
        loadingAccountList: action.page === 1
      };
    case ACCOUNT_SEARCH_SUCCESS:
      return {
        ...state,
        loadingNextPage: false,
        accountList:
          action.payload.page === 1
            ? action.payload.data
            : [...state.accountList, ...action.payload.data],
        totalPages: action.payload.totalPages,
        loadingAccountList: false,
        page: action.payload.page
      };

    case ACCOUNT_SEARCH_FAILED:
      return {
        ...state,
        error: action.payload,
        loadingNextPage: false,
        loadingAccountList: false
      };
    case ACCOUNT_FORM_REQUESTED:
      return {
        ...state,
        accountIsUpdating: true
      };
    case ACCOUNT_FORM_SUCCESS:
      return {
        ...state,
        accountIsUpdating: false
      };
    case ACCOUNT_FORM_FAILED:
      return {
        ...state,
        accountIsUpdating: false
      };

    //item attribute related
    case FETCH_ITEM_ATTRIBUTES_REQUESTED:
      return {
        ...state,
        attributeListLoading: true
      };
    case FETCH_ITEM_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributeListLoading: false,
        attributeList: action.payload
      };
    case FETCH_ITEM_ATTRIBUTES_FAILED:
      return {
        ...state,
        attributeListLoading: false
        // attributeList: action.payload
      };
    case ADD_ITEM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        attributeList: [...state.attributeList, action.payload]
      };
    case COMPANY_FETCH_ACTIVE_USER_SESSIONS_REQUESTED:
      return {
        ...state,
        loadingUserSessions: true
      };
    case COMPANY_FETCH_ACTIVE_USER_SESSIONS_SUCCESS:
      return {
        ...state,
        loadingUserSessions: false,
        activeUserSessions: [...action.payload]
      };
    case COMPANY_FETCH_ACTIVE_USER_SESSIONS_FAILED:
      return {
        ...state,
        loadingUserSessions: false
      };
    case COMPANY_DELETE_USER_SESSION_REQUESTED:
      return {
        ...state,
        sessionDeleteRequested: true
      };
    case COMPANY_DELETE_USER_SESSION_SUCCESS:
      return {
        ...state,
        sessionDeleteRequested: false
      };
    case COMPANY_DELETE_USER_SESSION_FAILED:
      return {
        ...state,
        sessionDeleteRequested: false
      };
    case COMPANY_SET_E_SHOP_STATUS_REQUESTED:
      return {
        ...state,
        eShopStatusRequested: true
      };
    case COMPANY_SET_E_SHOP_STATUS_SUCCESS:
      return {
        ...state,
        eShopStatusRequested: false,
        eShopStatus: action.status
      };
    case COMPANY_SET_E_SHOP_STATUS_FAILED:
      return {
        ...state,
        eShopStatusRequested: false
      };
    case COMPANY_UPDATE_E_SHOP_NAME_REQUESTED:
      return {
        ...state,
        eShopNameUpdateRequested: true,
        eShopNameUpdateError: ''
      };
    case COMPANY_UPDATE_E_SHOP_NAME_SUCCESS:
      return {
        ...state,
        eShopPathName: action.name,
        eShopNameUpdateRequested: false,
        eShopNameUpdateError: ''
      };
    case COMPANY_UPDATE_E_SHOP_NAME_FAILED:
      return {
        ...state,
        eShopNameUpdateRequested: false,
        eShopNameUpdateError: action.errorMessage
      };
    case COMPANY_GET_E_SHOP_SHARE_MESSAGE_REQUESTED:
      return {
        ...state,
        eShopShareMessageRequested: true
      };
    case COMPANY_GET_E_SHOP_SHARE_MESSAGE_SUCCESS:
      return {
        ...state,
        eShopShareMessage: action.payload.message,
        eShopShareMessageRequested: false
      };
    case COMPANY_GET_E_SHOP_SHARE_MESSAGE_FAILED:
      return {
        ...state,
        eShopShareMessageRequested: false
      };
    case COMPANY_DELETE_TEAM_MEMBER_REQUESTED:
      return {
        ...state,
        removeTeamMemberRequested: true
      };
    case COMPANY_DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        removeTeamMemberRequested: false
      };
    case COMPANY_DELETE_TEAM_MEMBER_FAILED:
      return {
        ...state,
        removeTeamMemberRequested: false
      };
    default:
      return state;
  }
};

export const states = (state = statesInitialState, action) => {
  switch (action.type) {
    case FETCH_STATES_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_STATES_FAILED:
      return {
        ...state,
        loading: false,
        list: []
      };
    default:
      return state;
  }
};

export const countries = (state = countriesInitialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_COUNTRIES_FAILED:
      return {
        ...state,
        loading: false,
        list: countriesInitialState.list
      };
    default:
      return state;
  }
};

export const industryTypes = (state = industryTypesInitialState, action) => {
  switch (action.type) {
    case FETCH_INDUSTRY_TYPES_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_INDUSTRY_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_INDUSTRY_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        list: []
      };
    default:
      return state;
  }
};

export const cities = (state = citiesInitialState, action) => {
  switch (action.type) {
    case FETCH_CITIES_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        totalDataCount: action.payload.totalDataCount,
        totalPages: action.payload.totalPages
      };
    case FETCH_CITIES_FAILED:
      return {
        ...state,
        loading: false,
        list: []
      };
    default:
      return state;
  }
};

export const partiesStates = (state = statesInitialState, action) => {
  switch (action.type) {
    case FETCH_PARTIES_STATES_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_PARTIES_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_PARTIES_STATES_FAILED:
      return {
        ...state,
        loading: false,
        list: []
      };
    default:
      return state;
  }
};

export const partyAccountTypes = (state = partyAccountTypesInitialState, action) => {
  switch (action.type) {
    case FETCH_PARTY_ACCOUNT_TYPES_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_PARTY_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_PARTY_ACCOUNT_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        list: []
      };
    default:
      return state;
  }
};

//selectors
export const getCurrentCompany = (companies, currentCompanyId) =>
  find(companies, { id: currentCompanyId });

// const filterName = ['name','id'];
export const getBranchNameId = state =>
  state.map(item => ({
    name: item.name,
    id: item.id
  }));

export const getFinancialYear = state => {
  const {
    currentCompany: { selectedFinancialYear }
  } = state;
  return selectedFinancialYear;
};

export const getDecimalPoints = state => {
  const {
    currentCompany: { currencyDecimals }
  } = state;
  return currencyDecimals;
};

export const getQtyDecimalPoints = state => {
  const {
    currentCompany: { itemQtyDecimals }
  } = state;
  return itemQtyDecimals;
};
