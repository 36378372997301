import { reduce } from 'lodash-es';
import {
  SET_DUPLICATE_ITEM_BEHAVIOUR,
  TOGGLE_AUTO_REPEAT_LAST_ITEM,
  TOGGLE_ITEM_LAST_SP_UNIT_PRICE,
  TOGGLE_LINE_ITEM_BARCODE_SEARCH,
  UPDATE_USER_COMPANY_VOUCHER_SETTING,
  UPDATE_VOUCHER_LINE_ITEM_TABLE_COLUMN_VISIBILITY,
  TOGGLE_AUTO_ADD_QTY
} from '../actions/types';
import { SHOW_DUPLICATE_DIALOG } from '../constants';

export const voucherSettingsDefalult = {
  autoRepeatBoxNoFlag: false,
  useItemLastSPUnitPrice: false,
  useSearchByBarcode: false,
  autoRepeatLastItem: false,
  duplicateLineItemBehaviour: SHOW_DUPLICATE_DIALOG,
  hiddenColumns: ['mrp'],
  autoAddQty: false
};

const initialState = {
  duplicateLineItemBehaviour: SHOW_DUPLICATE_DIALOG,
  voucherSettings: {
    defaultSettings: voucherSettingsDefalult
  }
};

export const settings = (state = initialState, action) => {
  let currSetting = voucherSettingsDefalult;
  switch (action.type) {
    case SET_DUPLICATE_ITEM_BEHAVIOUR:
      currSetting = state.voucherSettings[action.key] || voucherSettingsDefalult;

      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          [action.key]: {
            ...currSetting,
            duplicateLineItemBehaviour: action.payload
          }
        }
      };
    case TOGGLE_LINE_ITEM_BARCODE_SEARCH:
      currSetting = state.voucherSettings[action.key] || voucherSettingsDefalult;

      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          [action.key]: {
            ...currSetting,
            useSearchByBarcode: action.flag
          }
        }
      };
    case TOGGLE_ITEM_LAST_SP_UNIT_PRICE:
      currSetting = state.voucherSettings[action.key] || voucherSettingsDefalult;

      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          [action.key]: {
            ...currSetting,
            useItemLastSPUnitPrice: action.flag
          }
        }
      };
    case TOGGLE_AUTO_REPEAT_LAST_ITEM:
      currSetting = state.voucherSettings[action.key] || voucherSettingsDefalult;
      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          [action.key]: {
            ...currSetting,
            autoRepeatLastItem: action.flag
          }
        }
      };
    case TOGGLE_AUTO_ADD_QTY:
      currSetting = state.voucherSettings[action.key] || voucherSettingsDefalult;
      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          [action.key]: {
            ...currSetting,
            autoAddQty: action.flag
          }
        }
      };
    case UPDATE_VOUCHER_LINE_ITEM_TABLE_COLUMN_VISIBILITY:
      currSetting = state.voucherSettings[action.key] || voucherSettingsDefalult;
      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          [action.key]: {
            ...currSetting,
            hiddenColumns: action.hiddenColumns,
            columnVisibility: reduce(
              state.voucherSettings[action.key].columnVisibility,
              (result, value, key) => {
                result[key] = action.hiddenColumns.indexOf(key) >= 0 ? false : true;
                return result;
              },
              {}
            )
          }
        }
      };
    case UPDATE_USER_COMPANY_VOUCHER_SETTING:
      return {
        ...state,
        voucherSettings: {
          ...state.voucherSettings,
          ...action.voucherSettings
        }
      };
    default:
      return state;
  }
};

export const getDuplicateItemBehaviour = ({ settings: { voucherSettings } }, voucherType) => {
  return voucherSettings[voucherType]
    ? voucherSettings[voucherType].duplicateLineItemBehaviour
    : voucherSettingsDefalult.duplicateLineItemBehaviour;
};

export const getSearchByBarcodeFlag = ({ settings: { voucherSettings } }, voucherType) => {
  return voucherSettings[voucherType]
    ? voucherSettings[voucherType].useSearchByBarcode
    : voucherSettingsDefalult.useSearchByBarcode;
};

export const getItemLastSPUnitPriceFlag = ({ settings: { voucherSettings } }, voucherType) => {
  return voucherSettings[voucherType]
    ? voucherSettings[voucherType].useItemLastSPUnitPrice
    : voucherSettingsDefalult.useItemLastSPUnitPrice;
};

export const getAutoRepeatLastItemFlag = ({ settings: { voucherSettings } }, voucherType) => {
  return voucherSettings[voucherType]
    ? voucherSettings[voucherType].autoRepeatLastItem
    : voucherSettingsDefalult.autoRepeatLastItem;
};

export const getAutoAddQtyFlag = ({ settings: { voucherSettings } }, voucherType) => {
  return voucherSettings[voucherType]
    ? voucherSettings[voucherType].autoAddQty
    : voucherSettingsDefalult.autoAddQty;
};

export const getVoucherHiddenCols = ({ settings: { voucherSettings } }, voucherType) => {
  const defaultHiddenColumns = voucherSettingsDefalult.hiddenColumns;
  const voucherTypeSettings = voucherSettings[voucherType];

  const { columnVisibility, hiddenColumns } = voucherTypeSettings;

  if (columnVisibility) {
    // if columnVisibility is not present then create
    defaultHiddenColumns.forEach(key => {
      if (!columnVisibility[key]) {
        columnVisibility[key] = false;
      }
    });

    return reduce(
      columnVisibility,
      (result, value, key) => {
        if (value === false) {
          result.push(key);
        }
        return result;
      },
      []
    );
  }

  if (hiddenColumns) {
    // if hiddenColumns is not present then create
    defaultHiddenColumns.forEach(key => {
      if (hiddenColumns.indexOf(key) < 0) {
        hiddenColumns.push(key);
      }
    });

    return hiddenColumns;
  }

  return defaultHiddenColumns;

  // const hiddenColumns = voucherSettings[voucherType]
  //   ? voucherSettings[voucherType].columnVisibility
  //     ? reduce(
  //         voucherSettings[voucherType].columnVisibility,
  //         (result, value, key) => {
  //           if (value === false) {
  //             result.push(key);
  //           }
  //           return result;
  //         },
  //         []
  //       )
  //     : voucherSettings[voucherType].hiddenColumns
  //   : voucherSettingsDefalult.hiddenColumns;
  // return hiddenColumns;
};
